import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  InputAdornment,
  Stack,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { Controller, useForm } from "react-hook-form";
import dayjs from "dayjs";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import SelectAffiliateCompany from "../../../shared/general/selectAffiliateCompany";
import { useDispatch, useSelector } from "react-redux";
import { getAllEmployees } from "../../../../../actions/user";
import { toast } from "sonner";
import {
  getAllAppraisee,
  updateAppraisee,
} from "../../../../../actions/appraisee";

const StyledChip = styled(Chip)(({ mode }) => ({
  width: 120,
  ...(mode === "boss" && {
    borderColor: "#ff3034",
    color: "#ffffff",
    backgroundColor: "#ff3034",
  }),
  ...(mode === "peer" && {
    borderColor: "#ff8d00",
    color: "#ffffff",
    backgroundColor: "#ff8d00",
  }),
  ...(mode === "cross function" && {
    borderColor: "#7d55c2",
    color: "#ffffff",
    backgroundColor: "#7d55c2",
  }),
  ...(mode === "customer" && {
    borderColor: "#008dd1",
    color: "#ffffff",
    backgroundColor: "#008dd1",
  }),
  ...(mode === "subordinate" && {
    borderColor: "#00bdb1",
    color: "#ffffff",
    backgroundColor: "#00bdb1",
  }),
  ...(mode === "project" && {
    borderColor: "#ffc700",
    color: "#ffffff",
    backgroundColor: "#ffc700",
  }),
  ...(mode === "outsider" && {
    borderColor: "#820747",
    color: "#ffffff",
    backgroundColor: "#820747",
  }),
  ...(mode === "oneself" && {
    borderColor: "#b46c6c",
    color: "#ffffff",
    backgroundColor: "#b46c6c",
  }),
}));

function summarySamplesizeCalculation(
  calculationAll,
  currentKey,
  currentValue
) {
  let summary = 0;
  for (let key in calculationAll) {
    if (key === currentKey) {
      continue;
    }
    summary += calculationAll[key];
  }
  return summary + currentValue;
}

const sampleSize = [
  { idSampleSize: 1, key: "boss", label: "boss" },
  { idSampleSize: 2, key: "peer", label: "peer" },
  { idSampleSize: 3, key: "project", label: "project" },
  { idSampleSize: 4, key: "subordinate", label: "subordinate" },
  { idSampleSize: 5, key: "customer", label: "customer" },
  { idSampleSize: 6, key: "crossfunction", label: "cross function" },
  { idSampleSize: 7, key: "oneself", label: "oneself" },
];

const validationSchema = yup.object().shape({
  startDate: yup.date().stateDate,
  endDate: yup.date().when("startDate", (startDate, schema) => {
    if (startDate) {
      return schema.min(startDate, "วันที่สิ้นสุดไม่ถูกต้อง");
    }
  }),
  sampleSize: yup.object({
    boss: yup.number().integer().min(0),
    peer: yup.number().integer().min(0),
    project: yup.number().integer().min(0),
    subordinate: yup.number().integer().min(0),
    customer: yup.number().integer().min(0),
    crossfunction: yup.number().integer().min(0),
    outsider: yup.number().integer().min(0),
    oneself: yup.number().integer().min(0),
  }),
  selected: yup.object({
    boss: yup.array(),
    peer: yup.array(),
    project: yup.array(),
    subordinate: yup.array(),
    customer: yup.array(),
    crossfunction: yup.array(),
    oneself: null,
  }),
  outsider: yup.object({
    boss: yup.array(),
    peer: yup.array(),
    project: yup.array(),
    subordinate: yup.array(),
    customer: yup.array(),
    crossfunction: yup.array(),
  }),
});

const DialogConfig = ({ open, handleClose, data, selectDate }) => {
  const dispatch = useDispatch();
  const { result: persons } = useSelector((state) => state.users);
  const [companySelected, setCompanySelected] = useState(null);

  const {
    control,
    watch,
    setValue,
    getValues,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      startDate: dayjs(data.stateDate),
      endDate: dayjs(data.endDate),
      sampleSize: {
        boss: 0,
        peer: 0,
        project: 0,
        subordinate: 0,
        customer: 0,
        crossfunction: 0,
        outsider: 0,
        oneself: 0,
      },
      selected: {
        boss: [],
        peer: [],
        project: [],
        subordinate: [],
        customer: [],
        crossfunction: [],
      },
      outsider: {
        boss: [],
        peer: [],
        project: [],
        subordinate: [],
        customer: [],
        crossfunction: [],
      },
    },
    resolver: yupResolver(validationSchema),
  });

  const handleEditAssessment = async (values) => {
    const cleanSelected = {
      boss: values.selected.boss?.map((item) => ({
        idEmployees: item.idEmployees,
        email: item.email,
      })),
      peer: values.selected.peer?.map((item) => ({
        idEmployees: item.idEmployees,
        email: item.email,
      })),
      project: values.selected.project?.map((item) => ({
        idEmployees: item.idEmployees,
        email: item.email,
      })),
      subordinate: values.selected.subordinate?.map((item) => ({
        idEmployees: item.idEmployees,
        email: item.email,
      })),
      customer: values.selected.customer?.map((item) => ({
        idEmployees: item.idEmployees,
        email: item.email,
      })),
      crossfunction: values.selected.crossfunction?.map((item) => ({
        idEmployees: item.idEmployees,
        email: item.email,
      })),
    };

    const res = await dispatch(
      updateAppraisee({
        ...values,
        selected: cleanSelected,
        idAppraisee: data.idAppraisee,
      })
    );

    if (res && res.status === 200) {
      toast.success("ดำเนินการสำเร็จ");
      dispatch(getAllAppraisee({ selectDate: selectDate.format("YYYY-MM") }));
      handleClose();
    } else {
      toast.error("เกิดข้อผิดพลาด");
    }
  };

  const onSubmit = async (values) => {
    let totalRatio = 0;
    for (const sample in values.sampleSize) {
      totalRatio += values.sampleSize[sample];
      if (!sample === "oneself" &&
        values.sampleSize[sample] > 0 &&
        values.selected[sample].length === 0 &&
        values.outsider[sample].length === 0
      ) {
        toast.error("โปรดเลือกผู้ประเมินให้ถูกต้อง");
        return;
      }
    }
    if (totalRatio !== 100) {
      toast.error("โปรดเลือกน้ำหนักให้ครบ 100%");
      return;
    }
    handleEditAssessment(values);
  };

  useEffect(() => {
    dispatch(getAllEmployees());
  }, []);

  useEffect(() => {
    if (persons) {
      for (const sample of sampleSize) {
        const ratio =
          data.sampleSize.find(
            (item) => item.idSampleSize === sample.idSampleSize
          )?.ratio || 0;

        setValue(`sampleSize.${sample.key}`, ratio);
        
        if (sample.key !== "oneself") {
          const selected = persons.filter((emp) =>
            data.sampleSize?.find(
              (item) =>
                item.idAppraiser === emp.idEmployees &&
                item.idSampleSize === sample.idSampleSize
            )
          );
          const outsider = data.sampleSize
            ?.filter(
              (item) =>
                !item.idAppraiser && item.idSampleSize === sample.idSampleSize
            )
            .map((item) => item.email);

          setValue(`selected.${sample.key}`, selected);
          setValue(`outsider.${sample.key}`, outsider);
        }
      }
    }
  }, [persons, data.sampleSize]);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{"แก้ไขแบบประเมิน"}</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Typography fontSize={20} gutterBottom>
            {data.assessmentName}
          </Typography>
          <Typography>{`ผู้ถูกประเมิน: ${data.firstname_TH} ${data.lastname_TH}`}</Typography>
          <Stack direction="row" spacing={2} marginTop={2} marginBottom={2}>
            <Controller
              control={control}
              name="startDate"
              render={({ field, fieldState }) => (
                <DatePicker
                  {...field}
                  label="วันที่เริ่มต้น"
                  slotProps={{
                    textField: {
                      error: Boolean(fieldState.error),
                      helperText: fieldState.error?.message,
                    },
                  }}
                />
              )}
            />
            <Controller
              control={control}
              name="endDate"
              render={({ field, fieldState }) => (
                <DatePicker
                  {...field}
                  label="วันที่สิ้นสุด"
                  minDate={dayjs(watch("startDate")).add(1, "day")}
                  slotProps={{
                    textField: {
                      error: Boolean(fieldState.error),
                      helperText: fieldState.error?.message,
                    },
                  }}
                />
              )}
            />
          </Stack>
          <Divider sx={{ marginBottom: 2 }} />
          <Grid container spacing={1} marginBottom={2}>
            {sampleSize.map((item) => (
              <Grid item xs={12} sm={4} key={item.key}>
                <StyledChip
                  label={item.label}
                  mode={item.label}
                  sx={{ marginBottom: 1 }}
                />
                <Controller
                  control={control}
                  name={`sampleSize.${item.key}`}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      type="number"
                      onChange={(event) => {
                        const x = summarySamplesizeCalculation(
                          watch("sampleSize"),
                          item.key,
                          Number(event.target.value)
                        );
                        if (x > 100 || x < 0) {
                          field.onChange(0);
                        } else {
                          field.onChange(Number(event.target.value));
                        }
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">%</InputAdornment>
                        ),
                      }}
                      inputProps={{
                        step: 5,
                        min: 0,
                        max: 100,
                      }}
                      helperText={fieldState?.error?.message}
                      error={Boolean(fieldState.error)}
                    />
                  )}
                />
              </Grid>
            ))}
          </Grid>
          <Divider sx={{ marginBottom: 2 }} />
          <div style={{ marginBottom: "8px" }}>
            <SelectAffiliateCompany
              value={companySelected}
              handleChange={setCompanySelected}
            />
          </div>
          <Stack gap={1}>
            {sampleSize
              .filter((sample) => sample.key !== "oneself")
              .map((item) => (
                <Stack gap={1} key={item.key}>
                  <Divider />
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                    }}
                  >
                    <Typography>{"ผู้ประเมิน: "}</Typography>
                    <StyledChip label={item.label} mode={item.label} />
                  </div>
                  <Controller
                    control={control}
                    name={`selected.${item.key}`}
                    render={({ field, fieldState }) => (
                      <Autocomplete
                        {...field}
                        multiple
                        disabled={!companySelected || watch(`sampleSize.${item.key}`) === 0}
                        options={
                          persons?.filter(
                            (item) =>
                              !item.isTerminate &&
                              item.idCompany === companySelected?.idCompany &&
                              item.idEmployees !== data.idEmployees
                          ) || []
                        }
                        onChange={(event, newValue) => {
                          field.onChange(newValue);
                        }}
                        isOptionEqualToValue={(option, value) =>
                          option.idEmployees === value.idEmployees
                        }
                        disableCloseOnSelect
                        getOptionLabel={(option) =>
                          `${option.firstname_TH} ${option.lastname_TH || ""}`
                        }
                        renderInput={(params) => <TextField {...params} />}
                        renderOption={(props, option) => (
                          <li {...props} key={option.idEmployees}>
                            <Avatar
                              alt={
                                option.idEmployees === "outsider"
                                  ? "Outsider"
                                  : `${option.firstname_TH} ${option.lastname_TH}`
                              }
                              src={
                                option.idEmployees === "outsider"
                                  ? null
                                  : option.imageProfile
                              }
                            />
                            <Box ml={1}>
                              {option.firstname_TH} {option.lastname_TH}
                            </Box>
                          </li>
                        )}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name={`outsider.${item.key}`}
                    render={({ field, fieldState }) => (
                      <Autocomplete
                        {...field}
                        multiple
                        freeSolo
                        options={[]}
                        onChange={(_, newValue) => {
                          const test = newValue.some(
                            (email) =>
                              !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(
                                email
                              )
                          );
                          if (test) {
                            return;
                          }
                          field.onChange(newValue);
                        }}
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => {
                            const { key, ...tagProps } = getTagProps({ index });
                            return (
                              <Chip
                                variant="outlined"
                                label={option}
                                key={key}
                                {...tagProps}
                              />
                            );
                          })
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label="อีเมลของบุคคลภายนอก"
                            
                          />
                        )}
                        disabled={watch(`sampleSize.${item.key}`) === 0}
                      />
                    )}
                  />
                </Stack>
              ))}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{"ยกเลิก"}</Button>
          <Button type="submit" variant="contained" disabled={isSubmitting}>
            {"ยืนยัน"}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DialogConfig;
