import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import {
  Typography,
  Divider,
  Paper,
  Snackbar,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Radio,
  FormControlLabel,
  RadioGroup,
  FormControl,
  Box,
  Chip,
  ListItemText,
  ListItemIcon,
  ListItem,
  Card,
  InputBase,
  Tooltip,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import SaveIcon from "@mui/icons-material/Save";
import TextField from "@mui/material/TextField";
import Avatar from "@mui/material/Avatar";
import HeaderPage from "../../shared/header/headerPage";
import AssessmentIconHeader from "./assets/360-degrees-color.svg";
import FeelingIcon from "../../shared/feelingIcon";
import PersonIcon from "@mui/icons-material/Person";
import QuizIcon from "@mui/icons-material/Quiz";
import {
  addAnswerAppraisee,
  getAppraisee,
} from "../../../../actions/appraisee";
import { useLocation, useNavigate } from "react-router-dom";
import {
  answerQuestion5Scale,
  answerQuestion6Scale,
} from "../../assets/data/answerScale";

const WrapHeader = styled(Paper)({
  width: "100%",
  padding: 16,
  marginBottom: 20,
  "& .header-name": {
    "& .MuiTypography-subtitle1": {
      fontSize: 18,
    },
    "& .MuiTypography-body1": {
      fontSize: 14,
    },
  },
});

const WrapHeaderAnswer = styled(Grid)({
  display: "flex",
  justifyContent: "space-between",
  marginTop: 8,
  marginBottom: 8,
  "& .answer": {
    display: "flex",
    alignItems: "center",
    flexDirection: "column-reverse",
    "& p": {
      fontSize: 14,
      fontWeight: 500,
      marginBottom: 8,
    },
  },
});

const StyledDivider = styled(Divider)({
  width: "100%",
  margin: "10px 0",
});

const StyledRoot = styled("div")({
  display: "flex",
  flexDirection: "column",
  padding: 15,
  marginTop: 60,
  margin: "0px auto",
  minWidth: 500,
  backgroundColor: "#f5f5f5",
});

const Assessment360Question = (props) => {
  const navigate = useNavigate();
  const {
    state: { idAppraisee },
  } = useLocation();
  const dispatch = useDispatch();
  const { result: appraiseeItem } = useSelector((state) => state.appraiseeItem);
  const { result: userProfile } = useSelector((state) => state.userProfile);

  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState(new Set());
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [answer, setAnswer] = useState([]);
  const [comment, setComment] = useState([]);
  const { alt, src } = props;
  // const steps = getSteps();

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  useEffect(() => {
    dispatch(getAppraisee(parseInt(idAppraisee)));
  }, [dispatch, idAppraisee]);

  // function getSteps() {
  //   if (moraleQuestionItem) {
  //     return moraleQuestionItem.questionTopic;
  //   } else {
  //     return 0;
  //   }
  // }

  // const totalSteps = () => {
  //   return getSteps().length;
  // };

  const completedSteps = () => {
    return completed.size;
  };

  // const allStepsCompleted = () => {
  //   return completedSteps() === totalSteps();
  // };

  // const isLastStep = () => {
  //   return activeStep === totalSteps() - 1;
  // };

  // const handleNext = () => {
  //   const newActiveStep =
  //     isLastStep() && !allStepsCompleted()
  //       ? steps.findIndex((step, i) => !completed.has(i))
  //       : activeStep + 1;

  //   setActiveStep(newActiveStep);
  // };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleComplete = async () => {
    const res = await dispatch(addAnswerAppraisee(answer));
    if (res) {
      navigate("/assessment360");
    }
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();

  //   const answerPass =
  //     answer.filter((item) => item.value.trim().length > 0).length ===
  //     appraiseeItem.questionList.length;
  //   if (answerPass) {
  //     handleClickOpen();
  //   } else {
  //     setOpenSnackbar(true);
  //   }
  // };

  const handleSubmit = (e) => {
    e.preventDefault();

    const answerPass =
      answer.filter((item) => {
        const currentQuestion = appraiseeItem.questionList.find(
          (question) =>
            question.idAssessment_Question === item.idAssessment_Question
        );

        if (currentQuestion?.questionType === "choice") {
          return (
            item.value &&
            item.value.trim().length > 0 &&
            (item.comment === undefined || item.comment.trim().length > 0)
          );
        } else {
          return item.value && item.value.trim().length > 0;
        }
      }).length === appraiseeItem.questionList.length;

    if (answerPass) {
      handleClickOpen();
    } else {
      setOpenSnackbar(true);
      // console.log("Entered Answers:");
      // answer.forEach((item) => {
      //   console.log(`Question ID: ${item.idAssessment_Question}`);
      //   console.log(`Value: ${item.value}`);
      //   if (item.comment) {
      //     console.log(`Comment: ${item.comment}`);
      //   }
      // });
    }
  };

  const updateAnswer = (index, idAssessment_Question) => (event) => {
    const selectSamplesize = appraiseeItem.sampleSize.find(
      (item) => item.idAppraiser === userProfile.idEmployees
    );

    const currentQuestion = appraiseeItem.questionList.find(
      (question) => question.idAssessment_Question === idAssessment_Question
    );

    let newValue = {
      idAppraisee_SampleSize: selectSamplesize.idAppraisee_SampleSize,
      idAssessment_Question: idAssessment_Question,
    };

    if (event.target.name === "value") {
      newValue.value = event.target.value;
    } else if (
      event.target.name === "comment" &&
      currentQuestion?.questiontype === "choice"
    ) {
      newValue.comment = event.target.value;
    }

    const existingAnswerIndex = answer.findIndex(
      (ans) => ans.idAssessment_Question === idAssessment_Question
    );

    if (existingAnswerIndex === -1) {
      setAnswer([...answer, newValue]);
    } else {
      const updatedAnswers = answer.map((ans) =>
        ans.idAssessment_Question === idAssessment_Question
          ? { ...ans, [event.target.name]: event.target.value }
          : ans
      );
      setAnswer(updatedAnswers);
    }
  };

  if (
    userProfile &&
    appraiseeItem &&
    appraiseeItem.sampleSize.some(
      (sample) =>
        sample.idAppraiser === userProfile.idEmployees && sample.isComplete
    )
  ) {
    navigate("/assessment360", { replace: true });
  }

  return (
    <StyledRoot className={"page"}>
      {appraiseeItem && (
        <>
          <HeaderPage
            textLabel={"แบบประเมิน 360"}
            icon={AssessmentIconHeader}
          />
          <WrapHeader>
            <Grid container>
              <Grid item xs={12} md={4}>
                <Box sx={{ display: "flex", alignItems: "center", marginBottom: "24px" }}>
                  <PersonIcon />
                  <Typography
                    variant="h6"
                    align="flex-start"
                  >
                    ข้อมูลพนักงาน
                  </Typography>
                </Box>
                <Box display={"flex"} alignItems={"center"} gap={"24px"}>
                  <Avatar
                    style={{ width: 200, height: "100%" }}
                    alt={`employee`}
                    src={appraiseeItem.imageURL}
                  />
                  <div>
                    <Typography variant="h5">
                      {`${appraiseeItem.firstname_TH} ${appraiseeItem.lastname_TH}`}
                    </Typography>
                    {appraiseeItem.positionName && (
                      <Typography variant="subtitle1" color="textSecondary">
                        {appraiseeItem.positionName}
                      </Typography>
                    )}
                    {appraiseeItem.departmentName && (
                      <Typography variant="body1" color="textSecondary">
                        {appraiseeItem.departmentName}
                      </Typography>
                    )}
                  </div>
                </Box>
              </Grid>
              <Grid item xs={12} md={1}>
                <Divider orientation="vertical" />
              </Grid>
              <Grid item xs={12} md={7}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                >
                  <Box
                    sx={{
                      marginLeft: 3,
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center", marginBottom: "24px" }}>
                      <QuizIcon />
                      <Typography variant="h6">
                        ข้อมูลชุดคำถาม
                      </Typography>
                    </Box>
                    <Typography
                      variant="body2"
                      color="#EC5B6A"
                      justifyContent="flex-end"
                    >
                      ชื่อชุดคำถาม
                    </Typography>
                    <Typography variant="h6" sx={{ marginBottom: "8px" }}>
                      {`${appraiseeItem.assessmentName} `}
                    </Typography>

                    <Typography variant="body2" color="#EC5B6A">
                      คำอธิบาย
                    </Typography>

                    {/* <Tooltip
                  title={appraiseeItem.assessmentDescription}
                  placement="top"
                >
                  <Typography
                    variant="body1"
                    sx={{
                      maxWidth: "100%",
                      marginBottom: "8px",
                      display: "-webkit-box",
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden",
                      WebkitLineClamp: 2,
                      textOverflow: "ellipsis",
                    }}
                  >
                    {`${appraiseeItem.assessmentDescription} `}
                  </Typography>
                </Tooltip> */}
                    <Tooltip title={appraiseeItem.assessmentDescription}>
                      <Typography
                        variant="body1"
                        sx={{
                          maxWidth: "100%",
                          marginBottom: "8px",
                          display: "-webkit-box",
                          WebkitBoxOrient: "vertical",
                          overflow: "hidden",
                          WebkitLineClamp: 2,
                          textOverflow: "ellipsis",
                        }}
                      >
                        {`${appraiseeItem.assessmentDescription} `}
                      </Typography>
                    </Tooltip>
                    <Typography
                      variant="body2"
                      color="#EC5B6A"
                      justifyContent="flex-end"
                    >
                      จำนวนคำถาม
                    </Typography>
                    <Typography variant="body1" sx={{ marginBottom: "8px" }}>
                      {`${appraiseeItem.questionList.length} ข้อ`}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            {/* {appraiseeItem.questionList.some(
              (question) => question.questionType === "choice"
            ) && (
              <div style={{ marginTop: 20 }}>
                <StyledDivider />
                <Typography color="textSecondary" style={{ fontSize: 14 }}>
                  คำตอบ:
                </Typography>
                <Grid container justifyContent="center">
                  <WrapHeaderAnswer item xs={12} sm={6}>
                    {answerScale.map((item) => (
                      <div
                        className={`answer`}
                        key={item.key}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          paddingRight: "8px",
                        }}
                      >
                        <Typography
                          variant="body2"
                          style={{
                            marginTop: "4px",
                            textAlign: "center",
                            fontSize: "14px",
                          }}
                        >
                          {item.label}
                        </Typography>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "100%",
                          }}
                        >
                          <FeelingIcon
                            feeling={item.key}
                            style={{ maxWidth: "100%", height: "auto" }}
                          />
                        </div>
                      </div>
                    ))}
                  </WrapHeaderAnswer>
                </Grid>
              </div>
            )} */}
          </WrapHeader>

          <form onSubmit={handleSubmit}>
            <div component="nav" aria-label="list-question">
              {appraiseeItem.questionList.map((question, index) => {
                return (
                  <Paper
                    variant="outlined"
                    key={index}
                    style={{
                      display: "block",
                      padding: 16,
                      margin: "16px 0",
                      borderLeft: "10px solid #EC5B6A",
                    }}
                  >
                    <ListItem>
                      <ListItemIcon>
                        <Avatar sx={{ backgroundColor: "#EC5B6A" }}>
                          {index + 1}
                        </Avatar>
                      </ListItemIcon>

                      <ListItemText
                        primary={
                          <Typography variant="h6">
                            {question.question}
                          </Typography>
                        }
                      />
                    </ListItem>
                    {/* <Typography
                      gutterBottom
                      style={{ fontSize: 20 }}
                      variant="body1"
                    >
                      {`${index + 1}. ${question.question}`}
                    </Typography> */}

                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Grid container spacing={2} alignItems="center">
                        <WrapHeaderAnswer item xs={12} sm={12}>
                          {question.questionType === "choice" ? (
                            <FormControl sx={{ width: "100%" }}>
                              <RadioGroup
                                row
                                aria-labelledby="demo-radio-buttons-group-label"
                                name="value"
                                sx={{
                                  justifyContent: "space-evenly",
                                  alignItems: "center",
                                }}
                                value={answer.value}
                                onChange={updateAnswer(
                                  index,
                                  question.idAssessment_Question
                                )}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    gap: "16px",
                                  }}
                                >
                                  {(appraiseeItem.scale === 6
                                    ? answerQuestion6Scale
                                    : answerQuestion5Scale
                                  ).map((item) => (
                                    <div className="answer" key={item.key}>
                                      <Chip
                                        label={item.label}
                                        sx={{ marginTop: "8px" }}
                                      />
                                      <FormControlLabel
                                        control={
                                          <Radio
                                            checkedIcon={
                                              <div
                                                style={{
                                                  width: 80,
                                                  height: 80,
                                                }}
                                              >
                                                <FeelingIcon
                                                  feeling={item.key}
                                                  unSelected={true}
                                                />
                                              </div>
                                            }
                                            icon={
                                              <div
                                                style={{
                                                  width: 80,
                                                  height: 80,
                                                }}
                                              >
                                                <FeelingIcon
                                                  feeling={item.key}
                                                  unSelected={false}
                                                />
                                              </div>
                                            }
                                          />
                                        }
                                        value={item.value}
                                        // label={item.label}
                                        labelPlacement="bottom"
                                      />
                                    </div>
                                  ))}
                                </Box>
                              </RadioGroup>
                              {/* <TextField
                                id="outlined-basic"
                                label="แสดงความเห็น :"
                                variant="outlined"
                                sx={{ marginTop: "20px", marginLeft: "40px" }}
                                name="comment"
                                value={comment.value}
                                onChange={updateAnswer(
                                  index,
                                  question.idAssessment_Question
                                )}
                              /> */}
                              <Card
                                sx={{
                                  padding: "8px",
                                  marginTop: "24px",
                                  borderRadius: "30px",
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Avatar
                                    sx={{ mr: 1, my: 0.5 }}
                                    alt={alt}
                                    src={userProfile.imageProfile}
                                  />

                                  <InputBase
                                    placeholder="แสดงความคิดเห็น"
                                    name="comment"
                                    sx={{ width: "400px" }}
                                    value={comment.value}
                                    inputProps={{ maxLength: 200 }}
                                    onChange={updateAnswer(
                                      index,
                                      question.idAssessment_Question
                                    )}
                                    helperText={`${Math.min(
                                      (
                                        answer.find(
                                          (a) =>
                                            a.idAssessment_Question ===
                                            question.idAssessment_Question
                                        )?.value || ""
                                      ).length,
                                      200
                                    )}/200 ตัวอักษร`}
                                  />
                                </Box>
                              </Card>
                            </FormControl>
                          ) : (
                            <Card
                              sx={{
                                padding: "8px",
                                marginTop: "24px",
                                borderRadius: "30px",
                                width: "100%",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Avatar
                                  sx={{ mr: 1, my: 0.5 }}
                                  alt={alt}
                                  src={userProfile.imageProfile}
                                />
                                <InputBase
                                  placeholder="คำตอบ"
                                  name="value"
                                  sx={{ width: "100%" }}
                                  value={answer.value}
                                  inputProps={{ maxLength: 200 }}
                                  onChange={updateAnswer(
                                    index,
                                    question.idAssessment_Question
                                  )}
                                  helperText={`${Math.min(
                                    (
                                      answer.find(
                                        (a) =>
                                          a.idAssessment_Question ===
                                          question.idAssessment_Question
                                      )?.value || ""
                                    ).length,
                                    200
                                  )}/200 ตัวอักษร`}
                                />
                              </Box>
                            </Card>
                          )}
                        </WrapHeaderAnswer>
                      </Grid>
                    </div>
                  </Paper>
                );
              })}
            </div>

            <div
              style={{
                marginTop: 20,
                marginBottom: 50,
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                endIcon={<SaveIcon />}
                size="large"
                type="submit"
              >
                {"ส่งคำตอบ"}
              </Button>
            </div>
          </form>

          <Snackbar
            open={openSnackbar}
            autoHideDuration={6000}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            onClose={handleCloseSnackbar}
          >
            <Alert onClose={handleCloseSnackbar} severity="error">
              โปรดกรอกข้อมูลให้ครบ
            </Alert>
          </Snackbar>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"ยืนยันส่งคำตอบ"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {"สามารถตอบคำถามได้รอบเดียวเท่านั้น"}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>ยกเลิก</Button>
              <Button onClick={handleComplete}>ยืนยัน</Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </StyledRoot>
  );
};

export default Assessment360Question;
