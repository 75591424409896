import { httpClient } from "./httpClient";

const getHeartTransfer = (query) => {
  return httpClient.get("heart-transfer", { params: query });
};

const addHeartTransfer = (formData) => {
  return httpClient.post("heart-transfer", formData);
};

const updateHeartTransfer = (formData) => {
  return httpClient.patch("heart-transfer", formData);
};

const getHeartReceiveYear = () => {
  return httpClient.get("heart-receive-year");
};

export default {
  getHeartTransfer,
  addHeartTransfer,
  updateHeartTransfer,
  getHeartReceiveYear,
};
