import React, { useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TableCell,
} from "@mui/material";

import { styled } from "@mui/system";
import PersonIcon from "@mui/icons-material/Person";

const StyledTableCell = styled(TableCell)({
  color: "#ffffff",
  fontWeight: "bold",
  padding: "16px",
  borderColor: "#2e2e2e",
  width: "50%",
});

const getTop3Weaknesses = (filterData) => {
  const topicWeaknesses = [];
  filterData.forEach((row) => {
    row.questionTopics.forEach((item) => {
      topicWeaknesses.push({
        topic: item.questionTopic,
        averageScore: item.averageScore,
      });
    });
  });

  return topicWeaknesses
    .sort((a, b) => a.averageScore - b.averageScore)
    .slice(0, 3);
};

const getScoreColor = (score) => {
  if (score >= 80) return { color: "green" };
  if (score >= 60) return { color: "yellowgreen" };
  if (score >= 40) return { color: "yellow" };
  if (score >= 20) return { color: "orange" };
  return { color: "red" };
};

const Top3WeaknessDailyTable = ({ filterData }) => {
  const top3Weaknesses = getTop3Weaknesses(filterData);

  return (
    <div>
      <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
        <PersonIcon sx={{ color: "#000" }} />
        <Typography  variant="h5" textAlign={"Left"} color="#000" >
          จุดอ่อน 3 อันดับแรก
        </Typography>
      </div>
      <TableContainer
        component={Paper}
        sx={{ marginTop: "16px", backgroundColor: "#fff", }}
      >
        <Table stickyHeader sx={{ borderCollapse: "collapse", }}>
          <TableHead>
            <TableRow>
              <StyledTableCell
                sx={{
                  fontSize: "1rem",
                  background: "linear-gradient(90deg, #A0E3E2, #C4A1D9)",
                  color: "#000",
                  width: "50%",
                  border: "1px solid #e0e0e0", // Add border to cell
                  borderBottom: "2px solid #e0e0e0" // Make bottom border thicker for header
                }}
              >
                <strong>หัวข้อ</strong>
              </StyledTableCell>
              <StyledTableCell
               align="center"
                sx={{
                  background: "linear-gradient(90deg, #A0E3E2, #C4A1D9)",
                  color: "#000",
                  fontSize: "1rem",
                  border: "1px solid #e0e0e0", // Add border to cell
                  borderBottom: "2px solid #e0e0e0" // Make bottom border thicker for header
                }}
              >
                <strong>คะแนนเฉลี่ย (%)</strong>
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {top3Weaknesses.map((row) => (
              <TableRow key={row.topic}>
                <StyledTableCell component="th" scope="row" sx={{ color: "black", border: "1px solid #e0e0e0" }} >
                  <Typography color={"#000"}>{row.topic}</Typography>
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    border: "1px solid #e0e0e0",
                    color: getScoreColor(row.averageScore),
                  }}
                >
                  {Math.ceil(row.averageScore)}
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default Top3WeaknessDailyTable;
