import React from "react";
import { styled } from '@mui/material/styles';
import {
  Typography,
  Grid,
  Card,
  CardActions,
  DialogContentText,
  Stack,
  Container,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { useDispatch, useSelector } from "react-redux";
import { updateMoraleQuestion, getAllMoraleDailyQuestion } from "../../../../../actions/moraleDaily";
import { getBgCard, imgInsight } from "./utils";
import DialogPublish from "./dialogPublish";

const StyledCard = styled(Card)({
  height:'100%',
  width:'100%',
  display: "flex",
  flexDirection: "column",
  border: '10px solid ',
});


const DialogArchive = (props) => {
  const dispatch = useDispatch();
  const handleDeleteQuestion = async () => {
    const res = await dispatch(
      updateMoraleQuestion({
        idMoraledailyQuestion: props.idQuestion,
        isActive: 0,
      })
    );
    if (res) {
      dispatch(getAllMoraleDailyQuestion());
    }
    props.onClose();
  };

  return (
    <Dialog
      open={props.open}
      fullWidth={true}
      maxWidth={"sm"}
      onClose={props.onClose}
    >
      <DialogTitle>{"achieve"}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          คุณแน่ใจว่าจะเก็บ?{" "}
          <span style={{ color: "#f44336" }}>{props.displayDelete}</span>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} color="primary">
          {"ยกเลิก"}
        </Button>
        <Button onClick={handleDeleteQuestion} color="primary">
          {"เก็บ"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const MoraleDailyActive = ({ selectTopic }) => {
  const { result: moraleDailyQuestion } = useSelector(state => state.moraleDailyQuestion)
  
  const [idQuestion, setIdQuestion] = React.useState(null)
  const [openPublic, setOpenPublic] = React.useState(false);
  const [displayDelete, setDisplayDelete] = React.useState(null);
  const [openArchive, setOpenArchive] = React.useState(false);

  const handleOpenPublic = (id) => {
    setIdQuestion(id)
    setOpenPublic(true)
  };

  const handleClosePublic = () => {
    setOpenPublic(false);
  };

  const handleClickArchive = (question, id) => {
    setIdQuestion(id)
    setDisplayDelete("คำถาม : " + question);
    setOpenArchive(true);
  };

  const handleCloseArchive = () => {
    setOpenArchive(false);
  };

  return (
    <>
      <Grid container spacing={2}>
        {moraleDailyQuestion && moraleDailyQuestion 
          .filter((data) =>
            data.isActive && (selectTopic ? selectTopic === data.idQuestionTopic : true)
          )
          .map((data) => (
            <Grid item xs={12} md={6} lg={4} key={data.idMoraledailyQuestion}>
              <StyledCard elevation={3} sx={{ position: 'relative', borderRadius: '15px',borderColor: getBgCard(data.idQuestionTopic), padding: 2, paddingBottom: 5 }}>
                <div
                  style={{
                    position: 'absolute', 
                    top: 0, 
                    left: 0, 
                    backgroundColor: getBgCard(data.idQuestionTopic),
                    borderBottomRightRadius: '50%',
                    width: 45,
                    height: 45,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                </div>
                <div 
                  style={{
                    position: 'absolute', 
                    top: 0, 
                    left: 0, 
                    backgroundColor: "#FFD923", 
                    color: "#EA8900",
                    borderRadius: '50%', 
                    width: 40, 
                    height: 40, 
                    display: 'flex', 
                    alignItems: 'center', 
                    justifyContent: 'center'
                  }}
                >
                  <Typography style={{ fontSize:'40px', color: "#EA8900", fontWeight:'600' }}>{data.reward}</Typography>
                </div>

                <Container style={{height:270, alignItems: 'center'}}>
                  <Stack display='flex' direction="column" alignItems="center" spacing={1} >
                    <div style={{ alignContent:'center', paddingTop:25, paddingBottom:10}}>
                      <Typography variant="h6" color="primary" textAlign={"center"} sx={{ lineHeight: 1, fontWeight: 'bold', color: getBgCard(data.idQuestionTopic), fontSize: '28px', textAlign:'center'}} >
                        {data.questionTopic}
                      </Typography>
                    </div>
                    <img
                      alt="questionImg"
                      width={140}
                      height={170}
                      src={imgInsight(data.idQuestionTopic)}
                      style={{ borderRadius: '50%', backgroundColor: '#FFF', }}
                    />
                  </Stack>
                </Container>

                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', alignContent:'start', minHeight: "190px" }}>
                  <Typography variant="subtitle1" color="textSecondary"textAlign={"left"}>
                    {`คำถาม:`}
                  </Typography>
                  <Typography variant="h5" textAlign={"left"} sx={{ lineHeight: 1.1, fontSize: '18px', alignContent:'start', marginBottom:1 }}>
                    {data.question}
                  </Typography>
                  <Typography 
                    variant="subtitle1" 
                    color="textSecondary"
                    textAlign={"left"}
                  >
                    {`ประเภทคำตอบ: ${data.questionType === "text" ? "ข้อความ" : "ตัวเลือก"}`}
                  </Typography>
                </div>
                <CardActions>
                  <Button 
                    variant='outlined' 
                    color="primary"
                    sx={{ width: "100%", borderRadius: 2 }}  
                    onClick={() => handleClickArchive(data.question, data.idMoraledailyQuestion)}
                  >
                    เก็บ
                  </Button>
                  <Button 
                    variant='contained'
                    color="secondary"
                    sx={{ width: "100%", borderRadius: 2 }} 
                    onClick={() => handleOpenPublic(data.idMoraledailyQuestion)}
                  >
                    ส่งคำถาม
                  </Button>
                </CardActions>
              </StyledCard>
            </Grid>
          ))
        }
      </Grid>
      {openArchive && (
        <DialogArchive
          idQuestion={idQuestion}
          open={openArchive}
          onClose={handleCloseArchive}
          displayDelete={displayDelete}
        />
      )}
      {openPublic && (
        <DialogPublish
          open={openPublic}
          handleClose={handleClosePublic} 
          idQuestion={idQuestion} 
        />
      )}
    </>
  )
}

export default MoraleDailyActive;
