import feedbackService from "../services/feedback.service";
import { FEEDBACK_FETCHING, FEEDBACK_FAILED, FEEDBACK_SUCCESS } from "./types";

export const getFeedback = (query) => async (dispatch) => {
  try {
    dispatch({
      type: FEEDBACK_FETCHING,
    });
    const res = await feedbackService.getFeedback(query);
    if (res) {
      dispatch({
        type: FEEDBACK_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: FEEDBACK_FAILED,
    });
  }
};

export const addFeedback = (formData) => async (dispatch) => {
  try {
    const res = await feedbackService.addFeedback(formData);
    if (res) {
      return res;
    }
  } catch (err) {
    dispatch({
      type: FEEDBACK_FAILED,
    });
    console.log(err);
  }
};