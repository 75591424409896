import { httpClient } from "./httpClient";

const getAllAssessment = (query) => {
  return httpClient.get("assessment", { params: query });
};

const getAnswerByUserId = (idAssessment, assignTo) => {
  return httpClient.get(
    `assessment/${idAssessment}/answer?assessment=${idAssessment}&assign_to=${assignTo}`
  );
};

const getAssessment = (idAssessment) => {
  return httpClient.get("assessment/" + idAssessment);
};

const getOutsiderAssessment = (idAssessment) => {
  return httpClient.get("assessment-outsider/" + idAssessment);
};

const getOutsiderAssessmentById = (idAppraisee, query) => {
  return httpClient.get(`assessmentQuestion-outsider/${idAppraisee}`, { params: query });
};

const addAssessment = (formData) => {
  return httpClient.post(`assessment`, formData);
};

const updateAssessment = (formData) => {
  return httpClient.patch(`assessment`, formData);
};

const updateAssessmentStatus = (formData) => {
  return httpClient.patch(`assessment/${formData.idAssessment}`, formData);
};

const updateAssessmentQuestion = (formData) => {
  return httpClient.patch("assessment-question", formData);
};

const getAssessmentAnswer = () => {
  return httpClient.get("assessment-answer");
};

const updateAssessmentFavorite = (formData) => {
  return httpClient.patch("assessment/favorite", formData);
};

export default {
  getAllAssessment,
  getAssessment,
  getAnswerByUserId,
  addAssessment,
  updateAssessment,
  updateAssessmentStatus,
  updateAssessmentQuestion,
  getAssessmentAnswer,
  updateAssessmentFavorite,
  getOutsiderAssessment,
  getOutsiderAssessmentById, 
};