import React, { useState, Fragment, useEffect } from "react";
import { styled } from "@mui/material/styles";
import {
  Divider,
  Grid,
  Typography,
  Paper,
  LinearProgress,
  AccordionSummary,
  AccordionDetails,
  Chip,
  linearProgressClasses,
  Stack,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
} from "@mui/material";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import Accordion from "@mui/material/Accordion";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useSelector } from "react-redux";
import clsx from "clsx";
import { Bar } from "react-chartjs-2";
import FeelingIcon from "../feelingIcon";
import unicornCry from "./assets/Unicorn-Mascot-Cry.png";
import unicornHeart from "./assets/Unicorn-Mascot-Heart.png";
import unicornIndifferently from "./assets/Unicorn-Mascot-Indifferently.png";
import { useMoraleKPI } from "../../../../quries/morale";
import { BarChart, Bar as RechartsBar, Cell, ResponsiveContainer, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import { Collapse, Button } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
const BorderLinearProgress = styled(LinearProgress)({
  [`&.${linearProgressClasses.root}`]: {
    height: 10,
    borderRadius: 5,
  },
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#333333",
  },
  [`&.${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "#1a90ff",
  },
});

const BorderLinearProgressDetail = styled(LinearProgress)({
  [`&.${linearProgressClasses.root}`]: {
    height: 8,
    borderRadius: 5,
  },
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "rgba(255, 255, 255, 0.2)",
  },
  [`&.${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "#f66817",
    
  },
});

const colorSet = [
  "#ff5100",
  "#ff9000",
  "#ebc400",
  "#9fc914",
  "#12b09b",
  "#127eb0",
  "#5053a3",
  "#6939a3",
  "#a3399e",
  "#a3395e",
];

const colorSelect = (score) => {
  if (score === 100) {
    return "#178867";
  } else if (score >= 80) {
    return "#649c18";
  } else if (score >= 60) {
    return "#caa401";
  } else if (score >= 40) {
    return "#cb6600";
  } else if (score >= 20) {
    return "#bc293a";
  } else {
    return "#cc3426";
  }
};

// const getScoreColor = (score) => {
//   switch (feelingScoreYearly(score)) {
//     case "veryhappy":
//       return "green";
//     case "happy":
//       return "lightgreen";
//     case "littlehappy":
//       return "yellowgreen";
//     case "littlebad":
//       return "yellow";
//     case "bad":
//       return "orange";
//     case "verybad":
//       return "red";
//     default:
//       return "white";
//   }
// };


const optionsTopic = {
  responsive: true,
  maintainAspectRatio: true,
  plugins: {
    legend: {
          display: false, //Is the legend shown?
          position: "top" //Position of the legend.
        },
    tooltip: {
      backgroundColor: "#333", // Modern tooltip background
      titleColor: "#fff", // Title text color
      bodyColor: "#fff", // Body text color
    },
  },
  scales: {
    xAxes: {
      ticks: {
        color: "#FFFFFF", // X-axis label color
        font: {
          size: 10, // Decrease label font size
        },
      },
      grid: {
        display: false, // Remove gridlines
      },
    },
     yAxes: [
      {
        ticks: {
          fontColor: "#FFFFFF",
          suggestedMin: 0,
          suggestedMax: 100,
          stepSize: 20
        },
        gridLines: {
          display: true,
          color: "rgb(40,40,40)",
        },
      },
    ],
  },
};

const StyledResultIcon = styled("div")({
  display: "flex",
  justifyContent: "center",
});

const WrapSummaryAnswerDepartment = styled(Paper)({
  backgroundColor: "#424242",
  padding: "12px 16px",
  minHeight: 48,
  "& .label": {
    display: "flex",
    justifyContent: "space-between",
    "& .label-left": {
      display: "flex",
      "& .MuiSvgIcon-root, .MuiTypography-root": {
        color: "#FFFFFF",
      },
    },
  },
});

const feelingScoreYearly = (avgScore) => {
  if (avgScore === 100) {
    return "very happy";
  } else if (avgScore >= 80) {
    return "happy";
  } else if (avgScore >= 60) {
    return "little happy";
  } else if (avgScore >= 40) {
    return "little bad";
  } else if (avgScore >= 20) {
    return "bad";
  } else {
    return "very bad";
  }
};

const StyledAccordion = styled(Accordion)({
  backgroundColor: "#424242",
  "& .label": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .label-left": {
      display: "flex",
      alignItems: "center",
      gap: 8,
      paddingBottom: 8,
      "& .MuiSvgIcon-root, .MuiTypography-root": {
        color: "#FFFFFF",
      },
    },
  },
});

const WrapSummaryTopic = styled("div")({
  justifyContent: "center",
  gap: "8px",
  "& .cardTopicSummary": {
    opacity: 0.5,
    justifyContent: "space-between",
    cursor: "pointer",
    borderRadius: "8px",
    transition: "transform 0.3s, box-shadow 0.3s",
    "&:hover": {
      transform: "scale(1.02)",
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
    },
    "& .summary": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      "& .MuiTypography-root": {
        fontSize: 45,
      },
    },
    "@media only screen and (max-width:600px)": {
      width: "100%",
    },
  },
  "& .active": {
    opacity: 1,
  },
  // "& .veryhappy": {
  //   backgroundColor: "#178867",
  // },
  // "& .happy": {
  //   backgroundColor: "#649c18",
  // },
  // "& .littlehappy": {
  //   backgroundColor: "#caa401",
  // },
  // "& .littlebad": {
  //   backgroundColor: "#cb6600",
  // },
  // "& .bad": {
  //   backgroundColor: "#bc293a",
  // },
  // "& .verybad": {
  //   backgroundColor: "#cc3426",
  // },
});

const WrapAverageScore = styled("div")({
  padding:10,
  // display: "flex",
  // flexWrap: "wrap",
  justifyContent: "center",
  gap: "8px",
  "& .cardTopicSummary": {
    opacity: 0.5,
    borderRadius:8,
    width: "fit-content",
    padding: "8px 16px",
    //display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    "& .summary": {
      height:'100%',
      alignItems:'end',
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      "& .MuiTypography-root": {
        fontSize: 36,
      },
    },
    "@media only screen and (max-width:600px)": {
      width: "100%",
    },
  },
  "& .active": {
    opacity: 1,
  },
  "& .veryhappy": {
    backgroundColor: "#178867",
  },
  "& .happy": {
    backgroundColor: "#649c18",
  },
  "& .littlehappy": {
    backgroundColor: "#caa401",
  },
  "& .littlebad": {
    backgroundColor: "#cb6600",
  },
  "& .bad": {
    backgroundColor: "#bc293a",
  },
  "& .verybad": {
    backgroundColor: "#cc3426",
  },
});



const MoraleDetail = ({ idMorale, filterSelected, roles }) => {
  const [selectedTopic, setSelectedTopic] = useState(0);
  const [expandedComment, setExpandedComment] = React.useState("panel1");
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const [expanded, setExpanded] = React.useState(false);
  const {
    data: moraleKPI,
    isPending,
    isError,
  } = useMoraleKPI({
    idMorale,
    query: {
      gender: filterSelected["gender"].itemList
        ?.filter((item) => item.checked)
        .map((item) => item.value),
      generation: filterSelected["generation"].itemList
        ?.filter((item) => item.checked)
        .map((item) => item.value),
      jobLevel: filterSelected["jobLevel"].itemList
        ?.filter((item) => item.checked)
        .map((item) => item.value),
      jobType: filterSelected["jobType"].itemList
        ?.filter((item) => item.checked)
        .map((item) => item.value),
    },
  });

  if (isError) {
    return (
      <Typography variant="h6" color={"#ffffff"}>
        ไม่พบข้อมูล
      </Typography>
    );
  }

  const unicornImage = (x) => {
    if (x >= 80) {
      return unicornHeart;
    } else if (x >= 50) {
      return unicornIndifferently;
    } else {
      return unicornCry;
    }
  };

  const answerTopicGrade = (score) => {
    if (score === 100) {
      return "veryhappy";
    } else if (score >= 80) {
      return "happy";
    } else if (score >= 60) {
      return "littlehappy";
    } else if (score >= 40) {
      return "littlebad";
    } else if (score >= 20) {
      return "bad";
    } else {
      return "verybad";
    }
  };
  
  const dataChartQuestion2 = (moraleKPI?.moraleTopicAvgAndBest[selectedTopic].moraleQuestion || []).map((question) => ({
    questionId: question.idMoraleQuestion || "Unknown", 
    questionData: 
    question.questionType === "choice"  ? question.score.average : 
    question.questionType === "text" && question.questionAnswerText ? 
    question.questionAnswerText.map(
      (item) => item.sentimentScore || 0 ).reduce(
        (a, b) => a + b, 0) / (question.questionAnswerText.map(
          (item) => item.sentimentScore || 0 ).length || 1).toFixed(2) : 0,
    color:
    question.questionType === "choice"  ? colorSelect(question.score.average) : 
    question.questionType === "text" && question.questionAnswerText ? 
    colorSelect(question.questionAnswerText.map(
      (item) => item.sentimentScore || 0).reduce(
        (a, b) => a + b, 0) / (question.questionAnswerText.map(
          (item) => item.sentimentScore || 0 ).length || 1)) : "#cccccc"
  }));

  const dataChartQuestion = () => ({
    labels: moraleKPI.moraleTopicAvgAndBest[selectedTopic].moraleQuestion.map(
      (question) => question.idMoraleQuestion
    ),
    datasets: [
      {
        data: moraleKPI.moraleTopicAvgAndBest[selectedTopic].moraleQuestion.map(
          (question) => {
            if (question.questionType === "choice") {
              return question.score.average;
            } else if (
              question.questionType === "text" &&
              question.questionAnswerText
            ) {
              const textScores = question.questionAnswerText.map(
                (item) => item.sentimentScore || 0
              );
              const averageScore =
                textScores.reduce((a, b) => a + b, 0) /
                (textScores.length || 1);
              return averageScore.toFixed(2);
            }
            return 0;
          }
        ),
        backgroundColor: moraleKPI.moraleTopicAvgAndBest[
          selectedTopic
        ].moraleQuestion.map((question) => {
          if (question.questionType === "choice") {
            return colorSelect(question.score.average);
          } else if (
            question.questionType === "text" &&
            question.questionAnswerText
          ) {
            const textScores = question.questionAnswerText.map(
              (item) => item.sentimentScore || 0
            );
            const averageScore =
              textScores.reduce((a, b) => a + b, 0) / (textScores.length || 1);
            return colorSelect(averageScore);
          }
          return "#cccccc";
        }),
        barThickness: 100,
      },
    ],
  });

  const dataDepartment = () => ({
    labels: [...moraleKPI.moraleDepAvgAndBest.map((dep) => dep.departmentName)],
    datasets: [
      {
        type: "bar",
        barThickness: 30,
        label: "คะแนนประเมินผล",
        backgroundColor: "#00b81e",
        data: [
          ...moraleKPI.moraleDepAvgAndBest.map((dep) => dep.average.toFixed(2)),
        ],
      },
    ],
  });

  const dataDepartment2 = (moraleKPI?.moraleDepAvgAndBest || []).map((dep) => ({
    departmentName: dep.departmentName || "Unknown", // Fallback for questionTopic
    averageDepartment: parseFloat(dep.average?.toFixed(2) || 0), // Default to 0 if average is missing
    color: "#00b81e", // Assign colors cyclically
  }));

  const dataTopic2 = (moraleKPI?.moraleTopicAvgAndBest || []).map((topic, index) => ({
    questionTopic: topic.questionTopic || "Unknown", // Fallback for questionTopic
    average: parseFloat(topic.average?.toFixed(2) || 0), // Default to 0 if average is missing
    color: colorSet[index % colorSet.length], // Assign colors cyclically
  }));

  const handleChangeComment = (panel) => (event, newExpanded) => {
    setExpandedComment(newExpanded ? panel : false);
  };

  const totalParticipant = () => {
    return moraleKPI.moraleResponse.reduce(
      (acc, curr) => curr.participant + acc,
      0
    );
  };

  const totalResponse = () => {
    return moraleKPI.moraleResponse.reduce(
      (acc, curr) => parseInt(curr.response) + acc,
      0
    );
  };

  const avgScore = () => {
    if (!moraleKPI?.moraleTopicAvgAndBest?.length) return 0;
    const totalScore = moraleKPI.moraleTopicAvgAndBest.reduce(
      (sum, topic) => sum + topic.average,
      0
    );
    return Math.ceil(totalScore / moraleKPI.moraleTopicAvgAndBest.length);
  };

  if (isPending) { 
    return (
      <Box  style={{ width:'100%', display: "flex", alignItems: "center", justifyItems:'center', justifyContent:'center'}}>
      <CircularProgress />
    </Box>
    )
  }

  const getTopStrengthsAndWeaknesses = () => {
    const sortedTopics = moraleKPI.moraleTopicAvgAndBest
      .slice()
      .sort((a, b) => b.average - a.average);

    const topStrengths = sortedTopics
      .slice(0, 3)
      .map((topic) => ({ ...topic, type: "Strength" }));

    const topWeaknesses = sortedTopics
      .slice(-3)
      .map((topic) => ({ ...topic, type: "Weakness" }));

    return [...topStrengths, ...topWeaknesses];
  };

  const topTopics = getTopStrengthsAndWeaknesses();

  const StyledCircularProgress = styled(CircularProgress)(({ theme }) => ({
    position: "relative",
    "& .MuiCircularProgress-circle": {
      strokeLinecap: "round",
    },
    "&.MuiCircularProgress-root": {
      color: "#4CAF50", // Progress color
    },
    "&::before": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      borderRadius: "50%",
      background: "rgba(255, 255, 255, 0.2)", // Background track color
    },
  }));
  
  const ProgressContainer = styled("div")({
    marginTop:15,
    position: "relative",
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    width:120,
    height:120
  });
  
  const ProgressLabel = styled("div")({
    width:"65%",  height:"65%",
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "1.6rem",
    color: "#FFFFFF",
   background: "#9170EB",
    borderRadius: "50%",
  });

  const getColorByScore = (score) => {
    if (score == 100) return "#178867"; // Very happy - green
    else if (score >= 80) return "#649c18"; // Happy - light green
    else if (score >= 60) return "#caa401"; // Little happy - yellow
    else if (score >= 40) return "#cb6600"; // Little bad - orange
    else if (score >= 20) return "#bc293a"; // Bad - light red
    return "#cc3426"; // Very bad - red
  };

  const StyledLinearProgress = styled(LinearProgress)(({  value }) => ({
    height: "10px",
    borderRadius: "5px",
    backgroundColor: "#E0E0E0",
    "& .MuiLinearProgress-bar": {
      borderRadius: "5px",
      backgroundColor: getColorByScore(value),
    },
   
  }));

  return (
    <Fragment>
    <Grid container  sx={{ width: '100%' }}>
      <Grid item xs={6} md={8}>
      <div  
            style={{
              display: "flex",
              flexDirection: "row",
              background: "#9170EB",
              borderRadius: "8px",
              padding: "24px",
              marginBottom: 15,    
            }}
          >
            <div style={{ flex: 1, paddingTop: "10px", paddingLeft:"15px", alignContent:'flex-end', height:'100%'}}>
              <Box sx={{backgroundColor:'#2C2C2C', borderRadius:2, width:'40%', height:30, alignContent:'center', marginBottom: 7,}}>
                <Typography style={{ color: "#fff", textAlign:'center', }}>
                  สรุปผลการประเมิน
                </Typography>
              </Box>
              <Box sx={{marginTop:10}} >
                <Typography variant="h6" textAlign="left" color="#FFFFFF">
                  {`คำถาม`}
                </Typography>
                <Typography variant="h3" textAlign="left" color="#FFFFFF">
                  {`${moraleKPI.moraleName}`}
                </Typography>
              </Box>
            </div>
            <div style={{ flex: 1,  borderRadius: "8px", paddingRight:"15px",  }}>
            {userProfile.roles.includes("ROLE_ADMIN") ? (
              <div >
                <Typography variant="h5" style={{ color: "#FFFFFF", marginBottom: "16px",  }}>
                  {`ผู้เข้าร่วมตอบแบบประเมิน (${totalResponse()} / ${totalParticipant()})`}
                </Typography>
                <div style={{ display: "flex",flexDirection: "row", }} >
                  <ProgressContainer>
                    <StyledCircularProgress
                      variant="determinate"
                      value={Math.round((totalResponse() * 100) / totalParticipant())}
                      size={120} // Size of the circular progress bar
                      thickness={8} // Thickness of the progress bar
                    />
                    <ProgressLabel>
                      {`${Math.round((totalResponse() * 100) / totalParticipant())}%`}
                    </ProgressLabel>
                  </ProgressContainer>
                  <div style={{ display: "flex",flexDirection: "column", width:'100%',height:163, paddingLeft:20, justifyContent:'space-around', alignContent:'center'}} >
                    <Typography style={{ color: "#FFFFFF", }}>
                      แยกตามหน่วยงาน
                    </Typography>
                    <Stack spacing={1} style={{ marginTop: "8px" }}>
                    
                      {moraleKPI.moraleResponse.slice(0,2).map((value) => (
                        <div key={value.departmentName}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginBottom: "0.5rem",
                          }}
                          >
                            <Typography color={"#FFFFFF"}>
                              {`${value.departmentName} (${value.response} / ${value.participant})`}
                            </Typography>
                            <Typography color={"#FFFFFF"}>
                              {`${Math.round((value.response * 100) / value.participant)}%`}
                            </Typography>
                          </div>
                          <BorderLinearProgressDetail
                            variant="determinate"
                            value={Math.round((value.response * 100) / value.participant)}
                            sx={{
                              color: "#4CAF50", // Progress color
                            }}
                          />
                        </div>
                      ))}
                    </Stack>
                    {moraleKPI.moraleResponse.length > 2 && (
    <Collapse in={expanded}>
      <Stack spacing={1} style={{ marginTop: "8px" }}>
        {moraleKPI.moraleResponse.slice(2).map((value) => (
          <div key={value.departmentName}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "0.5rem",
              }}
            >
              <Typography color={"#FFFFFF"}>
                {`${value.departmentName} (${value.response} / ${value.participant})`}
              </Typography>
              <Typography color={"#FFFFFF"}>
                {`${Math.round((value.response * 100) / value.participant)}%`}
              </Typography>
            </div>
            <BorderLinearProgressDetail
              variant="determinate"
              value={Math.round((value.response * 100) / value.participant)}
              sx={{
                color: "#4CAF50",
              }}
            />
          </div>
        ))}
      </Stack>
    </Collapse>
  )}

  {moraleKPI.moraleResponse.length > 2 && (
    <Button
      onClick={() => setExpanded(!expanded)}
      style={{
        color: "#FFFFFF",
        marginTop: "8px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Typography style={{ marginRight: "4px", color:'#fff'}}>
        {expanded ? "แสดงน้อยลง" : `แสดงเพิ่มเติม (${moraleKPI.moraleResponse.length - 2})`}
      </Typography>
      {expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
    </Button>
  )}


                  </div>
                </div>
              </div>
              ) : (
              <div style={{ marginTop: 16, marginBottom: 16 }}>
                <WrapSummaryAnswerDepartment>
                  <div className={`inner`}>
                    <div className={`label`}>
                      <div className={`label-left`}>
                        <HowToRegIcon />
                        <Typography>
                          {`ผู้เข้าร่วมตอบแบบประเมิน (${totalResponse()} / ${totalParticipant()})`}
                        </Typography>
                      </div>
                      <div style={{ display: "flex",flexDirection: "row", }} >
                        <ProgressContainer>
                          <StyledCircularProgress
                          variant="determinate"
                          value={Math.round((totalResponse() * 100) / totalParticipant())}
                          size={120} // Size of the circular progress bar
                          thickness={8} // Thickness of the progress bar
                          />
                          <ProgressLabel>
                            {`${Math.round((totalResponse() * 100) / totalParticipant())}%`}
                          </ProgressLabel>
                        </ProgressContainer>
                      </div>
                    </div>
                  </div>
                </WrapSummaryAnswerDepartment>

                {/* หน้า manager */}
                <Typography style={{ color: "white", marginTop: 20 }} variant="h5">
                  ผลการประเมินแบ่งตามหน่วยงาน
                </Typography>
                <Typography style={{ color: "white", marginTop: 20 }} variant="h5">
                  test
                </Typography>
                <div  style={{ backgroundColor: "#424242", borderRadius: "8px", padding: "16px",  marginTop: "16px",  }} >
                  <div style={{ height: 300 }}>
                    <Bar
                      data={dataDepartment}
                      options={{
                        responsive: true,
                        maintainAspectRatio: false,
                        legend: { display: false, },
                        scales: {
                          yAxes: [
                            {
                              stacked: true,
                              ticks: {  beginAtZero: true,fontColor: "#FFFFFF",},
                              gridLines: { display: true,  color: "rgb(40,40,40)", },
                            },
                          ],
                          xAxes: [
                            {
                              stacked: true,
                              ticks: { fontColor: "#FFFFFF", },
                              gridLines: {  display: true,  color: "rgb(40,40,40)",  },
                            },
                          ],
                        },
                        tooltips: {enabled: true, mode: "index",  intersect: false, },
                      }}
                    />
                  </div>
                </div>
              </div>
              )}
            </div>
          </div>
          <Grid container spacing={2}>
          <Grid item xs={6} md={6}>
          <div style={{ backgroundColor: "#ffffff", padding: "20px",  height: "100%", borderRadius: "8px", marginBottom: 15, paddingLeft: "35px", paddingRight: "35px" }} >
                <Typography style={{ color: "#000" }} variant="h5">
                  ผลการประเมินแบ่งตามหน่วยงาน
                </Typography>
                <div style={{ backgroundColor: "#fff",  borderRadius: "8px", marginTop: "16px",  }} >
                  <div style={{ width: "100%" }}>             
                    <ResponsiveContainer width="100%" height={300}>
      <BarChart data={dataDepartment2} margin={{ top: 20,right: 20, left: -30, bottom: 5, }}>
        {/* Background gridlines */}
        <CartesianGrid strokeDasharray="3 3" stroke="#fff" />
        <XAxis
          dataKey="departmentName"
          tick={{ fill: "#333", fontSize: 12 }}
          axisLine={{ stroke: "#333" }}
          tickLine={{ stroke: "#333" }}
        />
        
        <YAxis
          tick={{ fill: "#333", fontSize: 12 }}
          axisLine={{ stroke: "#333" }}
          tickLine={{ stroke: "#333" }}
          domain={[0, 100]} // Y-axis range
        />
        
        <Tooltip
          contentStyle={{ backgroundColor: "#ccc", color: "#000" }}
          cursor={{ fill: "rgba(255, 255, 255, 0.1)" }}
        />
      <RechartsBar dataKey="averageDepartment" barSize={30} label={{ position: 'top', fill: '#000', fontSize: 14 }} > 
        {dataDepartment2.map((entry) => (
          <Cell  fill={entry.color} />
        ))}
      </RechartsBar>
      </BarChart>
    </ResponsiveContainer>

                  </div>
                </div>
              </div>
          </Grid>
         
          <Grid item xs={6} md={6}>
          <div style={{ backgroundColor: "#ffffff", padding: "20px", height: "100%", borderRadius: "8px", marginBottom: 15, paddingLeft: "35px", paddingRight: "35px" }} >
              <Typography style={{ color: "#000" }}  variant="h5"   >
                ผลประเมินประจำปี
              </Typography>
              <div style={{ backgroundColor: "#ffff",  borderRadius: "8px",  marginTop: "16px", }} >
                <div style={{width: "100%", }}>
                  <ResponsiveContainer width="100%" height={300}>
      <BarChart data={dataTopic2} margin={{ top: 20,right: 20, left: -30, bottom: 5, }}>
        {/* Background gridlines */}
        <CartesianGrid strokeDasharray="3 3" stroke="#fff" />
        <XAxis
          dataKey="questionTopic"
          tick={{ fill: "#333", fontSize: 12 }}
          axisLine={{ stroke: "#333" }}
          tickLine={{ stroke: "#333" }}
        />
        
        <YAxis
          tick={{ fill: "#333", fontSize: 12 }}
          axisLine={{ stroke: "#333" }}
          tickLine={{ stroke: "#333" }}
          domain={[0, 100]} // Y-axis range
        />
        
        <Tooltip
          contentStyle={{ backgroundColor: "#ccc", color: "#000" }}
          cursor={{ fill: "rgba(255, 255, 255, 0.1)" }}
        />
      <RechartsBar dataKey="average" barSize={30} label={{ position: 'top', fill: '#000', fontSize: 14 }} > 
        {dataTopic2.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={entry.color} />
        ))}
      </RechartsBar>
      </BarChart>
    </ResponsiveContainer>
                </div>
              </div>
            </div>
          </Grid>
          </Grid>
      </Grid>
      <Grid item xs={6} md={4}>
      <div 
            style={{   
              backgroundColor: "#ffffff", 
              borderRadius: "8px", 
              textAlign: "center", 
              marginBottom: 15,
              marginLeft: 15,
              display: "flex",
              flexDirection: "column",
              // alignItems: "center",
              justifyContent: "center",  
              alignContent: 'space-between',
              height: "100%"
            }}>
            <StyledResultIcon>
              <div style={{ width: "100%",aligeItems:'center' }}>
                <Typography variant="h4" style={{ color: "#000000",  }}>
                  {`คะแนนเฉลี่ย`}
                </Typography>
                <img
                  src={unicornImage(avgScore())}
                  alt="unicorn"      
                  style={{ marginBottom: "5px",  width: "45%", height: "auto", }}
                />
              </div>
            </StyledResultIcon>       
            <WrapAverageScore>
              {moraleKPI.moraleTopicAvgAndBest[0] && (
                <div
                  className={clsx(
                    `cardTopicSummary`,
                    answerTopicGrade(avgScore()),
                    { [`active`]: selectedTopic === 0,}
                  )}
                >
                  <div className={`summary`}>
                    <div style={{  marginRight: 5, width:'12%' }}>
                      <FeelingIcon
                        feeling={answerTopicGrade(avgScore())}
                        unSelected={true}
                      />
                    </div>
                    <div style={{ display: "flex", flexDirection: "row",}}>
                      <Typography variant="h4" style={{ color: "#fff", marginRight:5 }}>
                        {`${avgScore()}% `} 
                      </Typography>
                      <Typography
                        variant="h4"
                        className={answerTopicGrade(avgScore())}
                        color="#ffffff"
                      >
                        {`${feelingScoreYearly(avgScore())}`}
                      </Typography>
                    </div>
                  </div>
                </div>
              )}
            </WrapAverageScore>
          </div>
      </Grid> 
    </Grid>
    <div style={{  display: "flex",flexDirection: "row",marginTop:15, width:'100%', flex: 1, backgroundColor: "#ffffff", padding: "16px", borderRadius: "8px", marginBottom: 15 }}>
      <Fragment>
        <div style={{ paddingTop: "16px", paddingBottom: "16px", display: "flex",flexDirection: "column", marginRight:15, flex: "0 0 50%"}}>
          <Typography  variant="h5" textAlign={"Left"} color="#000" >
            จุดแข็ง 3 อันดับแรก
          </Typography>
          <TableContainer
            component={Paper}
            style={{ marginTop: "16px", backgroundColor: "#fff",}}
          >
            <Table sx={{ borderCollapse: "collapse", }}>
              <TableHead>
                <TableRow >
                  <TableCell
                    sx={{
                      background: "linear-gradient(90deg, #A0E3E2, #C4A1D9)",
                      color: "#000",
                      fontSize: "1rem",
                      width: "50%",
                      border: "1px solid #e0e0e0", // Add border to cell
                      borderBottom: "2px solid #e0e0e0" // Make bottom border thicker for header
                    }}
                  >
                    <strong>หัวข้อ</strong>
                  </TableCell>
                  <TableCell
                    sx={{
                      background: "linear-gradient(90deg, #A0E3E2, #C4A1D9)",
                      color: "#000",
                      fontSize: "1rem",
                      border: "1px solid #e0e0e0", // Add border to cell
                      borderBottom: "2px solid #e0e0e0" // Make bottom border thicker for header
                    }}
                    align="center"
                  >
                    <strong>คะแนนเฉลี่ย (%)</strong>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {topTopics
                  .filter((row) => row.type === "Strength")
                  .map((row, index) => (
                    <TableRow key={index} >
                      <TableCell sx={{ color: "black", border: "1px solid #e0e0e0" }}>
                        {row.questionTopic}
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "black",
                          fontWeight: "bold",
                          border: "1px solid #e0e0e0"
                        }}
                        align="center"
                      >
                        {Math.ceil(row.average)}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div style={{ paddingTop: "16px", paddingBottom: "16px", paddingRight: "16px",display: "flex",flexDirection: "column", flex: "0 0 50%"}}>
          <Typography  variant="h5"  textAlign={"Left"}  color="#000" >
            จุดอ่อน 3 อันดับแรก
          </Typography>

          <TableContainer
            component={Paper}
            style={{ marginTop: "16px", backgroundColor: "#fff" }}
          >
            <Table sx={{ borderCollapse: "collapse" }}>
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      background:
                        "linear-gradient(90deg, #F6B8B8, #F9E1C5)",
                      color: "#000",
                      fontSize: "1rem",
                      width: "50%",
                    }}
                  >
                    <strong>หัวข้อ</strong>
                  </TableCell>
                  <TableCell
                    sx={{
                      background:
                        "linear-gradient(90deg, #F6B8B8, #F9E1C5)",
                      color: "#000",
                      fontSize: "1rem",
                      border: "1px solid #e0e0e0", // Add border to cell
                      borderBottom: "2px solid #e0e0e0" // Make bottom border thicker for header
                    }}
                    align="center"
                  >
                    <strong>คะแนนเฉลี่ย (%)</strong>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {topTopics
                  .filter((row) => row.type === "Weakness")
                  .sort((a, b) => a.average - b.average)
                  .map((row, index) => (
                    <TableRow key={index} >
                      <TableCell style={{ color: "black", border: "1px solid #e0e0e0" }}>
                        {row.questionTopic}
                      </TableCell>
                      <TableCell
                        style={{
                          color: "black",
                          fontWeight: "bold",
                          border: "1px solid #e0e0e0" 
                        }}
                        align="center"
                      >
                        {Math.ceil(row.average)}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          </div>
        </Fragment>
      </div>

      <Grid container spacing={2}>
        <Grid item xs={8}>
        <div style={{ backgroundColor: "#ffffff", padding: "20px", height: "100%", borderRadius: "8px", marginBottom: 15, paddingLeft: "35px", paddingRight: "35px" }} >
              <Typography style={{ color: "#000" }}  variant="h5"   >
                ผลสรุปตามคำถาม
              </Typography>
              <div style={{ backgroundColor: "#ffff",  borderRadius: "8px",  marginTop: "16px", }} >
                <div style={{width: "100%", }}>
                  <ResponsiveContainer width="100%" height={300}>
      <BarChart data={dataChartQuestion2} margin={{ top: 20,right: 20, left: -30, bottom: 5, }}>
        {/* Background gridlines */}
        <CartesianGrid strokeDasharray="3 3" stroke="#fff" />
        <XAxis
          dataKey="questionId"
          tick={{ fill: "#333", fontSize: 12 }}
          axisLine={{ stroke: "#333" }}
          tickLine={{ stroke: "#333" }}
        />
        
        <YAxis
          tick={{ fill: "#333", fontSize: 12 }}
          axisLine={{ stroke: "#333" }}
          tickLine={{ stroke: "#333" }}
          domain={[0, 100]} // Y-axis range
        />
        
        <Tooltip
          contentStyle={{ backgroundColor: "#ccc", color: "#000" }}
          cursor={{ fill: "rgba(255, 255, 255, 0.1)" }}
        />
      <RechartsBar dataKey="questionData" barSize={30} label={{ position: 'top', fill: '#000', fontSize: 14 }} > 
        {dataChartQuestion2.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={entry.color} />
        ))}
      </RechartsBar>
      </BarChart>
    </ResponsiveContainer>
                </div>
              </div>
            </div>
        </Grid>
        <Grid item xs={4}>
        <div style={{ backgroundColor: "#ffffff", padding: "20px", borderRadius: "8px", height: "100%", marginBottom: 15, paddingLeft: "35px", paddingRight: "35px" }} >
              <Typography style={{ color: "#000",  marginBottom:20}}  variant="h5"   >
                ผลสรุปตามหัวข้อ
              </Typography>
                <div style={{width: "100%", }}>
              <WrapSummaryTopic>
              {moraleKPI.moraleTopicAvgAndBest.map((value, index) => {
                const grade = answerTopicGrade(value.average); // Grade classification
                return (
                  <div
                    onClick={() => setSelectedTopic(index)}
                    key={index}
                    className={clsx("cardTopicSummary", grade, {
                      active: selectedTopic === index,
                    })}
                  >
                    <Box key={index} mb={2}>
                      <Box sx={{display: "flex",justifyContent: "space-between",alignItems: "center",marginBottom: "8px",}}>
                        <Typography variant="body1" style={{ fontWeight: "bold" }}>
                          {value.questionTopic}
                        </Typography>
                        <Typography variant="body1" style={{ fontWeight: "bold" }}>
                          {Math.ceil(value.average)}%
                        </Typography>
                      </Box>
                      <StyledLinearProgress
                        variant="determinate"
                        value={Math.ceil(value.average)}
                        //color={value.color}
                      />
                    </Box>
                  </div>
                );
              })}
              </WrapSummaryTopic>
            </div>
          </div>
        </Grid>
      </Grid>
          <div style={{ paddingBottom: "16px", marginTop:15 }}>  
            <div style={{ backgroundColor: "#fff", borderRadius: "8px",  padding: "16px", }}>
              <div >
                {moraleKPI.moraleTopicAvgAndBest[selectedTopic].moraleQuestion && (
                  <div>
                    <Typography variant="subtitle1" align="left" style={{ color: "#000", fontSize: 22 }}>
                      คำถาม
                    </Typography>
                    <div>
                      {moraleKPI.moraleTopicAvgAndBest[
                        selectedTopic
                      ].moraleQuestion.map((question, index) => {
                        return (
                          <Accordion
                            square
                            expanded={expandedComment === "panel" + index}
                            onChange={handleChangeComment("panel" + index)}
                            sx={{
                              "&:hover": {
                                backgroundColor: "#f5f5f5",
                              },
                            }}
                          >
                            <AccordionSummary
                              aria-controls="panel1d-content"
                              id="panel1d-header"
                              expandIcon={<ExpandMoreIcon />}
                            >
                              <Stack
                                direction="row"
                                alignItems="center"
                                justifyContent="space-between"
                                width={"100%"}
                              >
                                <Typography>
                                  {question.idMoraleQuestion}.{" "}
                                  {question.question}
                                </Typography>
                                <Chip
                                  label={
                                    question.questionType === "choice"
                                      ? "คำตอบแบบตัวเลือก"
                                      : "คำตอบแบบข้อความ"
                                  }
                                  color={
                                    question.questionType === "choice"
                                      ? "primary"
                                      : "secondary"
                                  }
                                  variant="outlined"
                                />
                              </Stack>
                            </AccordionSummary>
                            <AccordionDetails
                              style={{ display: "inline-block" }}
                            >
                              {question.questionType === "choice" ? (
                                <Typography>ข้อเสนอแนะ</Typography>
                              ) : (
                                <Typography>คำตอบ</Typography>
                              )}

                              {question.questionType === "text" ? (
                                question.questionAnswerText.map(
                                  (item, answerIndex) => (
                                    <div
                                      key={item.idMoraleAnswer}
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        width: "100%",
                                      }}
                                    >
                                      <Typography style={{ flex: 1 }}>
                                        {"- "}
                                        {item.answer}
                                      </Typography>
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "flex-end",
                                          alignItems: "right",
                                          padding: "10px",
                                        }}
                                      >
                                        <Chip
                                          label={`คะแนนความรู้สึก: ${item.sentimentScore} %`}
                                        />
                                      </div>
                                    </div>
                                  )
                                )
                              ) : question.questionFeedback.length > 0 ? (
                                question.questionFeedback.map((item) => (
                                  <Typography
                                    key={item.idMoraleQuestionFeedback}
                                  >
                                    {"- "}
                                    {item.feedback}
                                  </Typography>
                                ))
                              ) : (
                                <Typography>{"ไม่มีข้อเสนอแนะ"}</Typography>
                              )}
                            </AccordionDetails>
                          </Accordion>
                        );
                      })}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div> 
    </Fragment>
  );
};

export default MoraleDetail;
