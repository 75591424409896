import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import UniLogo from "../assets/UNI_HR_Logo_RGB_Full_Color.png";
import universeBackground from "./universeBackground.jpg";
import {
  styled,
  Typography,
  Box,
  InputBase,
  Card,
  Button,
  Toolbar,
} from "@mui/material";
import { getOutsiderAssessment } from "../../../actions/assessment";

const BtnLogin1 = styled(Button)({
  color: "#ffffff",
  "&:hover": {
    borderBottom: "5px solid black",
  },
});

const StyledRoot = styled("div")({
  display: "flex",
  flexDirection: "column",
  padding: 15,
  marginTop: 20,
  margin: "0px auto",
  minWidth: 350,
  minHeight: "100vh",
  backgroundColor: "#000000",
  flexGrow: 1,
  backgroundImage: `url(${universeBackground})`,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
});

const Outsider = () => {
  const dispatch = useDispatch();
  const [outsiderEmail, setOutsiderEmail] = useState([]);
  const [isValidEmail, setIsValidEmail] = useState(false);

  const navigate = useNavigate();

  const handleEmailSave = (event) => {
    const email = event.target.value;
    setOutsiderEmail(email);

    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    setIsValidEmail(emailRegex.test(email));
  };

  const handleFormSubmit = async () => {
    if (!isValidEmail) {
      alert("โปรดกรอกอีเมลที่ถูกต้อง");
      return;
    }

    try {
      const response = await dispatch(getOutsiderAssessment(outsiderEmail));

      if (!response || response.error) {
        alert("โปรดกรอกอีเมลที่ถูกต้อง");
        return;
      }

      navigate("/outsider-question", { state: { email: outsiderEmail } });
    } catch (error) {
      alert("โปรดกรอกอีเมลที่ถูกต้อง");
    }
  };

  return (
    <>
      <StyledRoot className={"page"} sx={{}}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Card
            sx={{
              backgroundColor: "white",
              boxShadow: "none",
              borderRadius: "45px",
              width: "95%",
              justifyContent: "center",
              alingItems: "center",
            }}
          >
            <Toolbar
              sx={{
                display: "flex",
                justifyContent: "space-between",
                gap: "20px",
              }}
            >
              <Link to="/landing">
                <div style={{ width: "112px", height: "63px" }}>
                  <img
                    alt="uni-culture-logo"
                    src={UniLogo}
                    style={{
                      marginTop: "-17.5px",
                      marginLeft: "-25px",
                      width: "120%",
                      height: "160%",
                      objectFit: "contain",
                    }}
                  />
                </div>
              </Link>

              <Box>
                <BtnLogin1 sx={{ color: "black" }} component={Link} to="/landing">
                  About Us
                </BtnLogin1>

                <BtnLogin1 sx={{ color: "black" }} component={Link} to="/login">
                  Log In
                </BtnLogin1>
              </Box>
            </Toolbar>
          </Card>
        </Box>

        <Typography
          variant="h2"
          sx={{
            color: "white",
            marginTop: "150px",
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          Welcome
        </Typography>

        <Box
          sx={{
            marginTop: "16px",
            width: "400px",
            alignItems: "center",
            justifyContent: "center",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingTop: "32px",
            }}
          >
            <Box>
              <Card
                sx={{
                  padding: "8px",
                  borderRadius: "30px",
                  width: "300px",
                  alignItems: "center",
                }}
              >
                <InputBase
                  placeholder="อีเมล"
                  name="outsiderEmail"
                  sx={{ width: "250px", paddingLeft: "10px" }}
                  value={outsiderEmail.value}
                  onChange={handleEmailSave}
                  inputProps={{ maxLength: 200 }}
                />
              </Card>
            </Box>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "30px",
            }}
          >
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#bd6acf",
                borderRadius: "15px",
                width: "150px",
                "&:hover": {
                  backgroundColor: "#dda2ea",
                },
              }}
              onClick={handleFormSubmit}
              disabled={!isValidEmail}
            >
              เข้าสู่แบบประเมิน
            </Button>
          </div>
        </Box>
      </StyledRoot>
    </>
  );
};

export default Outsider;
