import React, { useState } from "react";
import {
  Autocomplete,
  Avatar,
  Button,
  ListItemSecondaryAction,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";

const StyledAutocomplete = styled(Autocomplete)({
  minWidth: "400px",
  backgroundColor: "#282828",
  "& .MuiOutlinedInput-root": {
    color: "#ffffff",
  },
  fieldset: {
    borderColor: "#ffffff",
  },
  "& .MuiFormLabel-root,& .MuiFormLabel-root.Mui-focused": {
    color: "#ffffff",
  },
});

const getUniqueEmployees = (data, companySelected) => {
  const seen = new Set();
  return data.filter((employee) => {
    const uniqueIdentifier = `${companySelected.idCompany}-${employee.firstname_TH} ${employee.lastname_TH}`;
    if (seen.has(uniqueIdentifier)) {
      return false;
    }
    seen.add(uniqueIdentifier);
    return true;
  });
};

const feelingScore = (score) => {
  if (score === 100) {
    return "veryhappy";
  } else if (score >= 80) {
    return "happy";
  } else if (score >= 60) {
    return "littlehappy";
  } else if (score >= 40) {
    return "littlebad";
  } else if (score >= 20) {
    return "bad";
  } else {
    return "verybad";
  }
};

const getScoreColor = (score) => {
  switch (feelingScore(score)) {
    case "veryhappy":
      return { color: "green" };
    case "happy":
      return { color: "lightgreen" };
    case "littlehappy":
      return { color: "yellowgreen" };
    case "littlebad":
      return { color: "yellow" };
    case "bad":
      return { color: "orange" };
    case "verybad":
      return { color: "red" };
    default:
      return { color: "white" };
  }
};

const TableMoraleDaily = ({ filterData, exportFile, companySelected }) => {
  const [filterEmployee, setFilterEmployee] = useState(null);
  const uniqueEmployees = getUniqueEmployees(filterData, companySelected);
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "16px",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <PersonIcon sx={{ color: "#000" }} />
          <Typography variant="h5" color={"#000"}>
            รายชื่อพนักงาน
          </Typography>
        </div>
        <div style={{ display: "flex", gap: "8px" }}>
          <StyledAutocomplete
            options={uniqueEmployees}
            value={filterEmployee}
            onChange={(event, newValue) => setFilterEmployee(newValue)}
            getOptionLabel={(option) =>
              `${option.firstname_TH} ${option.lastname_TH} - ${option.positionName}`
            }
            isOptionEqualToValue={(option, value) =>
              option.idEmployees === value.idEmployees
            }
            renderInput={(params) => (
              <TextField {...params} label="เลือกพนักงาน" />
            )}
          />
          <Button
            variant="contained"
            onClick={exportFile}
            disabled={uniqueEmployees.length === 0}
          >
            ส่งออกไฟล์
          </Button>
        </div>
      </div>
      <TableContainer
        component={Paper}
        sx={{
          maxHeight: 600,
          backgroundColor: "#000000",
          border: "1px solid #2e2e2e",
        }}
      >
        <Table stickyHeader sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  background: "#2e2e2e",
                  color: "#ffffff",
                  fontSize: "1rem",
                  borderColor: "#2e2e2e",
                }}
              >
                ชื่อ-นามสกุล
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  background: "#2e2e2e",
                  color: "#ffffff",
                  fontSize: "1rem",
                  borderColor: "#2e2e2e",
                }}
              >
                ฝ่าย
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  background: "#2e2e2e",
                  color: "#ffffff",
                  fontSize: "1rem",
                  borderColor: "#2e2e2e",
                }}
              >
                ส่วน
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  background: "#2e2e2e",
                  color: "#ffffff",
                  fontSize: "1rem",
                  borderColor: "#2e2e2e",
                }}
              >
                แผนก
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  background: "#2e2e2e",
                  color: "#ffffff",
                  fontSize: "1rem",
                  borderColor: "#2e2e2e",
                }}
              >
                ตำแหน่ง
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  background: "#2e2e2e",
                  color: "#ffffff",
                  fontSize: "1rem",
                  borderColor: "#2e2e2e",
                  borderRight: "1px solid #2e2e2e",
                }}
              >
                คะแนนเฉลี่ย
              </TableCell>
              {filterData[0].questionTopics.map((item) => (
                <TableCell
                  key={item.idQuestionTopic}
                  align="center"
                  sx={{
                    background: "#2e2e2e",
                    color: "#ffffff",
                    fontSize: "1rem",
                    borderColor: "#2e2e2e",
                  }}
                >
                  {item.questionTopic}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {filterData
              .filter(
                (item) =>
                  item.idCompany === companySelected.idCompany &&
                  (filterEmployee
                    ? item.idEmployees === filterEmployee.idEmployees
                    : true)
              )
              .map((row) => (
                <TableRow
                  key={row.idEmployees}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{
                      borderColor: "#2e2e2e",
                      whiteSpace: "normal",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "16px",
                        wordWrap: "break-word",
                        width: "250px",
                      }}
                    >
                      <Avatar src={row.imageURL} />
                      <Typography color={"#ffffff"}>
                        {`${row.firstname_TH} ${row.lastname_TH}`}
                      </Typography>
                    </div>
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ color: "#ffffff", borderColor: "#2e2e2e" }}
                  >
                    {row.divisionName}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ color: "#ffffff", borderColor: "#2e2e2e" }}
                  >
                    {row.departmentName}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ color: "#ffffff", borderColor: "#2e2e2e" }}
                  >
                    {row.sectionName}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ color: "#ffffff", borderColor: "#2e2e2e" }}
                  >
                    {row.positionName}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: getScoreColor(row.averageScore),
                      borderColor: "#2e2e2e",
                      borderRight: "1px solid #2e2e2e",
                      fontWeight: "bold",
                    }}
                  >
                    {Math.ceil(row.averageScore)}
                  </TableCell>
                  {row.questionTopics.map((item) => (
                    <TableCell
                      key={item.idQuestionTopic}
                      align="center"
                      sx={{
                        color: getScoreColor(item.averageScore),
                        borderColor: "#2e2e2e",
                      }}
                    >
                      {Math.ceil(item.averageScore)}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default TableMoraleDaily;
