import {
  MORALE_DAILYQUESTIONS_FETCHING,
  MORALE_DAILYQUESTIONS_FAILED,
  MORALE_DAILYQUESTIONS_SUCCESS,
  MORALE_DAILY_FETCHING,
  MORALE_DAILY_FAILED,
  MORALE_DAILY_SUCCESS,
  MORALE_DAILY_ITEM_FETCHING,
  MORALE_DAILY_ITEM_FAILED,
  MORALE_DAILY_ITEM_SUCCESS,
  MORALE_DAILY_USER_FETCHING,
  MORALE_DAILY_USER_FAILED,
  MORALE_DAILY_USER_SUCCESS,
  MORALE_DAILY_CURRENT_FETCHING,
  MORALE_DAILY_CURRENT_FAILED,
  MORALE_DAILY_CURRENT_SUCCESS,
} from "./types";

import moraleDailyquestionService from "../services/morale-dailyquestion.service";

//MoraleDailyQuestion
export const getAllMoraleDailyQuestion = (query) => async (dispatch) => {
  try {
    dispatch({
      type: MORALE_DAILYQUESTIONS_FETCHING,
    });
    const res = await moraleDailyquestionService.getAllMoraleDailyQuestion(query);
    if (res) {
      dispatch({
        type: MORALE_DAILYQUESTIONS_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: MORALE_DAILYQUESTIONS_FAILED,
    });
    console.log(err);
  }
};

export const addMoraledailyQuestion = (formData) => async (dispatch) => {
  try {
    const res = await moraleDailyquestionService.addMoraleDailyQuestion(
      formData
    );
    if (res) {
      return res;
    }
  } catch (err) {
    dispatch({
      type: MORALE_DAILYQUESTIONS_FAILED,
    });
  }
};

export const updateMoraleQuestion = (formData) => async (dispatch) => {
  try {
    const res = await moraleDailyquestionService.updateMoraleDailyQuestion(
      formData
    );
    if (res) {
      return res;
    }
  } catch (err) {
    dispatch({
      type: MORALE_DAILYQUESTIONS_FAILED,
    });
    console.log(err);
  }
};

//MoraleDaily
export const getAllMoraleDaily = (query) => async (dispatch) => {
  try {
    dispatch({
      type: MORALE_DAILY_FETCHING,
    });
    const res = await moraleDailyquestionService.getAllMoraleDaily(query);
    if (res) {
      dispatch({
        type: MORALE_DAILY_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: MORALE_DAILY_FAILED,
    });
    console.log(err);
  }
};

export const getMoraleDailyActive = (query) => async (dispatch) => {
  try {
    dispatch({
      type: MORALE_DAILY_FETCHING,
    });
    const res = await moraleDailyquestionService.getMoraleDailyActive(query);
    if (res) {
      dispatch({
        type: MORALE_DAILY_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: MORALE_DAILY_FAILED,
    });
  }
};

export const getMoraleDaily = (id) => async (dispatch) => {
  try {
    dispatch({
      type: MORALE_DAILY_ITEM_FETCHING,
    });
    const res = await moraleDailyquestionService.getMoraleDaily(id);
    if (res) {
      dispatch({
        type: MORALE_DAILY_ITEM_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: MORALE_DAILY_ITEM_FAILED,
    });
  }
};

export const addMoraleDaily = (formData) => async (dispatch) => {
  try {
    const res = await moraleDailyquestionService.addMoraleDaily(formData);
    if (res) {
      return res;
    }
  } catch (err) {
    dispatch({
      type: MORALE_DAILY_FAILED,
    });
  }
};

//MoraleDailyAnswer
export const addMoraleDailyAnswer = (formData) => async (dispatch) => {
  try {
    const res = await moraleDailyquestionService.addMoraleDailyAnswer(formData);
    if (res) {
      return res;
    }
  } catch (err) {
    dispatch({
      type: MORALE_DAILY_FAILED,
    });
  }
};

export const getMoraleDailyByUserId = () => async (dispatch) => {
  try {
    dispatch({
      type: MORALE_DAILY_USER_FETCHING,
    });
    const res = await moraleDailyquestionService.getMoraleDailyByUserId();
    if (res) {
      dispatch({
        type: MORALE_DAILY_USER_SUCCESS,
        payload: res.data,
      });
      return res.data;
    }
  } catch (err) {
    dispatch({
      type: MORALE_DAILY_USER_FAILED,
    });
  }
};

export const getMoraleDailyCurrent = () => async (dispatch) => {
  try {
    dispatch({
      type: MORALE_DAILY_CURRENT_FETCHING,
    });
    const res = await moraleDailyquestionService.getMoraleDailyCurrent();
    if (res) {
      dispatch({
        type: MORALE_DAILY_CURRENT_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: MORALE_DAILY_CURRENT_FAILED,
    });
    console.log(err);
  }
};

export const getQuestionDailyAnswerText = (query) => async (dispatch) => {
  try {
    dispatch({
      type: MORALE_DAILYQUESTIONS_FETCHING,
    });

    const res = await moraleDailyquestionService.getQuestionDailyAnswerText(query);

    if (res) {
      dispatch({
        type: MORALE_DAILYQUESTIONS_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: MORALE_DAILYQUESTIONS_FAILED,
    });
    console.error(err);
  }
};
