import {
  APPRAISEE_FETCHING,
  APPRAISEE_FAILED,
  APPRAISEE_SUCCESS,
  APPRAISEE_USER_FETCHING,
  APPRAISEE_USER_FAILED,
  APPRAISEE_USER_SUCCESS,
  APPRAISEE_ITEM_FETCHING,
  APPRAISEE_ITEM_FAILED,
  APPRAISEE_ITEM_SUCCESS,
} from "./types";

import AppraiseeService from "../services/appraisee.service";

export const getAllAppraisee = (query) => async (dispatch) => {
  try {
    dispatch({
      type: APPRAISEE_FETCHING,
    });
    const res = await AppraiseeService.getAllAppraisee(query);
    if (res) {
      dispatch({
        type: APPRAISEE_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: APPRAISEE_FAILED,
    });
    console.log(err);
  }
};

export const getAppraisee = (idAppraisee) => async (dispatch) => {
  try {
    dispatch({
      type: APPRAISEE_ITEM_FETCHING,
    });
    const res = await AppraiseeService.getAppraisee(idAppraisee);
    if (res) {
      dispatch({
        type: APPRAISEE_ITEM_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: APPRAISEE_ITEM_FAILED,
    });
  }
};

export const addAppraisee = (formData) => async (dispatch) => {
  try {
    const res = await AppraiseeService.addAppraisee(formData);
    if (res) {
      return res;
    }
  } catch (err) {
    dispatch({
      type: APPRAISEE_FAILED,
    });
    console.log(err);
  }
};

export const addAnswerAppraisee = (formData) => async (dispatch) => {
  try {
    const res = await AppraiseeService.addAnswerAppraisee(formData);
    if (res) {
      return res;
    }
  } catch (err) {
    dispatch({
      type: APPRAISEE_FAILED,
    });
    console.log(err);
  }
};

export const addAnswerOutsider = (formData) => async (dispatch) => {
  try {
    const res = await AppraiseeService.addAnswerOutsider(formData);
    if (res) {
      return res;
    }
  } catch (err) {
    console.log(err);
  }
};


export const getAppraiseeUser = () => async (dispatch) => {
  try {
    dispatch({
      type: APPRAISEE_USER_FETCHING,
    });

    const res = await AppraiseeService.getAppraiseeUser();

    if (res) {
      dispatch({
        type: APPRAISEE_USER_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    console.error("Error fetching appraisee user:", err); 
    dispatch({
      type: APPRAISEE_USER_FAILED,
      payload: err?.message || "Unknown error",
      error: true,
    });
  }
};

export const updateAppraisee = (formData) => async (dispatch) => {
  try {
    const res = await AppraiseeService.updateAppraisee(formData);
    if (res) {
      return res;
    }
  } catch (err) {
    dispatch({
      type: APPRAISEE_FAILED,
    });
  }
};

