import React, { useState } from "react";
import {
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";

const StyledTableCell = styled(TableCell)({
  color: "#ffffff",
  fontWeight: "bold",
  padding: "16px",
  borderColor: "#2e2e2e",
  width: "50%",
});

const getUniqueEmployees = (data) => {
  const seen = new Set();
  return data.filter((employee) => {
    const name = `${employee.firstname_TH} ${employee.lastname_TH}`;
    if (seen.has(name)) {
      return false;
    }
    seen.add(name);
    return true;
  });
};

const feelingScore = (score) => {
  if (score === 100) {
    return "veryhappy";
  } else if (score >= 80) {
    return "happy";
  } else if (score >= 60) {
    return "littlehappy";
  } else if (score >= 40) {
    return "littlebad";
  } else if (score >= 20) {
    return "bad";
  } else {
    return "verybad";
  }
};

const getScoreColor = (score) => {
  switch (feelingScore(score)) {
    case "veryhappy":
      return { color: "green" };
    case "happy":
      return { color: "lightgreen" };
    case "littlehappy":
      return { color: "yellowgreen" };
    case "littlebad":
      return { color: "yellow" };
    case "bad":
      return { color: "orange" };
    case "verybad":
      return { color: "red" };
    default:
      return { color: "white" };
  }
};

const getTop3Strengths = (filterData) => {
  const topicStrengths = [];

  filterData.forEach((row) => {
    row.questionTopics.forEach((item) => {
      topicStrengths.push({
        topic: item.questionTopic,
        averageScore: item.averageScore,
      });
    });
  });

  const top3Topics = topicStrengths
    .sort((a, b) => b.averageScore - a.averageScore)
    .slice(0, 3);

  return top3Topics;
};

const Top3StrengthDailyTable = ({ filterData, exportFile }) => {
  const [filterEmployee, setFilterEmployee] = useState(null);
  const uniqueEmployees = getUniqueEmployees(filterData);
  const top3Topics = getTop3Strengths(filterData);
  return (
    <div>
      <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
        <PersonIcon sx={{ color: "#000" }} />
        <Typography  variant="h5" textAlign={"Left"} color="#000" >
          จุดแข็ง 3 อันดับแรก
        </Typography>
      </div>
      <TableContainer
        component={Paper}
        sx={{ marginTop: "16px", backgroundColor: "#fff", }}
      >
        <Table stickyHeader sx={{ borderCollapse: "collapse", }}>
          <TableHead>
            <TableRow>
              <StyledTableCell
                sx={{
                  fontSize: "1rem",
                  background: "linear-gradient(90deg, #A0E3E2, #C4A1D9)",
                  color: "#000",
                  width: "50%",
                  border: "1px solid #e0e0e0", // Add border to cell
                  borderBottom: "2px solid #e0e0e0" // Make bottom border thicker for header
                }}
              >
                <strong>หัวข้อ</strong>
              </StyledTableCell>
              <StyledTableCell
                align="center"
                sx={{
                  background: "linear-gradient(90deg, #A0E3E2, #C4A1D9)",
                  color: "#000",
                  fontSize: "1rem",
                  border: "1px solid #e0e0e0", // Add border to cell
                  borderBottom: "2px solid #e0e0e0" // Make bottom border thicker for header
                }}
              >
                <strong>คะแนนเฉลี่ย (%)</strong>
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {top3Topics.map((row) => (
              <TableRow key={row.topic} >
                <StyledTableCell component="th" scope="row" sx={{ color: "black", border: "1px solid #e0e0e0" }} >
                  <Typography color={"#000"}>{row.topic}</Typography>
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    border: "1px solid #e0e0e0",
                    color: getScoreColor(row.averageScore),
                  }}
                >
                  {Math.ceil(row.averageScore)}
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default Top3StrengthDailyTable;
