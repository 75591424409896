import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import {
  Divider,
  Typography,
  Chip,
  Card,
  Box,
  Grid,
  Tooltip,
  MenuItem,
  Select,
  Dialog,
  DialogTitle,
  DialogContent,
  Alert,
  DialogActions,
  Button,
} from "@mui/material";

import { getAllAssessment, updateAssessmentStatus } from "../../../../../actions/assessment";
import { updateAssessmentFavorite } from "../../../../../actions/assessment";
import { getAllAppraisee } from "../../../../../actions/appraisee";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import UnarchiveOutlinedIcon from '@mui/icons-material/UnarchiveOutlined';
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import CreateDuplicateAssessment from "../createAssessment/CreateDuplicateAssessment";
import LoadingIcon from "../../../shared/general/loadingIcon";
import NoData from "../assets/Nodata.jpg";
import { toast } from "sonner";

const currentYear = dayjs().year();

const DilaogDelete = ({ open, handleClose, value, handleDelete }) => {
  return (
    <Dialog
      fullWidth
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>{"จัดเก็บชุดคำถาม"}</DialogTitle>
      <DialogContent>
        <Typography fontWeight={500}>
          {value.assessmentName}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>
          {"ยกเลิก"}
        </Button>
        <Button variant="contained" onClick={handleDelete}>
          {"ยืนยัน"}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const AssessmentList = (value) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [alignment, setAlignment] = useState("all");
  const [selectedAssessment, setSelectedAssessment] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [assessmentQuestions, setAssessmentQuestions] = useState(
    selectedAssessment?.questionList || []
  );

  const assessmentList = useSelector((state) => state.assessment);
  const { result: appraiseeList, isFetching } = useSelector(
    (state) => state.appraisee
  );

  const [favorite, setFavorite] = useState([]);

  const handleToggleFavorite = async (idAssessment, currentFavorite) => {
    const newFavoriteStatus = currentFavorite === 0 ? 1 : 0;
    const formData = { idAssessment, favorite: newFavoriteStatus };

    setFavorite((prevFavorites) => {
      if (newFavoriteStatus === 1) {
        return [...prevFavorites, idAssessment];
      } else {
        return prevFavorites.filter((favId) => favId !== idAssessment);
      }
    });

    const res = await dispatch(updateAssessmentFavorite(formData));
    if (res.status === 200) {
      toast.success("แก้ไขข้อมูลสำเร็จ");
      dispatch(getAllAssessment());
    } else {
      toast.error("เกิดข้อผิดพลาด");
    }
  };

  const sortedAssessments = assessmentList.result
    ? [...assessmentList.result].sort((a, b) => {
        const aIsFavorite =
          a.favorite === 1 || favorite.includes(a.idAssessment);
        const bIsFavorite =
          b.favorite === 1 || favorite.includes(b.idAssessment);
        return bIsFavorite - aIsFavorite;
      })
    : [];

  const refreshAssessments = () => {
    dispatch(getAllAssessment());
  };

  const [selectYear, setSelectYear] = useState(currentYear);

  useEffect(() => {
    dispatch(getAllAssessment({ year: selectYear }));
  }, [selectYear]);

  const handleClickDescription = (id) => {
    navigate("/admin/manage/assessment360/" + id);
  };

  const handleClickUse = (id) => {
    navigate("/admin/manage/editassessment360/" + id);
  };

  // const isAssessmentActive = (assessmentId) => {
  //   const currentDate = new Date();

  //   if (!appraiseeList || !Array.isArray(appraiseeList)) {
  //     return false;
  //   }
  //   const appraisee = appraiseeList.find(
  //     (item) => item.idAssessment === assessmentId
  //   );

  //   if (appraisee && appraisee.startDate && appraisee.endDate) {
  //     const start = new Date(appraisee.startDate);
  //     const end = new Date(appraisee.endDate);

  //     return currentDate >= start && currentDate <= end;
  //   }
  //   return false;
  // };

  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  const [openModal, setOpenModal] = useState(false);
  const [currentAssessment, setCurrentAssessment] = useState(null);

  const handleOpenModal = (value) => {
    setOpenModal(true);
    setSelectedAssessment(value);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setCurrentAssessment(null);
  };

  const handleOpenDelete = (value) => {
    setOpenDelete(true)
    setSelectedAssessment(value)
  };

  const handleCloseDelete = () => {
    setOpenDelete(false)
    setSelectedAssessment(null)
  };

  const handleDelete = async () => {
    const res = await dispatch(updateAssessmentStatus({
      idAssessment: selectedAssessment.idAssessment,
      isActive: !selectedAssessment.isActive
    }));
    if (res && res.status === 200) {
      toast.success("สำเร็จ")
      dispatch(getAllAssessment({ year: selectYear }));
    } else {
      toast.error("เกิดข้อผิดพลาด")
    }
    handleCloseDelete();
  };

  const handleUnArchive = async (assessment) => {
    const res = await dispatch(updateAssessmentStatus({
      idAssessment: assessment.idAssessment,
      isActive: !assessment.isActive
    }));
    if (res && res.status === 200) {
      toast.success("สำเร็จ")
      dispatch(getAllAssessment({ year: selectYear }));
    } else {
      toast.error("เกิดข้อผิดพลาด")
    }
  };

  return (
    <container>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box>
          <Typography fontWeight={500} gutterBottom>
            สถานะคำถาม
          </Typography>
          <ToggleButtonGroup
            exclusive
            value={alignment}
            onChange={handleAlignment}
            color="secondary"
          >
            <ToggleButton value="all">ทั้งหมด</ToggleButton>
            <ToggleButton value="inProgress">กำลังดำเนินการ</ToggleButton>
            <ToggleButton value="archive">จัดเก็บ</ToggleButton>
          </ToggleButtonGroup>
        </Box>
        <Box>
          <Typography fontWeight={500} gutterBottom>
            ประจำปี
          </Typography>
          <Select
            value={selectYear}
            onChange={(event) => setSelectYear(event.target.value)}
            disabled={isFetching}
          >
            <MenuItem value={currentYear}>{currentYear}</MenuItem>
            <MenuItem value={currentYear - 1}>{currentYear - 1}</MenuItem>
            <MenuItem value={currentYear - 2}>{currentYear - 2}</MenuItem>
            <MenuItem value={currentYear - 3}>{currentYear - 3}</MenuItem>
            <MenuItem value={currentYear - 4}>{currentYear - 4}</MenuItem>
          </Select>
        </Box>
      </Box>

      {/* <select
        value={selectYear}
        onChange={(event) => setSelectYear(event.target.value)}
      >
        <MenuItem value={currentYear}>{currentYear} </MenuItem>
        <MenuItem value={currentYear - 1}>{currentYear - 1} </MenuItem>
      </select> */}

      {isFetching || assessmentList.isFetching ? (
        <LoadingIcon />
      ) : sortedAssessments.length === 0 ? (
        <Box sx={{ textAlign: "center", marginTop: "20px" }}>
          <img
            src={NoData}
            alt="No Data"
            style={{
              width: "40%",
              height: "40%",
            }}
          />
          <Typography
            variant="h6"
            align="center"
            sx={{ marginTop: "20px", color: "#888888" }}
          >
            ไม่มีคำถาม
          </Typography>
        </Box>
      ) : (
        <Grid container spacing={2} sx={{ marginTop: "20px" }}>
          {sortedAssessments.filter((value) => {
            if (alignment === "inProgress") {
              return value.inProgress;
            } else if (alignment === "archive") {
              return !value.isActive;
            } else {
              return value.isActive;
            }
          }).length === 0 ? (
            <Box sx={{ textAlign: "center", marginTop: "20px" }}>
              <img
                src={NoData}
                alt="No Data"
                style={{
                  width: "40%",
                  height: "70%",
                }}
              />
              <Typography
                variant="h6"
                align="center"
                sx={{ marginTop: "20px", color: "#888888" }}
              >
                ไม่มีคำถาม
              </Typography>
            </Box>
          ) : (
            sortedAssessments
              .filter((value) => {
                if (alignment === "inProgress") {
                  return value.inProgress;
                } else if (alignment === "archive") {
                  return !value.isActive;
                } else {
                  return value.isActive;
                }
              })
              .map((value) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  key={value.idAssessment}
                  sx={{ padding: 2 }}
                >
                  <Card
                    sx={{
                      height: "100%",
                      borderRadius: "12px",
                      boxShadow: 3,
                      padding: 2,
                      transition: "transform 0.3s, box-shadow 0.3s",
                      "&:hover": {
                        transform: "scale(1.02)",
                        boxShadow: 6,
                      },
                    }}
                  >
                    <Card
                      sx={{
                        maxHeight: "100%",
                        borderRadius: "12px",
                        justifyContent: "center",
                        marginTop: "8px",
                        backgroundColor: value.inProgress
                          ? "#f1cff8"
                          : "#eaeaea",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          padding: "8px",
                        }}
                      >
                        <div style={{ flexGrow: 1 }}>
                          <IconButton
                            onClick={() =>
                              handleToggleFavorite(
                                value.idAssessment,
                                value.favorite
                              )
                            }
                          >
                            {favorite.includes(value.idAssessment) ||
                            value.favorite === 1 ? (
                              <FavoriteIcon style={{ color: "red" }} />
                            ) : (
                              <FavoriteBorderIcon />
                            )}
                          </IconButton>
                        </div>
                        {alignment === "archive" ? (
                          <Tooltip title={"เปิดชุดคำถาม"}>
                            <IconButton
                              onClick={() => handleUnArchive(value)}
                              sx={{ color: "black" }}
                            >
                              <UnarchiveOutlinedIcon />
                            </IconButton>
                          </Tooltip>
                        ) : alignment === "all" ? (
                          <Tooltip title={"เก็บชุดคำถาม"}>
                            <IconButton
                              onClick={() => handleOpenDelete(value)}
                              sx={{ color: "black" }}
                            >
                              <ArchiveOutlinedIcon />
                            </IconButton>
                          </Tooltip>
                        ) : null}
                        {alignment === "all" && (
                          <Tooltip title="สำเนาชุดคำถาม">
                            <IconButton onClick={() => handleOpenModal(value)}>
                              <ContentCopyIcon sx={{ color: "black" }} />
                            </IconButton>
                          </Tooltip>
                        )}
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          padding: "0px 16px 16px 16px",
                          gap: "4px",
                        }}
                      >
                        <Typography
                          variant="h6"
                          component="div"
                          fontWeight="bold"
                          sx={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {value.assessmentName}
                        </Typography>
                        <Box display="flex" alignItems="center" gap={1}>
                          <HelpCenterIcon sx={{ width: 20, height: 20 }} />
                          <Typography variant="body2" style={{ lineHeight: 1 }}>
                            {`จำนวนคำถาม: ${value.questionList.length} ข้อ`}
                          </Typography>
                        </Box>
                        <Box display="flex" alignItems="center" gap={1}>
                          <CalendarMonthIcon sx={{ width: 20, height: 20 }} />
                          <Typography variant="body2">
                            {`วันที่สร้าง: ${dayjs(value.createDate).format(
                              "YYYY-MM-DD"
                            )}`}
                          </Typography>
                        </Box>
                        <Box
                          sx={{ display: "flex", justifyContent: "flex-end" }}
                        >
                          <Chip
                            sx={{
                              backgroundColor: "#ffffff",
                              color: "#000000",
                            }}
                            label={`ใช้งานไปแล้ว ${value.numberOfUse} ครั้ง`}
                          />
                        </Box>
                      </Box>
                    </Card>

                    <Divider
                      sx={{ width: "80%", marginTop: "15px", mx: "auto" }}
                    />

                    <Grid
                      container
                      sx={{
                        marginTop: "10px",
                        mx: "auto",
                        justifyContent: "center",
                        gap: "16px",
                      }}
                    >
                      <Grid>
                        <Chip
                          label="รายละเอียด"
                          variant="outlined"
                          sx={{
                            borderRadius: "5px",
                          }}
                          onClick={() =>
                            handleClickDescription(value.idAssessment)
                          }
                        />
                      </Grid>

                      <Grid>
                        <Chip
                          label="ใช้ชุดคำถาม"
                          variant="outlined"
                          sx={{
                            borderRadius: "5px",
                            backgroundColor: "#000000",
                            color: "white",
                            "&:hover": {
                              backgroundColor: "white",
                              color: "#000000",
                            },
                          }}
                          onClick={() => handleClickUse(value.idAssessment)}
                        />
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
              ))
          )}
        </Grid>
      )}

      {openDelete && (
        <DilaogDelete
          open={openDelete}
          handleClose={handleCloseDelete}
          value={selectedAssessment}
          handleDelete={handleDelete}
        />
      )}
      {openModal && (
        <CreateDuplicateAssessment
          open={openModal}
          handleClose={handleCloseModal}
          selectedAssessment={selectedAssessment}
          refreshAssessments={refreshAssessments}
        />
      )}
    </container>
  );
};

export default AssessmentList;
