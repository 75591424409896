import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Stack,
  Button,
  Chip,
  Card,
  CardContent,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import answerIcon from "./assets/answer.png";
import { useDispatch, useSelector } from "react-redux";
import { getQuestionDailyAnswerText } from "../../../../actions/moraleDaily";

const DailyMoraleQuestions = ({ idCompany, year, month }) => {
  const dispatch = useDispatch();
  const [answers, setAnswers] = useState([]);
  const [expandedPanels, setExpandedPanels] = useState({});
  const [allExpanded, setAllExpanded] = useState(false);
  const { result: moraleDailyQuestion } = useSelector(
    (state) => state.moraleDailyQuestion
  );

  useEffect(() => {
    if (idCompany && year && month) {
      dispatch(getQuestionDailyAnswerText({ idCompany, year, month }));
    }
  }, [idCompany, year, month]);

  useEffect(() => {
    if (moraleDailyQuestion) {
      const sortedAnswers = [...moraleDailyQuestion].sort((a, b) => {
        const aHasAnswer = a.employeesAnswer && a.employeesAnswer.length > 0;
        const bHasAnswer = b.employeesAnswer && b.employeesAnswer.length > 0;
        return bHasAnswer - aHasAnswer; // Sort answered first
      });
      setAnswers(sortedAnswers);
    }
  }, [moraleDailyQuestion]);

  const handleTogglePanel = (id) => {
    setExpandedPanels((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const handleToggleAll = () => {
    const newState = !allExpanded;
    setAllExpanded(newState);
    setExpandedPanels(
      newState
        ? answers.reduce((acc, item) => {
            acc[item.idMoraleDaily] = true;
            return acc;
          }, {})
        : {}
    );
  };

  const calculateTotalAverageScore = () => {
    const allAnswers = answers.flatMap((item) => item.employeesAnswer || []);
    if (allAnswers.length === 0) return 0;
    const totalScore = allAnswers.reduce(
      (sum, answer) =>
        sum +
        (answer.sentimentScore !== null && answer.sentimentScore !== undefined
          ? answer.sentimentScore
          : 0),
      0
    );
    return (totalScore / allAnswers.length).toFixed(2);
  };

  const totalAverageScore = calculateTotalAverageScore();

  if (!answers || answers.length === 0) {
    return <Typography sx={{ color: "white" }}>ไม่พบข้อมูล</Typography>;
  }

  return (
    <Grid container direction="column" sx={{ width: "100%" }}>
      {/* Header Section */}
      <div style={{ backgroundColor: "#ffffff",  height: "100%", borderRadius: "8px", marginBottom: 15, paddingLeft: "20px",   }} >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ marginBottom: 2 }}
        >
          <Typography variant="h5" sx={{ color: "#000", textAlign: "left" }}>
            คำตอบแบบข้อความ
          </Typography>
          <Button variant="contained" onClick={handleToggleAll}>
            {allExpanded ? "ซ่อน" : "ดูทั้งหมด"}
          </Button>
        </Stack>
        {/* Card for Total Average Score */}
        <Card sx={{ marginBottom: 2, backgroundColor: "#f5f5f5" }}>
          <CardContent>
            <Typography variant="h6" sx={{ textAlign: "center" }}>
              คะแนนเฉลี่ยทั้งหมด
            </Typography>
            <Typography
              variant="h4"
              sx={{
                textAlign: "center",
                color: "primary.main",
                fontWeight: "bold",
              }}
            >
              {totalAverageScore} %
            </Typography>
          </CardContent>
        </Card>

      {/* Questions and Answers */}
      {answers.map((item, index) => (
        <Accordion
          key={item.idMoraleDaily}
          expanded={!!expandedPanels[item.idMoraleDaily]}
          onChange={() => handleTogglePanel(item.idMoraleDaily)}
          sx={{
            borderRadius:
              index === 0
                ? "8px 8px 0 0"
                : index === answers.length - 1
                ? "0 0 8px 8px"
                : "0",
            "&:before": {
              display: "none",
            },
            margin: 0,
            boxShadow: "none",
            transition: "background-color 0.3s",
            "&:hover": {
              backgroundColor: "#F5F5F5",
              "&:before": {
                border: "none !important",
              },
            },
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel-${item.idMoraleDaily}-content`}
            id={`panel-${item.idMoraleDaily}-header`}
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ width: "100%" }}
            >
              <Typography>{item.question}</Typography>
              <Chip
                label={
                  item.employeesAnswer && item.employeesAnswer.length > 0
                    ? "มีการตอบกลับ"
                    : "ไม่มีการตอบกลับ"
                }
                color={
                  item.employeesAnswer && item.employeesAnswer.length > 0
                    ? "success"
                    : "default"
                }
              />
            </Stack>
          </AccordionSummary>
          <AccordionDetails>
            {item.employeesAnswer && item.employeesAnswer.length > 0 ? (
              <>
                <Typography>คำตอบ: </Typography>
                {item.employeesAnswer.map((emp, idx) => (
                  <Grid container key={idx} alignItems="center" sx={{ pb: 1 }}>
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <Chip
                        label={`${emp.firstname_TH} ${emp.lastname_TH}`}
                        color="default"
                      />
                      <Chip
                        label={`คะแนนความรู้สึก: ${
                          typeof emp.sentimentScore === "number"
                            ? Math.round(emp.sentimentScore)
                            : "N/A"
                        } %`}
                        color="primary"
                        variant="outlined"
                      />
                    </Stack>
                    <Typography variant="body2" sx={{ ml: 2 }}>
                      {emp.answer}
                    </Typography>
                  </Grid>
                ))}
              </>
            ) : (
              <Chip label="ไม่มีการตอบกลับ" color="default" />
            )}
          </AccordionDetails>
        </Accordion>
      ))}
      </div>
    </Grid>
  );
};

export default DailyMoraleQuestions;
