import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import { styled } from "@mui/material/styles";
import * as dayjs from "dayjs";

import {
  Divider,
  Grid,
  Typography,
  Card,
  TextField,
  Button,
  Input,
  InputAdornment,
  IconButton,
  Fab,
  ListItem,
  List,
  ListItemAvatar,
  Avatar,
  ListItemText,
  ListItemSecondaryAction,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  ListSubheader,
  Container,
  Alert,
  Snackbar,
  Box,
  Stack,
  Chip,
  Paper,
  sliderClasses,
} from "@mui/material";
import AvatarGroup from "@mui/material/AvatarGroup";
import Autocomplete from "@mui/material/Autocomplete";
import AddIcon from "@mui/icons-material/Add";
import CancelIcon from "@mui/icons-material/Cancel";
import RemoveIcon from "@mui/icons-material/Remove";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

import { Fragment } from "react";
import LooksOneIcon from "@mui/icons-material/LooksOne";
import LooksTwoIcon from "@mui/icons-material/LooksTwo";
import Looks3Icon from "@mui/icons-material/Looks3";
import SaveIcon from "@mui/icons-material/Save";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { toast } from "sonner";

import bgCrate from "../assets/bgCrate.svg";
import Me from "../assets/me.gif";
import { getAllEmployees } from "../../../../../actions/user";
import { addAppraisee } from "../../../../../actions/appraisee";
import { getAssessment } from "../../../../../actions/assessment";
import SelectAffiliateCompany from "../../../shared/general/selectAffiliateCompany";
// import { addOutsiderEmail} from '../../../../../services/appraisee.service'

const StyleDivider = styled(Divider)({
  margin: "10px 0",
});

const StyleListSubheader = styled(ListSubheader)(({ type }) => {
  const listMaster = {
    borderRadius: 6,
    textTransform: "uppercase",
    color: "#FFFFFF",
    height: 40,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingRight: 4,
  };

  switch (type) {
    case "boss":
      return { backgroundColor: "#ff3034", ...listMaster };
    case "peer":
      return { backgroundColor: "#ff8d00", ...listMaster };
    case "project":
      return { backgroundColor: "#ffc700", ...listMaster };
    case "subordinate":
      return { backgroundColor: "#00bdb1", ...listMaster };
    case "customer":
      return { backgroundColor: "#008dd1", ...listMaster };
    case "crossfunction":
      return { backgroundColor: "#7d55c2", ...listMaster };
    case "outsider":
      return { backgroundColor: "#820747", ...listMaster };
    case "oneself":
      return { backgroundColor: "#b46c6c", ...listMaster };

    default:
      return listMaster;
  }
});

const ItemQuestion = styled("div")({
  display: "flex",
  alignItems: "center",
  padding: 8,
});

const Section = styled("div")({
  marginTop: 16,
});

const WrapButtonAddQuestion = styled("div")({
  display: "flex",
  justifyContent: "flex-end",
});

const WrapCenterLeftRightSeletedPerson = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-evenly",
});

const WrapPerson = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
});

const WrapCalculation = styled("div")({
  display: "flex",
  justifyContent: "space-around",
  border: "1px solid #CDCDCD",
  borderRadius: 4,
  padding: 16,
});

const WrapSelectedPersonList = styled(List)({
  backgroundColor: "#FFFFFF",
  position: "relative",
  overflow: "auto",
  maxHeight: 540,
});

const StyleAccordionSummary = styled(AccordionSummary)({
  backgroundImage: `url(${bgCrate})`,
  "& .MuiAccordionSummary-content": {
    margin: "16px 0",
  },
});

const accordionSummaryIcon = {
  color: "#272727",
  position: "absolute",
  fontSize: 60,
  top: -15,
  left: -35,
};
const StyleLooksOneIcon = styled(LooksOneIcon)(accordionSummaryIcon);
const StyleLooksTwoIcon = styled(LooksTwoIcon)(accordionSummaryIcon);
const StyleLooks3Icon = styled(Looks3Icon)(accordionSummaryIcon);

const AccordionSummaryText = styled(Typography)({
  marginLeft: 40,
  color: "#FFFFFF",
});

const StyleAccordionDetails = styled(AccordionDetails)({
  width: "100%",
  padding: 16,
});

const ListSection = styled("li")({
  backgroundColor: "inherit",
});

const Ul = styled("ul")({
  backgroundColor: "inherit",
  padding: 0,
});

const BtnSave = styled("div")({
  display: "flex",
  justifyContent: "flex-end",
  marginTop: 16,
});

const StyledRoot = styled("div")({
  display: "flex",
  flexDirection: "column",
  padding: 0,
  marginTop: 40,
  minWidth: 400,
});

const AvatarNull = styled(Card)({
  backgroundColor: "transparent",
  color: "transparent",
  borderColor: "transparent",
});

const InputCalculation = styled(Input)({
  width: 75,
  marginBottom: 8,
  fontSize: 24,
  fontWeight: 500,
  "& .MuiInputBase-input": {
    padding: "3px 0",
    textAlign: "center",
  },
});

const StyleBtnMaster = styled(Fab)(({ group }) => {
  const btnMaster = {
    minWidth: 100,
    color: "#FFFFFF",
    height: 40,
    fontSize: 16,
  };

  switch (group) {
    case "boss":
      return {
        backgroundColor: "#ff3034",
        "&:hover": { backgroundColor: "#c8292c" },
        ...btnMaster,
      };
    case "peer":
      return {
        backgroundColor: "#ff8d00",
        "&:hover": { backgroundColor: "#d38e39" },
        ...btnMaster,
      };
    case "crossfunction":
      return {
        backgroundColor: "#7d55c2",
        "&:hover": { backgroundColor: "#483170" },
        ...btnMaster,
      };
    case "customer":
      return {
        backgroundColor: "#008dd1",
        "&:hover": { backgroundColor: "#025780" },
        ...btnMaster,
      };
    case "subordinate":
      return {
        backgroundColor: "#00bdb1",
        "&:hover": { backgroundColor: "#027c74" },
        ...btnMaster,
      };
    case "project":
      return {
        backgroundColor: "#ffc700",
        "&:hover": { backgroundColor: "#c59b02" },
        ...btnMaster,
      };
    case "outsider":
      return {
        backgroundColor: "#820747",
        "&:hover": { backgroundColor: "#311432" },
        ...btnMaster,
      };
    case "oneself":
      return {
        backgroundColor: "#b46c6c",
        "&:hover": { backgroundColor: "#544541" },
        ...btnMaster,
      };

    default:
      return btnMaster;
  }
});

const TextPercent = styled(InputAdornment)({
  "& .MuiTypography-colorTextSecondary": {
    color: "#000000DE",
  },
});

const StyledAvatar = styled(Avatar)({
  width: 48,
  height: 48,
  marginRight: 24,
});

const DivRoot = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

function summarySamplesizeCalculation(calculationAll) {
  var summary = 0;
  for (let calculation of Object.values(calculationAll)) {
    summary += calculation;
  }
  return summary;
}

function checkNull(obj) {
  for (var key in obj) {
    if (obj[key] !== null) return false;
  }
  return true;
}

const Calculation = ({
  group,
  sampleSizeCalculation,
  personList,
  handleClick,
  handleBlur,
  handleChangeCalculation,
  outSider,
}) => {
  return (
    <DivRoot>
      <div style={{ display: "flex" }}>
        <InputCalculation
          type="Number"
          value={sampleSizeCalculation.value}
          onChange={handleChangeCalculation}
          onBlur={() => handleBlur(group)}
          name={group}
          startAdornment={<TextPercent position="start">%</TextPercent>}
          disabled={
            sampleSizeCalculation.value === 0 &&
            sampleSizeCalculation.summary >= 100
          }
          inputProps={{
            step: 5,
            min: 0,
            max: 100,
            "aria-labelledby": "input-slider",
          }}
        />
      </div>

      <StyleBtnMaster
        variant="extended"
        aria-label="add"
        onClick={() => handleClick(group)}
        disabled={sampleSizeCalculation.value === 0}
        group={group}
      >
        {group}
      </StyleBtnMaster>

      {group !== "oneself" && (
        <div style={{ marginTop: 8 }}>
          <AvatarGroup max={4}>
            {personList ? (
              personList.map((value, index) => (
                <Avatar
                  key={index}
                  alt={value.username}
                  src={value.imageProfile}
                />
              ))
            ) : (
              <AvatarNull>
                <RemoveIcon />
              </AvatarNull>
            )}
            {outSider ? (
              outSider.map((email, index) => (
                <Avatar
                  key={index}
                  alt={email.username}
                />
              ))
            ) : (
              <AvatarNull>
                <RemoveIcon />
              </AvatarNull>
            )}
          </AvatarGroup>
        </div>
      )}
    </DivRoot>
  );
};
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[300],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
  },
}));

export default function EditAssessment360() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { state } = useLocation();

  const [assessment, setAssessment] = React.useState({
    appraisee: null,
    startDate: dayjs(),
    endDate: dayjs().add(1, "day"),
    sampleSize: {
      boss: 0,
      peer: 0,
      project: 0,
      subordinate: 0,
      customer: 0,
      crossfunction: 0,
      outsider: 0,
      oneself: 0,
    },
    selected: {
      boss: [],
      peer: [],
      project: [],
      subordinate: [],
      customer: [],
      crossfunction: [],
      oneself: null,
    },
    outsider: {
      boss: [],
      peer: [],
      project: [],
      subordinate: [],
      customer: [],
      crossfunction: [],
    },
  });
  const [selectGroup, setSelectGroup] = React.useState(null);
  const [openSelectAppraisee, setOpenSelectAppraisee] = React.useState(false);
  const [openSelectPerson, setOpenSelectPerson] = React.useState(false);
  const [selectedPersonList, setSelectedPersonList] = React.useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [displaySnackbar, setDisplaySnackbar] = useState("");
  const [openEmailDialog, setOpenEmailDialog] = useState(false);
  const [outsiderEmail, setOutsiderEmail] = useState("");
  const [showAppraiseeName, setShowAppraiseeName] = useState(false);
  const [emailError, setEmailError] = React.useState("");
  const [openDialogWarning, setOpenDialogWarning] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [companySelected, setCompanySelected] = React.useState(false);
  const handleOpenDialogWarning = () => setOpenDialogWarning(true);
  const handleCloseDialogWarning = () => setOpenDialogWarning(false);

  const [emails, setEmails] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [selectedValues, setSelectedValues] = useState([]);

  const handleAddEmail = () => {
    const trimmedEmail = inputValue.trim();

    // Validate email format
    if (!/\S+@\S+\.\S+/.test(trimmedEmail)) {
      setEmailError("กรุณากรอกอีเมลที่ถูกต้อง");
      return;
    }

    // Check if email already exists
    if (emails.includes(trimmedEmail)) {
      setEmailError("อีเมลนี้ถูกกรอกไปแล้ว");
      return;
    }

    if (trimmedEmail) {
      setEmails((prevEmails) => {
        const updatedEmails = [...prevEmails, trimmedEmail];
        console.log(updatedEmails);

        setOutsiderEmail(updatedEmails);
        setSelectedPersonList(updatedEmails);
        return updatedEmails;
      });

      setInputValue("");
      setEmailError("");
    }
  };

  // const handleAddEmail = () => {
  //   const trimmedValue = inputValue.trim();
  //   console.log("email : ", trimmedValue);
  //   if (trimmedValue) {
  //     if (selectedValues.includes(trimmedValue)) {
  //       setEmailError("อีเมลนี้มีอยู่แล้ว");
  //     } else if (/\S+@\S+\.\S+/.test(trimmedValue)) {
  //       setSelectedValues([...selectedValues, trimmedValue]);
  //       handleChangeSelectedAppraisee(null, [...selectedValues, trimmedValue]);

  //       setEmailError("");
  //     } else {
  //       setEmailError("กรุณากรอกอีเมลที่ถูกต้อง");
  //     }
  //   }
  // };

  const handleKeyDown = (event) => {
    if (event.key === " " || event.key === "Tab") {
      event.preventDefault();
      handleAddEmail();
    }
  };

  const handleDeleteEmail = (emailToDelete) => {
    setEmails(emails.filter((email) => email !== emailToDelete));
  };

  const dispatch = useDispatch();
  const { result: assessmentItem } = useSelector(
    (state) => state.assessmentItem
  );
  const { result: persons } = useSelector((state) => state.users);

  useEffect(() => {
    dispatch(getAssessment(parseInt(id)));
    dispatch(getAllEmployees());
  }, []);

  const [checked, setChecked] = React.useState([]);

  const handleOpenSnackbar = (value) => {
    setDisplaySnackbar(value);
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleOpenSelectAppraisee = () => {
    setOpenSelectAppraisee(true);
  };

  const handleCloseSelectAppraisee = () => {
    setOpenSelectAppraisee(false);
  };

  const handleChangeCalculation = (event) => {
    let newValue = { ...assessment };
    newValue.sampleSize[event.target.name] =
      event.target.value === "" ? 0 : Number(event.target.value);
    let summary = summarySamplesizeCalculation(newValue.sampleSize);
    if (summary > 100) {
      newValue.sampleSize[event.target.name] = 0;
    }

    setAssessment(newValue);
  };

  const handleBlur = (name) => {
    let newValue = { ...assessment };
    if (newValue.sampleSize[name] < 0) {
      newValue.sampleSize[name] = 0;
    } else if (newValue.sampleSize[name] > 100) {
      newValue.sampleSize[name] = 100;
    }

    setAssessment(newValue);
  };

  const handleChangeDateSample = (name, date) => {
    const newValue = { ...assessment, [name]: date };
    setAssessment(newValue);
  };

  const handleChangeSelectedAppraisee = (event, value) => {
    setSelectedPersonList([value.idEmployees]);
    setAssessment((prev) => ({
      ...prev,
      appraisee: value,
      selected: {
        boss: [],
        peer: [],
        project: [],
        subordinate: [],
        customer: [],
        crossfunction: [],
        oneself: null,
      },
    }));
    handleCloseSelectAppraisee();
  };

  useEffect(() => {
    if (
      checked.some(
        (selectedOption) => selectedOption.idEmployees === "outsider"
      )
    ) {
      setEmailError("");
    }
  }, [checked, selectGroup]);

  const handleSaveChangeSelectedPerson = () => {
    let newValue = { ...assessment };

    if (!newValue.selected[selectGroup]) {
      newValue.selected[selectGroup] = [];
    }

    if (selectGroup === "oneself") {
      const appraiseeData = {
        idEmployees: assessment.appraisee?.idEmployees || "",
        firstname_TH: assessment.appraisee?.firstname_TH || "",
        lastname_TH: assessment.appraisee?.lastname_TH || "",
        imageProfile: assessment.appraisee?.imageProfile || "",
      };

      const isDuplicate = newValue.selected[selectGroup].some(
        (existingItem) => existingItem.idEmployees === appraiseeData.idEmployees
      );

      if (!isDuplicate) {
        newValue.selected[selectGroup].push(appraiseeData);
      }
    } else {
      checked.forEach((item) => {
        if (item.idEmployees === "outsider" && emails.length > 0) {
          emails.forEach((outsiderEmail) => {
            const isDuplicate = newValue.selected[selectGroup].some(
              (existingItem) =>
                existingItem.idEmployees === "outsider" &&
                existingItem.email === outsiderEmail
            );

            if (!isDuplicate) {
              newValue.selected[selectGroup].push({
                ...item,
                idEmployees: null,
                email: outsiderEmail,
              });
            }
          });
        } else {
          const isDuplicate = newValue.selected[selectGroup].some(
            (existingItem) => existingItem.idEmployees === item.idEmployees
          );

          if (!isDuplicate) {
            newValue.selected[selectGroup].push({
              ...item,
              email: null,
            });
          }
        }
      });
    }

    setSelectedPersonList([
      ...new Set([
        ...selectedPersonList,
        ...checked.map((item) => item.idEmployees),
      ]),
    ]);

    setAssessment(newValue);
    handleCloseSelectPerson();
  };

  const handleClickOpenSelectPersonOneself = () => {
    setShowAppraiseeName((prevState) => !prevState);
  };

  const handleClickOpenSelectPerson = (group) => {
    setSelectGroup(group);
    setChecked([]);

    // if (group === "oneself") {
    //   setChecked([assessment.appraisee]);
    // }

    if (group === "oneself") {
      handleOpenSelectPerson();
    }

    if (group === "outsider") {
      setOpenEmailDialog(true);
    } else {
      handleOpenSelectPerson();
    }
  };

  const handleCloseSelectPerson = () => {
    setOpenSelectPerson(false);
  };

  const handleOpenSelectPerson = () => {
    setOpenSelectPerson(true);
  };

  const handleClickDeleteSelectedPerson = (index, group) => {
    let newValue = { ...assessment };

    const person = newValue.selected[group][index];
    newValue.selected[group].splice(index, 1);
    if (newValue.selected[group].length === 0) {
      newValue.selected[group] = null;
    }
    setAssessment(newValue);

    const filtered = selectedPersonList.filter(
      (item) => item !== person.idEmployees
    );
    setSelectedPersonList(filtered);
  };

  const handleSaveAssessment = async () => {
    setIsSubmitting(true);
    const calculationAll = assessment.sampleSize;
    const selectedAll = assessment.selected;
    const datePass = assessment.startDate < assessment.endDate;
    let appraiserPass = true;
    for (let property in calculationAll) {
      if (calculationAll[property] > 0 && selectedAll[property] === null) {
        appraiserPass = false;
        break;
      }
    }
    const sumaryPass =
      summarySamplesizeCalculation(assessment.sampleSize) === 100;
    const appraiseePass = assessment.appraisee !== null;

    const cleanSelected = {
      boss: assessment.selected.boss?.map((item) => ({
        idEmployees: item.idEmployees,
        email: item.email,
      })),
      peer: assessment.selected.peer?.map((item) => ({
        idEmployees: item.idEmployees,
        email: item.email,
      })),
      project: assessment.selected.project?.map((item) => ({
        idEmployees: item.idEmployees,
        email: item.email,
      })),
      subordinate: assessment.selected.subordinate?.map((item) => ({
        idEmployees: item.idEmployees,
        email: item.email,
      })),
      customer: assessment.selected.customer?.map((item) => ({
        idEmployees: item.idEmployees,
        email: item.email,
      })),
      crossfunction: assessment.selected.crossfunction?.map((item) => ({
        idEmployees: item.idEmployees,
        email: item.email,
      })),
    }; 

    if (appraiseePass && datePass && appraiserPass && sumaryPass) {
      const res = await dispatch(
        addAppraisee({
          ...assessment,
          appraisee: assessment.appraisee.idEmployees,
          selected: cleanSelected,
          idAssessment: parseInt(id),
        })
      );
      if (res && res.status === 201) {
        setIsSubmitting(false);
        toast.success("เพิ่มสำเร็จ");
        navigate("/admin/assessment360");
      } else {
        toast.error("เกิดข้อผิดพลาด");
      }
    } else {
      if (!appraiseePass) {
        handleOpenSnackbar("โปรดเลือกผู้ถูกประเมิน");
      } else if (!datePass) {
        handleOpenSnackbar("โปรดเลือกวันที่ให้ถูกต้อง");
      } else if (!sumaryPass) {
        handleOpenSnackbar("โปรดเพิ่มอัตราส่วนให้ครบ 100%");
      } else if (!appraiserPass) {
        handleOpenSnackbar("โปรดเลือกผู้ประเมินให้ครบ");
      }
    }
    setIsSubmitting(false);
  };

  const renderSelectedPersonList = () => {
    const output = Object.keys(assessment.outsider)
      .filter(
        (key) =>
          (assessment.selected[key] !== null &&
            assessment.selected[key].length > 0) ||
          (assessment.outsider[key] !== null &&
            assessment.outsider[key].length > 0)
      )
      .map((key) => (
        <ListSection key={`section-${key}`}>
          {assessment.selected[key].length > 0 ? (
            <Ul>
              <StyleListSubheader type={key}>
                <Typography color={"#FFFFFF"}>
                  {`${key} (${assessment.selected[key].length})`}
                </Typography>
              </StyleListSubheader>
              {assessment.selected[key].map((item, indexItem) => (
                <ListItem key={`item-${key}-${indexItem}`}>
                  <ListItemAvatar>
                    <Avatar
                      alt={`${item.username}`}
                      src={
                        item.idEmployees === "outsider"
                          ? null
                          : item.imageProfile
                      }
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      (item.idEmployees === null ||
                        item.idEmployees === "outsider") &&
                      item.email
                        ? item.email
                        : `${item.firstname_TH} ${item.lastname_TH}`
                    }
                  />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      style={{ color: "#a2a2a2" }}
                      onClick={() =>
                        handleClickDeleteSelectedPerson(indexItem, key)
                      }
                      size="large"
                    >
                      <CancelIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </Ul>
          ) : null}
          {assessment.outsider[key].length > 0 ? (
            <Ul>
              <StyleListSubheader type={key}>
                <Typography color={"#FFFFFF"}>
                  {`${key} outsider (${assessment.outsider[key].length})`}
                </Typography>
              </StyleListSubheader>
              {assessment.outsider[key].map((item, indexItem) => (
                <ListItem key={`item-${key}-${indexItem}`}>
                  <ListItemAvatar>
                    <Avatar>{item[0]}</Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={item} />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      style={{ color: "#a2a2a2" }}
                      onClick={() => {
                        const filtered = assessment.outsider[key].filter(
                          (email) => email !== item
                        );
                        setAssessment((prev) => ({
                          ...prev,
                          outsider: {
                            ...prev.outsider,
                            [selectGroup]: filtered,
                          },
                        }));
                      }}
                      size="large"
                    >
                      <CancelIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </Ul>
          ) : null}
        </ListSection>
      ));
    return output;
  };

  const renderOutsiderPersonList = () => {
    const output = Object.keys(assessment.outsider)
      .filter(
        (key) =>
          assessment.outsider[key] !== null &&
          assessment.outsider[key].length > 0
      )
      .map((key, index) => (
        <ListSection key={`section-${key}`}>
          <Ul>
            <StyleListSubheader type={key}>
              <Typography color={"#FFFFFF"}>
                {`${key} outsider (${assessment.outsider[key].length})`}
              </Typography>
            </StyleListSubheader>

            {assessment.outsider[key].map((item, indexItem) => (
              <ListItem key={`item-${key}-${indexItem}`}>
                <ListItemAvatar>
                  <Avatar>{item[0]}</Avatar>
                </ListItemAvatar>
                <ListItemText primary={item} />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    style={{ color: "#a2a2a2" }}
                    onClick={() => {
                      const filtered = assessment.outsider[key].filter(email => email !== item)
                      setAssessment((prev) => ({
                        ...prev,
                        outsider: { ...prev.outsider, [selectGroup]: filtered },
                      }))
                    }}
                    size="large"
                  >
                    <CancelIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </Ul>
        </ListSection>
      ));
    return output;
  };

  return (
    <StyledRoot className={"page"}>
      <Typography variant="h4" align="center" marginBottom={4}>
        แบบประเมิน 360 องศา
      </Typography>
      <Container maxWidth={"lg"}>
        {assessmentItem && (
          <Typography variant="h6">
            {`ชื่อชุดคำถาม: ${assessmentItem[0].assessmentName}`}
          </Typography>
        )}
        <StyleDivider />
        <>
          <Accordion style={{ marginBottom: 8 }}>
            <StyleAccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <div style={{ position: "relative" }}>
                <StyleLooksOneIcon />
                <AccordionSummaryText variant="h6">
                  ผู้ถูกประเมิน
                </AccordionSummaryText>
              </div>
            </StyleAccordionSummary>
            <StyleAccordionDetails>
              <div style={{ width: "100%" }}>
                <div style={{ marginBottom: 16 }}>
                  {assessment.appraisee && (
                    <div>
                      <ItemQuestion>
                        <Typography variant="h6">
                          {`${assessment.appraisee.firstname_TH} ${assessment.appraisee.lastname_TH}`}
                        </Typography>
                      </ItemQuestion>
                    </div>
                  )}
                </div>

                <WrapButtonAddQuestion>
                  <Button
                    variant="outlined"
                    size="medium"
                    color="primary"
                    startIcon={<AddIcon />}
                    style={{ backgroundColor: "#0161cd", color: "#FFFFFF" }}
                    onClick={handleOpenSelectAppraisee}
                  >
                    ผู้ถูกประเมิน
                  </Button>
                </WrapButtonAddQuestion>
              </div>
            </StyleAccordionDetails>
          </Accordion>

          <Accordion style={{ marginBottom: 8 }}>
            <StyleAccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <div style={{ position: "relative" }}>
                <StyleLooksTwoIcon />
                <AccordionSummaryText variant="h6">
                  ระยะเวลาของคำถาม
                </AccordionSummaryText>
              </div>
            </StyleAccordionSummary>
            <StyleAccordionDetails>
              <Stack direction="row" spacing={2}>
                <DatePicker
                  label="Start Date"
                  value={assessment.startDate}
                  // onChange={(e, ve) handleChangeDateSample("startDate")}
                  minDate={dayjs()}
                  onChange={(newValue) =>
                    handleChangeDateSample("startDate", newValue)
                  }
                  // slotProps={{
                  //   textField: {
                  //     error: !!errors.openRegisterDate,
                  //     helperText: errors.openRegisterDate?.message,
                  //   },
                  // }}
                />
                <DatePicker
                  label="End Date"
                  value={assessment.endDate}
                  // onChange={handleChangeDateSample("endDate")}
                  onChange={(newValue) =>
                    handleChangeDateSample("endDate", newValue)
                  }
                  minDate={dayjs(assessment.startDate).add(1, "day")}
                  // slotProps={{
                  //   textField: {
                  //     error: assessment.startDate >= assessment.endDate,
                  //     helperText: assessment.startDate >= assessment.endDate && "End date has to be after start date",
                  //   },
                  // }}
                />
              </Stack>
            </StyleAccordionDetails>
          </Accordion>

          <Accordion style={{ marginBottom: 8 }}>
            <StyleAccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <div style={{ position: "relative" }}>
                <StyleLooks3Icon />
                <AccordionSummaryText variant="h6">
                  ผู้ประเมิน
                </AccordionSummaryText>
              </div>
            </StyleAccordionSummary>
            <StyleAccordionDetails>
              <Section style={{ width: "100%", overflow: "auto" }}>
                <Grid container spacing={2} style={{}}>
                  <Grid
                    item
                    xs={12}
                    sm={checkNull(assessment.selected) ? 12 : 8}
                  >
                    <WrapCalculation>
                      <WrapCenterLeftRightSeletedPerson>
                        <Calculation
                          group="crossfunction"
                          sampleSizeCalculation={{
                            value: assessment.sampleSize["crossfunction"],
                            summary: summarySamplesizeCalculation(
                              assessment.sampleSize
                            ),
                          }}
                          personList={assessment.selected["crossfunction"]}
                          handleClick={handleClickOpenSelectPerson}
                          handleBlur={handleBlur}
                          handleChangeCalculation={handleChangeCalculation}
                          outSider={assessment.outsider["crossfunction"]}
                        />

                        <Calculation
                          group="customer"
                          sampleSizeCalculation={{
                            value: assessment.sampleSize["customer"],
                            summary: summarySamplesizeCalculation(
                              assessment.sampleSize
                            ),
                          }}
                          personList={assessment.selected["customer"]}
                          handleClick={handleClickOpenSelectPerson}
                          handleBlur={handleBlur}
                          handleChangeCalculation={handleChangeCalculation}
                          outSider={assessment.outsider["customer"]}
                        />
                      </WrapCenterLeftRightSeletedPerson>
                      <div>
                        <Calculation
                          group="boss"
                          sampleSizeCalculation={{
                            value: assessment.sampleSize["boss"],
                            summary: summarySamplesizeCalculation(
                              assessment.sampleSize
                            ),
                          }}
                          personList={assessment.selected["boss"]}
                          handleClick={handleClickOpenSelectPerson}
                          handleBlur={handleBlur}
                          handleChangeCalculation={handleChangeCalculation}
                          outSider={assessment.outsider["boss"]}
                        />
                        <WrapPerson>
                          <div style={{ width: "100%", margin: "8px 0" }}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <Typography
                                align="center"
                                color="primary"
                                style={{ fontWeight: 500 }}
                              >
                                {summarySamplesizeCalculation(
                                  assessment.sampleSize
                                )}
                              </Typography>
                              <Typography align="center" color="textSecondary">
                                {"/100%"}
                              </Typography>
                            </div>
                            <BorderLinearProgress
                              variant="determinate"
                              value={summarySamplesizeCalculation(
                                assessment.sampleSize
                              )}
                            />
                          </div>

                          <img width={150} src={Me} alt="me" />
                          <Calculation
                            group="oneself"
                            sampleSizeCalculation={{
                              value: assessment.sampleSize["oneself"],
                              summary: summarySamplesizeCalculation(
                                assessment.sampleSize
                              ),
                            }}
                            personList={assessment.selected["oneself"]}
                            handleClick={
                              assessment.appraisee
                                ? handleClickOpenSelectPerson
                                : handleOpenDialogWarning
                            }
                            //  handleClick={handleClickOpenSelectPerson}
                            handleBlur={handleBlur}
                            handleChangeCalculation={handleChangeCalculation}
                          />

                          {/* <Calculation
                            group="oneself"
                            sampleSizeCalculation={{
                              value: assessment.sampleSize["oneself"],
                              summary: summarySamplesizeCalculation(
                                assessment.sampleSize
                              ),
                            }}
                            personList={assessment.selected["oneself"]}
                            handleClick={handleClickOpenSelectPerson}
                            handleBlur={handleBlur}
                            handleChangeCalculation={handleChangeCalculation}
                          /> */}

                          <Dialog
                            open={openDialogWarning}
                            onClose={handleCloseDialogWarning}
                            maxWidth={false}
                            sx={{
                              "& .MuiDialog-paper": {
                                width: "25%",
                                borderRadius: "15px",
                              },
                              padding: "30px",
                              borderRadius: "5px",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                            padding="30px"
                          >
                            <Paper
                              sx={{
                                display: "flex",
                                backgroundColor: "#EC5B6A",
                                alignItems: "center",
                                justifyContent: "center",
                                padding: "10px",
                              }}
                            >
                              <WarningAmberIcon
                                sx={{
                                  width: "70px",
                                  height: "70px",

                                  color: "white",
                                }}
                                color="warning"
                              />
                            </Paper>
                            <DialogContent
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <Typography
                                variant="h6"
                                sx={{ fontWeight: "bold", fontSize: "30px" }}
                              >
                                คำเตือน!
                              </Typography>
                              <DialogContentText>
                                โปรดเลือกผู้ถูกประเมิน
                              </DialogContentText>
                              <Button
                                onClick={handleCloseDialogWarning}
                                sx={{
                                  color: "white",
                                  height: "24px",
                                  width: "24px",
                                  backgroundColor: "#EC5B6A",
                                  borderRadius: "20px",
                                  marginTop: 3,
                                  fontSize: "12px",
                                  "&:hover": {
                                    backgroundColor: "#EC5B6A",
                                  },
                                }}
                                variant="contained"
                              >
                                CLOSE
                              </Button>
                            </DialogContent>

                            {/* <Button
                              onClick={handleCloseDialogWarning}
                              sx={{
                                color: "#EC5B6A",
                                alignItems: "flex-end",
                              }}
                            ></Button> */}
                          </Dialog>
                        </WrapPerson>
                        <div>
                          <Calculation
                            group="subordinate"
                            sampleSizeCalculation={{
                              value: assessment.sampleSize["subordinate"],
                              summary: summarySamplesizeCalculation(
                                assessment.sampleSize
                              ),
                            }}
                            personList={assessment.selected["subordinate"]}
                            handleClick={handleClickOpenSelectPerson}
                            handleBlur={handleBlur}
                            handleChangeCalculation={handleChangeCalculation}
                            outSider={assessment.outsider["subordinate"]}
                          />
                        </div>
                      </div>
                      <WrapCenterLeftRightSeletedPerson>
                        <Calculation
                          group="peer"
                          sampleSizeCalculation={{
                            value: assessment.sampleSize["peer"],
                            summary: summarySamplesizeCalculation(
                              assessment.sampleSize
                            ),
                          }}
                          personList={assessment.selected["peer"]}
                          handleClick={handleClickOpenSelectPerson}
                          handleBlur={handleBlur}
                          handleChangeCalculation={handleChangeCalculation}
                          outSider={assessment.outsider["peer"]}
                        />
                        <Calculation
                          group="project"
                          sampleSizeCalculation={{
                            value: assessment.sampleSize["project"],
                            summary: summarySamplesizeCalculation(
                              assessment.sampleSize
                            ),
                          }}
                          personList={assessment.selected["project"]}
                          handleClick={handleClickOpenSelectPerson}
                          handleBlur={handleBlur}
                          handleChangeCalculation={handleChangeCalculation}
                          outSider={assessment.outsider["project"]}
                        />
                      </WrapCenterLeftRightSeletedPerson>
                    </WrapCalculation>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <WrapSelectedPersonList subheader={<li />}>
                      {renderSelectedPersonList()}
                      {/* {renderOutsiderPersonList()} */}
                    </WrapSelectedPersonList>
                  </Grid>
                </Grid>
              </Section>
            </StyleAccordionDetails>
          </Accordion>
          <BtnSave>
            <Button
              variant="contained"
              color="primary"
              size="large"
              startIcon={<SaveIcon />}
              onClick={handleSaveAssessment}
              disabled={isSubmitting}
            >
              ยืนยัน
            </Button>
          </BtnSave>
        </>
      </Container>
      {openSelectPerson && assessment.appraisee && (
        <Dialog open={openSelectPerson} onClose={handleCloseSelectPerson}>
          <DialogTitle>{"เลือกผู้ประเมิน"}</DialogTitle>
          <DialogContent>
            <div>
              <Typography
                variant="h6"
                style={{ textTransform: "uppercase", fontWeight: 400 }}
                gutterBottom
              >
                {selectGroup}
              </Typography>
              {selectGroup === "oneself" && persons && (
                <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  sx={{ ml: "0px", mt: "10px" }}
                >
                  <Grid>
                    <ListItemAvatar>
                      <Avatar
                        alt={`${assessment.appraisee.username}`}
                        src={assessment.appraisee.imageProfile}
                      />
                    </ListItemAvatar>
                  </Grid>
                  <Grid>
                    <Chip
                      sx={{ minWidth: 400 }}
                      label={`${assessment.appraisee.firstname_TH} ${assessment.appraisee.lastname_TH}`}
                    />
                  </Grid>
                </Grid>
              )}

              {selectGroup !== "oneself" && persons && (
                <Stack gap={1}>
                  <SelectAffiliateCompany
                    value={companySelected}
                    handleChange={setCompanySelected}
                  />
                  <Autocomplete
                    multiple
                    disabled={!companySelected}
                    options={persons.filter(
                      (item) =>
                        !item.isTerminate &&
                        item.idCompany === companySelected?.idCompany &&
                        item.idEmployees !== assessment.appraisee.idEmployees
                    )}
                    value={assessment.selected[selectGroup]}
                    onChange={(event, newValue) => {
                      setAssessment((prev) => ({
                        ...prev,
                        selected: { ...prev.selected, [selectGroup]: newValue },
                      }))
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option.idEmployees === value.idEmployees
                    }
                    disableCloseOnSelect
                    getOptionLabel={(option) =>
                      `${option.firstname_TH} ${option.lastname_TH || ""}`
                    }
                    sx={{ minWidth: 400 }}
                    renderInput={(params) => <TextField {...params} />}
                    renderOption={(props, option) => (
                      <li {...props} key={option.idEmployees}>
                        <Avatar
                          alt={
                            option.idEmployees === "outsider"
                              ? "Outsider"
                              : `${option.firstname_TH} ${option.lastname_TH}`
                          }
                          src={
                            option.idEmployees === "outsider"
                              ? null
                              : option.imageProfile
                          }
                        />
                        <Box ml={1}>
                          {option.firstname_TH} {option.lastname_TH}
                        </Box>
                      </li>
                    )}
                  />
                  <Autocomplete
                    multiple
                    value={assessment.outsider[selectGroup]}
                    options={[]}
                    onChange={(_, newValue) => {
                      const test = newValue.some(
                        (email) =>
                          !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(
                            email
                          )
                      );
                      if (test) {
                        return;
                      }
                      setAssessment((prev) => ({
                        ...prev,
                        outsider: { ...prev.outsider, [selectGroup]: newValue },
                      }))
                    }}
                    freeSolo
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => {
                        const { key, ...tagProps } = getTagProps({ index });
                        return (
                          <Chip
                            variant="outlined"
                            label={option}
                            key={key}
                            {...tagProps}
                          />
                        );
                      })
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="อีเมลของบุคคลภายนอก"
                      />
                    )}
                  />
                </Stack>
              )}
            </div>
          </DialogContent>

          <DialogActions>
            <Button onClick={handleCloseSelectPerson} color="primary">
              ยกเลิก
            </Button>
            <Button onClick={handleSaveChangeSelectedPerson} color="primary">
              บันทึก
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {persons && (
        <Dialog
          open={openSelectAppraisee}
          onClose={handleCloseSelectAppraisee}
          fullWidth
        >
          <DialogTitle>{"เลือกผู้ถูกประเมิน"}</DialogTitle>
          <DialogContent>
            <Stack gap={1} marginTop={1}>
              <SelectAffiliateCompany
                value={companySelected}
                handleChange={setCompanySelected}
              />
              <Autocomplete
                disabled={!companySelected}
                options={persons.filter(
                  (item) =>
                    !item.isTerminate &&
                    item.idCompany === companySelected?.idCompany
                )}
                value={assessment.appraisee}
                onChange={(event, newValue) => {
                  handleChangeSelectedAppraisee(event, newValue);
                }}
                isOptionEqualToValue={(option, value) =>
                  option.idEmployees === value.idEmployees
                }
                getOptionLabel={(option) =>
                  `${option.firstname_TH} ${option.lastname_TH}`
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="ค้นหาพนักงาน"
                    variant="outlined"
                  />
                )}
                renderOption={(props, option) => (
                  <li {...props} key={option.idEmployees}>
                    <Avatar
                      alt={`${option.firstname_TH} ${option.lastname_TH}`}
                      src={option.imageProfile}
                    />
                    {/* 
                    <ListItemAvatar>
                      <Avatar src={item.imageURL} />
                    </ListItemAvatar> */}
                    <Box ml={2}>
                      {option.firstname_TH} {option.lastname_TH}
                    </Box>
                  </li>
                )}
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseSelectAppraisee} color="primary">
              {" "}
              close{" "}
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="error"
          sx={{ width: "100%" }}
        >
          {displaySnackbar}
        </Alert>
      </Snackbar>
    </StyledRoot>
  );
}
