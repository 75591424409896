export const answerQuestion5Scale = [
  {
    key: "verybad",
    label: "ปรับปรุง",
    value: "0",
  },
  {
    key: "bad",
    label: "พอใช้",
    value: "25",
  },
  {
    key: "littlehappy",
    label: "ปานกลาง",
    value: "50",
  },
  {
    key: "happy",
    label: "ดี",
    value: "75",
  },
  {
    key: "veryhappy",
    label: "ดีมาก",
    value: "100",
  },
];

export const answerQuestion6Scale = [
  {
    key: "verybad",
    label: "ปรับปรุง",
    value: "0",
  },
  {
    key: "bad",
    label: "พอใช้",
    value: "20",
  },
  {
    key: "littlebad",
    label: "ปานกลาง",
    value: "40",
  },
  {
    key: "littlehappy",
    label: "ดี",
    value: "60",
  },
  {
    key: "happy",
    label: "ดีมาก",
    value: "80",
  },
  {
    key: "veryhappy",
    label: "ดีเยี่ยม",
    value: "100",
  },
];