import { httpClient } from "./httpClient";

const getFeedback = (query) => {
  return httpClient.get("feedback", { params: query });
};

const addFeedback = (formData) => {
  return httpClient.post("feedback", formData);
};

export default {
  getFeedback,
  addFeedback,
};
