import React from "react";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Stack,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import { HeartCoin } from "../../shared/typeCoin";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  addHeartTransfer,
  getHeartTransfer,
} from "../../../../actions/heartTransfer";
import { toast } from "sonner";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfile } from "../../../../actions/user";

const btnSendHeartScore = {
  minWidth: 130,
  cursor: "pointer",
  display: "flex",
  justifyContent: "space-around",
  padding: 8,
  borderRadius: 45,
  backgroundColor: "#7fd3ff",
};

const BtnSendHeartScore = styled(Paper, {
  shouldForwardProp: (prop) => prop !== "score",
})(({ score }) => {
  return score
    ? {
        ...btnSendHeartScore,
        opacity: 0.4,
      }
    : {
        ...btnSendHeartScore,
      };
});

const DialogSentHeart = ({ open, handleClose }) => {
  const dispatch = useDispatch();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: employees } = useSelector((state) => state.users);

  const schema = yup.object({
    selectedEmployee: yup.object().nullable().required(),
    // score: yup.number(),
    detail: yup.string().max(100).nullable(),
  });

  const {
    control,
    watch,
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      selectedEmployee: null,
      score: null,
      detail: "",
      emails: [],
    },
    resolver: yupResolver(schema),
  });

  const handleSendHeart = async (values) => {
    if (values.score > userProfile.sendHeartBalance) {
      toast.error("หัวใจคงเหลือไม่เพียงพอส่ง");
    } else if (values.selectedEmployee === null || values.score === null) {
      toast.error("โปรดกรอกข้อมูลให้ครบ");
    } else {
      const res = await dispatch(
        addHeartTransfer({
          score: values.score,
          detail: values.detail,
          idReceiver: values.selectedEmployee.idEmployees,
          emails: values.emails,
        })
      );
      if (res && res.status === 200) {
        dispatch(getHeartTransfer());
        handleClose();
        toast.success("ส่งหัวใจสำเร็จ");
      } else {
        toast.error("เกิดข้อผิดพลาด");
      }
      dispatch(getUserProfile());
    }
  };

  const score = watch("score");

  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true}>
      <DialogTitle>{"ส่งหัวใจ"}</DialogTitle>
      <form onSubmit={handleSubmit(handleSendHeart)}>
        <DialogContent>
          <Stack gap={2}>
            {userProfile && (
              <Controller
                control={control}
                name="selectedEmployee"
                render={({ field, fieldState }) => (
                  <Autocomplete
                    {...field}
                    onChange={(event, value) => field.onChange(value)}
                    options={employees.filter(
                      (item) => item.idEmployees !== userProfile.idEmployees
                    )}
                    getOptionLabel={(option) =>
                      `${option.firstname_TH} ${option.lastname_TH}`
                    }
                    isOptionEqualToValue={(option, value) =>
                      option.idEmployees === value.idEmployees
                    }
                    renderOption={(props, option) => (
                      <Box component="li" {...props} key={option.idEmployees}>
                        {`${option.firstname_TH} ${option.lastname_TH}`}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        label="พนักงาน"
                        error={Boolean(fieldState.error)}
                        helperText={fieldState.error && fieldState.error.message}
                      />
                    )}
                  />
                )}
              />
            )}
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <BtnSendHeartScore
                score={score === 3}
                onClick={() => setValue("score", 3)}
              >
                <HeartCoin width={35} />
                <HeartCoin width={35} />
                <HeartCoin width={35} />
              </BtnSendHeartScore>
              <BtnSendHeartScore
                score={score === 2}
                onClick={() => setValue("score", 2)}
              >
                <HeartCoin width={35} />
                <HeartCoin width={35} />
              </BtnSendHeartScore>
              <BtnSendHeartScore
                score={score === 1}
                onClick={() => setValue("score", 1)}
              >
                <HeartCoin width={35} />
              </BtnSendHeartScore>
            </div>
            <Controller
              name="detail"
              control={control}
              render={({ field, filedState }) => (
                <div>
                  <TextField
                    {...field}
                    multiline
                    rows={2}
                    inputProps={{ maxLength: 100 }}
                    fullWidth
                    id="msg-to-friend"
                    label="ข้อความแทนคำขอบคุณ..."
                    variant="outlined"
                    sx={{ marginBottom: 1 }}
                  />
                  <Typography color="textSecondary">
                    {`(${watch("detail")?.length}/100)`}
                  </Typography>
                </div>
              )}
            />
            <Controller
              name="emails"
              control={control}
              render={({ field, filedState }) => (
                <Autocomplete
                  {...field}
                  multiple
                  freeSolo
                  options={[]}
                  onChange={(_, newValue) => {
                    const test = newValue.some(
                      (email) =>
                        !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(
                          email
                        )
                    );
                    if (test) {
                      return;
                    } else {
                      field.onChange(newValue)
                    }
                  }}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => {
                      const { key, ...tagProps } = getTagProps({ index });
                      return (
                        <Chip
                          variant="outlined"
                          label={option}
                          key={key}
                          {...tagProps}
                        />
                      );
                    })
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="CC Email"
                    />
                  )}
                />
              )}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            ยกเลิก
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={isSubmitting}
          >
            ส่งหัวใจ
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DialogSentHeart;
