import React, { useEffect } from "react";
import { styled } from '@mui/material/styles';
import {
  Avatar,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Typography,
  AccordionDetails,
  AccordionSummary,
  Accordion,
  TextField,
  Grid,
  Card,
  Select,
  CardContent,
  MenuItem,
  Checkbox,
  Slider,
  Divider,
  Chip,
  Container,
  Box,
  Tab,
  Tabs,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import NotificationIcon from "./assets/notification.svg";
import HeaderPage from "../../../shared/header/headerPage";
import { useDispatch, useSelector } from "react-redux";
import {
  addMoraledailyQuestion,
  getAllMoraleDailyQuestion,
  getAllMoraleDaily,
} from "../../../../../actions/moraleDaily";
import { getAllQuestionTopic } from "../../../../../actions/questionTopic";
import { getDepartment } from "../../../../../actions/department";
import { toast } from "sonner";
import dayjs from 'dayjs';
import isBetween from "dayjs/plugin/isBetween";
import { getBgCard, imgInsight } from "./utils";
import MoraleDailyActive from "./MoraleDailyActive";
import MoraleDailyInProgress from "./MoraleDailyInProgress";
import MoraleDailyHistory from "./MoraleDailyHistory";
import MoraleDailyArchive from "./MoraleDailyArchive";
dayjs.extend(isBetween);

const WrapHeader = styled('div')({
  display: "flex",
  justifyContent: "space-between",
  ["@media only screen and (max-width:600px)"]: {
    flexDirection: "column",
    "& .wrap-button-add": {
      marginTop: 8,
    },
  },
})

const StyledButton = styled(Button)({
  margin: 4,
  color: '#000000',
  backgroundColor: "#ffffff",
  ":hover": {
    backgroundColor: "#bdbdbd",
  }
})

const StyledCoinChoice = styled(Avatar)({
  width: 24,
  height: 24,
  fontSize: 14,
  backgroundColor: "#f5cb00",
  color: "#dc8100",
  marginLeft: 10,
  position: "absolute",
  right: 10,
})

const StyledChip = styled(Chip)(({ theme }) => ({
  margin: theme.spacing(0.5),
}))

const StyledDivider = styled(Divider)({
  margin: "8px 0px",
  width: "100%", 
})

const StyledRoot = styled('div')({
  display: "flex",
  flexDirection: "column",
  padding: 15,
  marginTop: 60,
  margin: "0px auto",
})

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Notification = () => {
  const [questionType, setQuestionType] = React.useState("choice");
  const [open, setOpen] = React.useState(false);
  const [idQuestionTopic, setIdQuestionTopic] = React.useState("");
  const [question, setQuestion] = React.useState("");
  const [checkAnonymous, setCheckAnonymous] = React.useState(false);
  const [reward, setReward] = React.useState(1);
  const [selectQuestionStatus, setSelectQuestionStatus] = React.useState(2);
  const [selectTopic, setSelectTopic] = React.useState(null);

  const handleQuestionStatus = (event, newQuestionStatus) => {
    if (newQuestionStatus !== null) {
      setSelectQuestionStatus(newQuestionStatus);
      // dispatch(getAllMoraleDaily())
    }
  };

  const handleChangeAnonymous = (event) => {
    setCheckAnonymous(event.target.checked);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    setQuestionType(event.target.value);
  };

  const handleChangeIdQuestionTopic = (event) => {
    setIdQuestionTopic(event.target.value);
  };

  const handleChangeQuestion = (event) => {
    setQuestion(event.target.value);
  };

  const handleScoreChange = (event, newValue) => {
    setReward(newValue);
  };

  const handleChangeTopic = (topic) => {
    setSelectTopic(topic);
  };

  const marks = [
    { value: 0, label: "0" },
    { value: 1, label: "1" },
    { value: 2, label: "2" },
    { value: 3, label: "3" },
    { value: 4, label: "4" },
    { value: 5, label: "5" },
    { value: 6, label: "6" },
    { value: 7, label: "7" },
    { value: 8, label: "8" },
    { value: 9, label: "9" },
    { value: 10, label: "10" },
  ];

  const dispatch = useDispatch()
  const { result: questionTopic } = useSelector((state) => state.questionTopic);

  useEffect(() => {
    dispatch(getAllMoraleDailyQuestion());
  }, []);
  
  useEffect(() => {
    dispatch(getAllQuestionTopic())
    dispatch(getDepartment());
  }, []);

  const handleSubmit = async () => {
    const formData = {
      questionType,
      idQuestionTopic,
      question,
      reward,
    }

    if (!idQuestionTopic) {
      toast.error("โปรดเลือกหัวข้อคำถาม")
    } else if (question.trim().length === 0) {
      toast.error("โปรดเพิ่มคำถาม")
    } else {
      const res = await dispatch(addMoraledailyQuestion(formData))
      if (res && res.status === 201) {
        toast.success("สำเร็จ")
        dispatch(getAllMoraleDailyQuestion())
        setIdQuestionTopic("")
        setQuestion("")
        handleClose()
      } else {
        toast.error("เกิดข้อผิดพลาด")
      }
    }
    dispatch(getAllMoraleDailyQuestion())
    dispatch(getAllMoraleDaily())
    
  }

  return (
    <StyledRoot className={'page'}>
      <WrapHeader>
        <HeaderPage textLabel={"Morale Daily"} icon={NotificationIcon} />
        <div className={`wrap-button-add`}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={handleClickOpen}
          >
            เพิ่มคำถาม
          </Button>
        </div>
      </WrapHeader>
      <StyledDivider />
      {questionTopic && (
        <div style={{ marginBottom: "24px" }}>
          <Typography gutterBottom variant="h6">
            หัวข้อคำถาม
          </Typography>
          <StyledChip
            clickable
            label={"ALL"}
            color={selectTopic === null ? "primary" : "default"}
            onClick={() => handleChangeTopic(null)}
          />
          {questionTopic.map((data, index) => (
            <StyledChip
              key={data.questionTopic}
              clickable
              label={data.questionTopic}
              color={selectTopic === data.idQuestionTopic ? "primary" : "default"}
              onClick={() => handleChangeTopic(data.idQuestionTopic)}
            />
          ))}
        </div>
      )}
      <Container>
        <div style={{ marginBottom: "16px" }}>
          <Typography gutterBottom variant="h6">
            สถานะคำถาม
          </Typography>
          <StyledDivider />
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={selectQuestionStatus} onChange={handleQuestionStatus} exclusive>
              <Tab label="กำลังดำเนินการ" value={0} {...a11yProps(0)} />
              <Tab label="ประวัติ" value={1} {...a11yProps(1)} />
              <Tab label="เปิดใช้งาน" value={2} {...a11yProps(2)}  />
              <Tab label="ที่เก็บไว้" value={3} {...a11yProps(3)} />
            </Tabs>
          </Box>
        </div>
   
        <CustomTabPanel value={selectQuestionStatus} index={0}>
          <MoraleDailyInProgress selectTopic={selectTopic} />
        </CustomTabPanel>
        <CustomTabPanel value={selectQuestionStatus} index={1}>
          <MoraleDailyHistory selectTopic={selectTopic} />
        </CustomTabPanel>
        <CustomTabPanel value={selectQuestionStatus} index={2}>
          <MoraleDailyActive selectTopic={selectTopic} />
        </CustomTabPanel>
        <CustomTabPanel value={selectQuestionStatus} index={3}>
          <MoraleDailyArchive />
        </CustomTabPanel>
      </Container>
      {open && (
        <Dialog
          open={open}
          fullWidth={true}
          maxWidth={"lg"}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          PaperProps={{
            style: { borderRadius: 16, padding: '24px', boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)' }
          }}
        >
          <DialogTitle id="alert-dialog-title">{"สร้างคำถามใหม่"}</DialogTitle>
          <DialogContent>
            <Grid container spacing={3} justifyContent="center">
              <Grid item xs={12} sm={6}>
                <Accordion expanded={true}>
                  <AccordionSummary
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>
                      1. ประเภทคำตอบ
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <FormControl component="fieldset">
                      <RadioGroup
                        row
                        aria-label="typeQues"
                        name="typeQues"
                        value={questionType}
                        onChange={handleChange}
                      >
                        <FormControlLabel
                          value="choice"
                          control={<Radio />}
                          label="ตัวเลือก"
                        />
                        <FormControlLabel
                          value="text"
                          control={<Radio />}
                          label="ข้อความ"
                        />
                      </RadioGroup>
                    </FormControl>
                  </AccordionDetails>
                </Accordion>
                <Accordion expanded={true}>
                  <AccordionSummary
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>
                      2. หัวข้อคำถาม
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <FormControl variant="outlined" style={{ width: "100%" }}>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={idQuestionTopic}
                        onChange={handleChangeIdQuestionTopic}
                      >
                        {questionTopic && questionTopic.map(topic => (
                          <MenuItem value={topic.idQuestionTopic} key={topic.idQuestionTopic}>
                            {topic.questionTopic}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </AccordionDetails>
                </Accordion>

                <Accordion expanded={true}>
                  <AccordionSummary
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <Typography>3. คำถาม</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <TextField
                      style={{ width: "100%" }}
                      id="outlined-basic"
                      label=""
                      variant="outlined"
                      value={question}
                      onChange={handleChangeQuestion}
                    />
                  </AccordionDetails>
                </Accordion>

                <Accordion expanded={true}>
                  <AccordionSummary
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <Typography>
                      4. จำนวนเหรียญรางวัล
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Slider
                      defaultValue={1}
                      aria-labelledby="discrete-slider-custom"
                      marks={marks}
                      valueLabelDisplay="auto"
                      min={0}
                      max={10}
                      onChange={handleScoreChange}
                    />
                  </AccordionDetails>
                </Accordion>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Card
                  variant="outlined"
                  style={{ height: "100%", backgroundColor: getBgCard(idQuestionTopic), borderColor:getBgCard(idQuestionTopic), borderWidth:10}}
                >
                  <CardContent style={{ paddingBottom: 0, textAlign: "center" }}>
                    <img
                      style={{ marginBottom: 10, padding: 8, borderRadius: 8 }}
                      src={imgInsight(idQuestionTopic)}
                      alt=""
                      width={170}
                      height={200}
                    />
                    {question === "" ? (
                      <Typography
                        variant="h5"
                        gutterBottom
                        style={{ textAlign: "center", color: "#BFBFBF" }}
                      >
                        ... แสดงตัวอย่างคำถาม ....
                      </Typography>
                    ) : (
                      <Typography
                        variant="h5"
                        gutterBottom
                        style={{ textAlign: "center", color: "#FFFFFF" }}
                      >
                        {question}
                      </Typography>
                    )}
                    {questionType === "choice" ? (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "80%",
                          margin: "auto",
                        }}
                      >
                        <StyledButton
                          variant={"outlined"}
                          // variant={insight === "hh" ? "outlined" : "contained"}
                        >
                          <div style={{ display: "flex" }}>
                            <Typography variant="button">Very Happy</Typography>
                            <StyledCoinChoice>
                              {reward}
                            </StyledCoinChoice>
                          </div>
                        </StyledButton>
                        <StyledButton
                          variant={"outlined"}
                          // variant={insight === "hh" ? "outlined" : "contained"}
                        >
                          {" "}
                          <div style={{ display: "flex" }}>
                            <Typography variant="button">Happy</Typography>
                            <StyledCoinChoice>
                              {reward}
                            </StyledCoinChoice>
                          </div>
                        </StyledButton>
                        <StyledButton
                          variant={"outlined"}
                          // variant={insight === "hh" ? "outlined" : "contained"}
                        >
                          {" "}
                          <div style={{ display: "flex" }}>
                            <Typography variant="button">Little Happy</Typography>
                            <StyledCoinChoice>
                              {reward}
                            </StyledCoinChoice>
                          </div>
                        </StyledButton>
                        <StyledButton
                          variant={"outlined"}
                          // variant={insight === "hh" ? "outlined" : "contained"}
                        >
                          {" "}
                          <div style={{ display: "flex" }}>
                            <Typography variant="button">Little Bad</Typography>
                            <StyledCoinChoice>
                              {reward}
                            </StyledCoinChoice>
                          </div>
                        </StyledButton>
                        <StyledButton
                          variant={"outlined"}
                          // variant={insight === "hh" ? "outlined" : "contained"}
                        >
                          {" "}
                          <div style={{ display: "flex" }}>
                            <Typography variant="button">Bad</Typography>
                            <StyledCoinChoice>
                              {reward}
                            </StyledCoinChoice>
                          </div>
                        </StyledButton>
                        <StyledButton
                          variant={"outlined"}
                          // variant={insight === "hh" ? "outlined" : "contained"}
                        >
                          {" "}
                          <div style={{ display: "flex" }}>
                            <Typography variant="button">Very Bad</Typography>
                            <StyledCoinChoice>
                              {reward}
                            </StyledCoinChoice>
                          </div>
                        </StyledButton>
                      </div>
                    ) : (
                      <div>
                        <div>
                          <TextField
                            style={{ width: "100%", backgroundColor: "#FFF" }}
                            id="outlined-basic"
                            label=""
                            variant="outlined"
                            multiline
                            rows={4}
                          />
                          <FormControlLabel
                            style={{ width: "100%", }}
                            control={
                              <Checkbox
                                checked={checkAnonymous}
                                onChange={handleChangeAnonymous}
                                name="checkedB"
                                color="default"
                              />
                            }
                            label={
                              <span style={{ color: 'white' }}>ส่งโดยไม่ระบุชื่อ</span>
                            }
                            
                          />
                        </div>
                        <div>
                          <StyledButton
                            variant={"contained"}
                            // variant={insight === "hh" ? "outlined" : "contained"}
                          >
                            {" "}
                            <div style={{ display: "flex", width: 100 }}>
                              <Typography variant="button">ส่งคำตอบ</Typography>
                              <StyledCoinChoice>
                                {reward}
                              </StyledCoinChoice>
                            </div>{" "}
                          </StyledButton>
                        </div>
                      </div>
                    )}
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>
              ยกเลิก
            </Button>
            <Button variant="contained" onClick={handleSubmit}>
              บันทึก
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </StyledRoot>
  );
};

export default Notification;
