import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  styled,
  Grid,
} from "@mui/material";

import tableIcon from "./assets/table.png";

const StyledTableCell = styled(TableCell)({
  color: "#ffffff",
  fontWeight: "bold",
  padding: "16px",
  borderColor: "#2e2e2e",
  width: "50%",
});

const StyledTableRow = styled(TableRow)({
  backgroundColor: "#000000",
  padding: "16px",
  borderColor: "#2e2e2e",
});

const getScoreColor = (score) => {
  if (score >= 80) return { color: "green" };
  if (score >= 60) return { color: "yellowgreen" };
  if (score >= 40) return { color: "yellow" };
  if (score >= 20) return { color: "orange" };
  return { color: "red" };
};

const DailyDepartmentSectionTable = ({ filterData }) => {
  const [filterEmployee, setFilterEmployee] = useState(null);
  const departmentScores = filterData.reduce((acc, cur) => {
    const deptKey = cur.departmentName;
    if (!acc[deptKey]) {
      acc[deptKey] = { sumScore: 0, count: 0 };
    }
    if (cur.averageScore) {
      acc[deptKey].sumScore += cur.averageScore;
      acc[deptKey].count += 1;
    }
    return acc;
  }, {});

  const sectionScores = filterData.reduce((acc, cur) => {
    const sectionKey = cur.sectionName;
    if (!acc[sectionKey]) {
      acc[sectionKey] = { sumScore: 0, count: 0 };
    }
    if (cur.averageScore) {
      acc[sectionKey].sumScore += cur.averageScore;
      acc[sectionKey].count += 1;
    }
    return acc;
  }, {});

  const departmentScoresArray = Object.keys(departmentScores).map((key) => ({
    department: key,
    averageScore: Math.round(
      departmentScores[key].sumScore / departmentScores[key].count
    ),
  }));

  const sectionScoresArray = Object.keys(sectionScores).map((key) => ({
    section: key,
    averageScore: Math.round(
      sectionScores[key].sumScore / sectionScores[key].count
    ),
  }));

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <div style={{ backgroundColor: "#ffffff", padding: "20px", height: "100%", borderRadius: "8px", marginBottom: 15, paddingLeft: "35px", paddingRight: "35px" }} >
            <Typography variant="h5" style={{ color: "#000000",  }}>
              {`คะแนนเฉลี่ยตามส่วน`}
            </Typography>
            <TableContainer
              component={Paper}
              sx={{backgroundColor: "#fff", border: "1px solid #e0e0e0", marginTop:2, }}
            >
              <Table stickyHeader sx={{ borderCollapse: "collapse", }}>
                <TableHead>
                  <TableRow>
                    <StyledTableCell
                      style={{
                        background: "#2e2e2e",
                        color: "#fff",
                        border: "1px solid #e0e0e0", 
                        borderBottom: "2px solid #e0e0e0"
                      }}
                    >
                      ส่วน
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      style={{
                        background: "#2e2e2e",
                        color: "#fff",
                        border: "1px solid #e0e0e0", 
                        borderBottom: "2px solid #e0e0e0"
                      }}
                    >
                      คะแนนเฉลี่ย (%)
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {departmentScoresArray.map((row, index) => (
                    <StyledTableRow key={index} sx={{backgroundColor:'#fff'}}>
                      <StyledTableCell component="th" scope="row" sx={{ color: "black", border: "1px solid #e0e0e0" }} >
                        <Typography color={"#000"}> {row.department} </Typography>
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        sx={{
                          fontWeight: "bold",
                          border: "1px solid #e0e0e0",
                          color: getScoreColor(row.averageScore),
                        }}
                      >
                        {isNaN(row.averageScore) ? 0 : Math.ceil(row.averageScore)}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div style={{ backgroundColor: "#ffffff", padding: "20px", height: "100%", borderRadius: "8px", marginBottom: 15, paddingLeft: "35px", paddingRight: "35px" }} >
            <Typography variant="h5" style={{ color: "#000000",  }}>
              {`คะแนนเฉลี่ยตามฝ่าย`}
            </Typography>
            <TableContainer
              component={Paper}
              sx={{backgroundColor: "#fff", border: "1px solid #e0e0e0", marginTop:2, }}
            >
               <Table stickyHeader sx={{ borderCollapse: "collapse", }}>
                <TableHead>
                  <TableRow>
                    <StyledTableCell
                      style={{
                        background: "#2e2e2e",
                        color: "#fff",
                        border: "1px solid #e0e0e0", 
                        borderBottom: "2px solid #e0e0e0"
                      }}
                    >
                      แผนก
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      style={{
                        background: "#2e2e2e",
                        color: "#fff",
                        border: "1px solid #e0e0e0", 
                        borderBottom: "2px solid #e0e0e0"
                      }}
                    >
                      คะแนนเฉลี่ย (%)
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sectionScoresArray.map((row, index) => (
                    <StyledTableRow key={index} sx={{backgroundColor:'#fff'}}>
                      <StyledTableCell component="th" scope="row" sx={{ color: "black", border: "1px solid #e0e0e0" }} >
                        <Typography color={"#000"}>{row.section}</Typography>
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        sx={{
                          fontWeight: "bold",
                          border: "1px solid #e0e0e0",
                          color: getScoreColor(row.averageScore),
                        }}
                      >
                        {isNaN(row.averageScore) ? 0 : Math.ceil(row.averageScore)}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </Grid>
      </Grid>
     </div>
  );
};

export default DailyDepartmentSectionTable;
