import {
  ASSESSMENT_FETCHING,
  ASSESSMENT_FAILED,
  ASSESSMENT_SUCCESS,
  ASSESSMENT_ANSWER_FETCHING,
  ASSESSMENT_ANSWER_FAILED,
  ASSESSMENT_ANSWER_SUCCESS,
  ASSESSMENT_ITEM_FETCHING,
  ASSESSMENT_ITEM_SUCCESS,
  ASSESSMENT_ITEM_FAILED,
  ASSESSMENT_OUTSIDER_FETCHING,
  ASSESSMENT_OUTSIDER_SUCCESS,
  ASSESSMENT_OUTSIDER_FAILED,
  QUESTION_OUTSIDER_FETCHING,
  QUESTION_OUTSIDER_SUCCESS,
  QUESTION_OUTSIDER_FAILED,
} from "./types";

import AssessmentService from "../services/assessment.service";

export const getAllAssessment = (query) => async (dispatch) => {
  try {
    dispatch({
      type: ASSESSMENT_FETCHING,
    });
    const res = await AssessmentService.getAllAssessment(query);
    if (res) {
      dispatch({
        type: ASSESSMENT_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: ASSESSMENT_FAILED,
    });
    console.log(err);
  }
};

export const getAssessment = (idAssessment) => async (dispatch) => {
  try {
    dispatch({
      type: ASSESSMENT_ITEM_FETCHING,
    });
    const res = await AssessmentService.getAssessment(idAssessment);
    if (res) {
      dispatch({
        type: ASSESSMENT_ITEM_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: ASSESSMENT_ITEM_FAILED,
    });
    console.log(err);
  }
};

export const getOutsiderAssessment = (idAssessment) => async (dispatch) => {
  try {
    dispatch({ type: ASSESSMENT_OUTSIDER_FETCHING });

    const res = await AssessmentService.getOutsiderAssessment(idAssessment);

    if (res && res.data) {
      dispatch({
        type: ASSESSMENT_OUTSIDER_SUCCESS,
        payload: res.data,
      });

      return { success: true };
    } else {
      throw new Error("Invalid email");
    }
  } catch (err) {
    dispatch({ type: ASSESSMENT_OUTSIDER_FAILED });
    console.error(err);
    return {
      success: false,
      error: err.message || "Failed to fetch assessment",
    };
  }
};

export const getAnswerByUserId =
  (idAssessment, assignTo) => async (dispatch) => {
    try {
      dispatch({
        type: ASSESSMENT_ANSWER_FETCHING,
      });
      const res = await AssessmentService.getAnswerByUserId(
        idAssessment,
        assignTo
      );
      if (res) {
        dispatch({
          type: ASSESSMENT_ANSWER_SUCCESS,
          payload: res.data,
        });
      }
    } catch (err) {
      dispatch({
        type: ASSESSMENT_ANSWER_FAILED,
      });
      console.log(err);
    }
  };

export const addAssessment = (formData) => async (dispatch) => {
  try {
    const res = await AssessmentService.addAssessment(formData);
    if (res) {
      return res;
    }
  } catch (err) {
    dispatch({
      type: ASSESSMENT_FAILED,
    });
    console.log(err);
  }
};

export const updateAssessment = (formData) => async (dispatch) => {
  try {
    const res = await AssessmentService.updateAssessment(formData);
    if (res) {
      return res;
    }
  } catch (err) {
    dispatch({
      type: ASSESSMENT_FAILED,
    });
    console.log(err);
  }
};

export const updateAssessmentStatus = (formData) => async (dispatch) => {
  try {
    const res = await AssessmentService.updateAssessmentStatus(formData);
    if (res) {
      return res;
    }
  } catch (err) {
    dispatch({
      type: ASSESSMENT_FAILED,
    });
    console.log(err);
  }
};

export const updateAssessmentQuestion = (formData) => async (dispatch) => {
  try {
    const res = await AssessmentService.updateAssessmentQuestion(formData);
    if (res) {
      return res;
    }
  } catch (err) {
    dispatch({
      type: ASSESSMENT_FAILED,
    });
    console.log(err);
  }
};

export const getAssessmentAnswer = () => async (dispatch) => {
  try {
    dispatch({
      type: ASSESSMENT_ANSWER_FETCHING,
    });
    const res = await AssessmentService.getAssessmentAnswer();
    if (res) {
      dispatch({
        type: ASSESSMENT_ANSWER_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: ASSESSMENT_ANSWER_FAILED,
    });
    console.log(err);
  }
};

export const updateAssessmentFavorite = (formData) => async (dispatch) => {
  try {
    const res = await AssessmentService.updateAssessmentFavorite(formData);
    if (res) {
      return res;
    }
  } catch (err) {
    dispatch({
      type: ASSESSMENT_FAILED,
    });
  }
};


export const getOutsiderAssessmentById = (idAppraisee, query) => async (dispatch) => {
  try {
    dispatch({
      type: QUESTION_OUTSIDER_FETCHING,
    });

    const res = await AssessmentService.getOutsiderAssessmentById(idAppraisee, query);

    if (res) {
      dispatch({
        type: QUESTION_OUTSIDER_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    console.error("Error fetching outsider question:", err); 
    dispatch({
      type: QUESTION_OUTSIDER_FAILED,
      payload: err?.message || "Unknown error",
      error: true,
    });
  }
};
