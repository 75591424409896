import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Chip,
  CircularProgress,
  Container,
  Divider,
  Stack,
  Typography,
  IconButton,
  Drawer,
  Grid,
  Tooltip,
  Select,
  MenuItem,
} from "@mui/material";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useDispatch, useSelector } from "react-redux";
import { getAllAppraisee } from "../../../../../actions/appraisee";
import dayjs from "dayjs";
import HeaderPage from "../../../shared/header/headerPage";
import iconHeader from "../assets/360-degrees-color.svg";
import Questions from "./questions";
import Assign from "./assign";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import { Link } from "react-router-dom";
import FilterListIcon from "@mui/icons-material/FilterList";
import SettingsIcon from "@mui/icons-material/Settings";
import AssessmentFilter from "../../../shared/assessmentFilter";
import DialogConfig from "./DialogConfig";

const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF",
  "& .wrapHeader": {
    display: "flex",
    alignItems: "center",
    marginBottom: 36,
    ["@media only screen and (max-width:600px)"]: {
      flexDirection: "column",
    },
  },
});

const StyledChip = styled(Chip)(({ mode, variant }) => ({
  width: 120,
  ...(mode === "questions" &&
    variant === "fill" && {
      borderColor: "#dddddd",
      backgroundColor: "#dddddd",
    }),
  ...(mode === "questions" &&
    variant === "outlined" && {
      borderColor: "#dddddd",
      backgroundColor: "#FFFFFF",
    }),
  ...(mode === "boss" && {
    borderColor: "#ff3034",
    color: "#ffffff",
    backgroundColor: "#ff3034",
  }),
  ...(mode === "peer" && {
    borderColor: "#ff8d00",
    color: "#ffffff",
    backgroundColor: "#ff8d00",
  }),
  ...(mode === "cross function" && {
    borderColor: "#7d55c2",
    color: "#ffffff",
    backgroundColor: "#7d55c2",
  }),
  ...(mode === "customer" && {
    borderColor: "#008dd1",
    color: "#ffffff",
    backgroundColor: "#008dd1",
  }),
  ...(mode === "subordinate" && {
    borderColor: "#00bdb1",
    color: "#ffffff",
    backgroundColor: "#00bdb1",
  }),
  ...(mode === "project" && {
    borderColor: "#ffc700",
    color: "#ffffff",
    backgroundColor: "#ffc700",
  }),
  ...(mode === "outsider" && {
    borderColor: "#820747",
    color: "#ffffff",
    backgroundColor: "#820747",
  }),
  ...(mode === "oneself" && {
    borderColor: "#b46c6c",
    color: "#ffffff",
    backgroundColor: "#b46c6c",
  }),
}));

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  border: `none`,
  marginBottom: 8,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  borderRadius: 16,
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
    "& .wrap": {
      width: "100%",
      display: "flex",
      alignItems: "center",
    },
  },
}));

const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  "& .wrap-number": {
    display: "flex",
    marginTop: "24px",
    marginBottom: "32px",
    justifyContent: "space-evenly",
    "& .wrap-question, .wrap-ratio": {
      "& .label": {
        fontWeight: 600,
        marginBottom: 16,
      },
      "& .item-question, .item-ratio": {
        display: "flex",
        "& div": {
          margin: "0 8px",
        },
      },
    },
  },
  "& .wrap-assign": {
    display: "flex",
    justifyContent: "flex-end",
  },
  "& .wrap-answer": {
    display: "flex",
    alignItems: "center",
    "& .progress-answer": {
      marginRight: 24,
    },
    "& .progress-text": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginBottom: 6,
      "& .answer": {
        fontWeight: 600,
      },
    },
  },
}));

const FilterIconButton = styled(IconButton)({
  color: "#FFFFFF",
  backgroundColor: "#412020",
  "&:hover": {
    backgroundColor: "#5B3737",
  },
});

const DrawerFilter = ({ setSelectDate }) => {
  const [openFilter, setOpenFilter] = useState(false);
  const [filterOptions, setFilterOptions] = useState({
    assessmentType: { open: true, close: true },
    status: { ongoing: true, finished: true },
  });


  const toggleDrawer = (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpenFilter(!openFilter);
  };
  
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          padding: "15px",
          color: "rgb(0, 0, 255)",
        }}
      >
        <FilterIconButton
          aria-label="filter"
          onClick={toggleDrawer}
          size="large"
          style={{ marginTop: "-50px", backgroundColor: "rgb(65,105,225)" }}
        >
          <FilterListIcon fontSize="large" />
        </FilterIconButton>
      </div>

      <Drawer anchor={"right"} open={openFilter} onClose={toggleDrawer}>
        <AssessmentFilter
          setFilterSelectedDate={setSelectDate}
          setFilterAssessment={setFilterOptions}
        />
      </Drawer>
    </>
  )
}

const AssessmentDetail = ({ item, handleOpenConfig }) => {
  const [view, setView] = React.useState("question");
  const [expanded, setExpanded] = React.useState("");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <StyledAccordion
      expanded={expanded === `panel${item.idAppraisee}`}
      onChange={handleChange(`panel${item.idAppraisee}`)}
      disableGutters
      elevation={0}
      square
    >
      <StyledAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
        aria-controls="panel1d-content"
        id="panel1d-header"
      >
        <div className="wrap">
          <Chip
            style={{
              marginRight: "20px",
              width: "15%",
              flexShrink: 0,
            }}
            label={`${item.firstname_TH} ${item.lastname_TH}`}
          />
          <Typography style={{ width: "30%", flexShrink: 0 }}>
            {item.assessmentName}
          </Typography>
          <Typography
            color="text.third"
            style={{ width: "43%", flexShrink: 0 }}
          >
            {`Launch Date: ${dayjs(item.startDate).format("DD MMM YYYY")} -
                    ${dayjs(item.endDate).format("DD MMM YYYY")}`}
          </Typography>
          <div
            style={{
              width: "10%",
              flexShrink: 0,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button variant="outlined">View</Button>
          </div>
        </div>
      </StyledAccordionSummary>

      <StyledAccordionDetails>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: "8px",
          }}
        >
          <Typography color="text.secondary" align="right" variant="body2">
            Created: {dayjs(item.createDate).format("DD MMM YYYY")}
          </Typography>
          <IconButton onClick={() => handleOpenConfig(item)}>
            <SettingsIcon />
          </IconButton>
        </div>
        <Typography
          variant="caption"
          className="label"
          color="text.third"
          // align="center"
          component={"p"}
          gutterBottom
          style={{ fontWeight: 600 }}
        >
          Descriptions
        </Typography>
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Typography>{`ผู้ถูกประเมิน: ${item.firstname_TH} ${item.lastname_TH}`}</Typography>
          <Tooltip title={item.assessmentDescription}>
            <Typography
              sx={{
                maxWidth: "500px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {`คำอธิบาย: ${item.assessmentDescription}`}
            </Typography>
          </Tooltip>
        </Stack>
        <div className="wrap-number">
          <div className="wrap-question">
            <Typography
              variant="caption"
              className="label"
              color="text.third"
              align="center"
              component={"p"}
              gutterBottom
            >
              Select view
            </Typography>
            <div className="item-question">
              <div>
                <Typography variant="h5" align="center">
                  {item.questionList.length}
                </Typography>
                <StyledChip
                  mode="questions"
                  label="Questions"
                  variant={view === "question" ? "fill" : "outlined"}
                  onClick={() => {
                    setView("question");
                  }}
                />
              </div>
              <div>
                <Typography variant="h5" align="center">
                  {item.sampleSize.length}
                </Typography>

                <StyledChip
                  mode="questions"
                  label="Assigns"
                  variant={view === "answer" ? "fill" : "outlined"}
                  onClick={() => {
                    setView("answer");
                  }}
                />
              </div>
            </div>
          </div>

          <Divider orientation="vertical" flexItem />
          <div className="wrap-ratio">
            <Typography
              variant="caption"
              className="label"
              color="text.third"
              align="center"
              component={"p"}
              gutterBottom
            >
              Ratio score
            </Typography>
            <div className="item-ratio">
              {[
                ...new Map(
                  item.sampleSize.map((m) => [m.sampleSizeName, m])
                ).values(),
              ].map((value) => (
                <div className="ratio-item" key={value.idSampleSize}>
                  <Typography variant="h5" align="center">
                    {`${value.ratio}%`}
                  </Typography>
                  <StyledChip
                    label={value.sampleSizeName}
                    mode={value.sampleSizeName.toLowerCase()}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="wrap-assign">
          <ButtonBlue
            variant="outlined"
            size="small"
            endIcon={<ChevronRightIcon />}
            component={Link}
            to={"/admin/assessment360/answer"}
            state={{ idAppraisee: item.idAppraisee }}
          >
            Answer
          </ButtonBlue>
        </div>
        {view === "question" ? (
          <Questions questionList={item.questionList} />
        ) : (
          <Assign assign={item.sampleSize} />
        )}
      </StyledAccordionDetails>
    </StyledAccordion>
  );
};

const currentDate = dayjs();

const AssignAssessment360 = () => {
  const [filterOptions, setFilterOptions] = useState({
    assessmentType: { open: true, close: true },
    status: { ongoing: true, finished: true },
  });


  const dispatch = useDispatch();
  const { result: appraiseeList, isFetching } = useSelector(
    (state) => state.appraisee
  );

  const [selectDate, setSelectDate] = useState(dayjs().startOf("month"));
  const [selectAssessment, setSelectdAssessment] = useState(null);
  const [openConfig, setOpenConfig] = useState(false);

  const handleOpenConfig = (value) => {
    setOpenConfig(true);
    setSelectdAssessment(value);
  };

  const handleCloseConfig = () => {
    setOpenConfig(false);
    setSelectdAssessment(null);
  };

  useEffect(() => {
    dispatch(getAllAppraisee({ selectDate: selectDate.format("YYYY-MM") }));
  }, [selectDate]);

  return (
    <StyledRoot className={`page`}>
      <Container maxWidth="lg">
        <div className={`wrapHeader`}>
          <HeaderPage textLabel={"ประเมิน 360 องศา"} icon={iconHeader} />
        </div>
        <DrawerFilter setSelectDate={setSelectDate} />
        {isFetching || !appraiseeList ? (
          <CircularProgress />
        ) : appraiseeList && appraiseeList.length > 0 ? (
          appraiseeList
            .filter((item) => {
              const isOngoing =
                filterOptions.status.ongoing &&
                currentDate.isAfter(dayjs(item.startDate)) &&
                currentDate.isBefore(dayjs(item.endDate));

              const isFinished =
                filterOptions.status.finished &&
                currentDate.isAfter(dayjs(item.endDate));

              return isOngoing || isFinished;
            })
            .map((item) => (
              <Grid item xs={12} sm={12} md={12} key={item.idAppraisee}>
                <AssessmentDetail item={item} handleOpenConfig={handleOpenConfig} />
              </Grid>
            ))
        ) : (
          <Typography variant="h6" align="center">
            ไม่มีการส่งคำถาม{" "}
          </Typography>
        )}
      </Container>
      {openConfig && (
        <DialogConfig
          open={openConfig}
          handleClose={handleCloseConfig}
          data={selectAssessment}
          selectDate={selectDate}
        />
      )}
    </StyledRoot>
  );
};

export default AssignAssessment360;
