import H1 from "./assets/h1.png";
import H2 from "./assets/h2.png";
import H3 from "./assets/h3.png";
import H4 from "./assets/h4.png";
import H5 from "./assets/h5.png";
import H6 from "./assets/h6.png";
import H7 from "./assets/h7.png";
import H8 from "./assets/h8.png";
import H9 from "./assets/h9.png";
import H10 from "./assets/h10.png";

const bgCard = [
  "#ff1744",
  "#d500f9",
  "#651fff",
  "#2979ff",
  "#14a37f",
  "#ffc400",
  "#ff9100",
  "#357a38",
  "#f73378",
  "#6D4C41",
];

const getBgCard = (id) => {
  if (id === 0) {
    return "#BFBFBF";
  }
  const idModTen = id % 10;
  return bgCard[idModTen];
};

const imgInsight = (id) => {
  const idModTen = id % 10;
  switch (idModTen) {
    case 0:
      return H1;
    case 1:
      return H2;
    case 2:
      return H3;
    case 3:
      return H4;
    case 4:
      return H5;
    case 5:
      return H6;
    case 6:
      return H7;
    case 7:
      return H8;
    case 8:
      return H9;
    case 9:
      return H10;
    default:
      break;
  }
};

const truncateText = (text, maxLength) => {
  if (text.length > maxLength) {
    return `${text.substring(0, maxLength)}...`;
  }
  return text;
};

export { getBgCard, imgInsight, truncateText };
