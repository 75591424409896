import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Typography,
  Grid,
  Card,
  Select,
  MenuItem,
  LinearProgress,
  Stack,
  Button,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAllMoraleDaily } from "../../../../../actions/moraleDaily";
import LoadingIcon from "../../../shared/general/loadingIcon";
import dayjs from "dayjs";
import { getBgCard, imgInsight } from "./utils";

const StyledCard = styled(Card)({
  height: "100%",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  border: "10px solid ",
});

const currentYear = dayjs().year();
const currentMonth = dayjs().month() + 1;

const MoraleDailyHistory = ({ selectTopic }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { result: moraleDaily, isFetching } = useSelector(
    (state) => state.moraleDaily
  );

  const [expandedCardId, setExpandedCardId] = useState(null);

  const [selectYear, setSelectYear] = useState(currentYear);
  const [selectMonth, setSelectMonth] = useState(currentMonth);

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return `${text.substring(0, maxLength)}...`;
    }
    return text;
  };

  const toggleReadMore = (id) => {
    setExpandedCardId((prevId) => (prevId === id ? null : id));
  };

  const handleClickAnswerPage = (id) => {
    navigate("/admin/manage/morale-daily/" + id);
  };

  useEffect(() => {
    dispatch(getAllMoraleDaily({ year: selectYear, month: selectMonth }));
  }, [selectYear, selectMonth]);

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "8px",
          marginBottom: "16px",
        }}
      >
        <Select
          value={selectYear}
          onChange={(event) => setSelectYear(event.target.value)}
        >
          <MenuItem value={currentYear}>{currentYear}</MenuItem>
          <MenuItem value={currentYear - 1}>{currentYear - 1}</MenuItem>
        </Select>
        <Select
          value={selectMonth}
          onChange={(event) => setSelectMonth(event.target.value)}
        >
          {Array.from({ length: 12 }, (_, index) => (
            <MenuItem key={index + 1} value={index + 1}>
              {new Date(0, index).toLocaleString("default", { month: "long" })}{" "}
              {/* Get month name */}
            </MenuItem>
          ))}
        </Select>
      </div>
      {isFetching ? (
        <LoadingIcon />
      ) : (
        <Grid container spacing={2}>
          {moraleDaily &&
            moraleDaily
              .filter((data) =>
                selectTopic ? selectTopic === data.idQuestionTopic : true
              )
              .map((data) => {
                const numberOfAnswer = data.participantList.filter(
                  (item) => item.answer !== null
                ).length;
                const isExpanded = expandedCardId === data.idMoraleDaily;
                const shouldShowReadMore = data.question.length > 60;
                const shouldShowScroll = data.question.length > 80;
                return (
                  <Grid item xs={12} sm={6} lg={4}>
                    <StyledCard
                      elevation={1}
                      key={data.idMoraleDaily}
                      sx={{
                        position: "relative",
                        borderRadius: "15px",
                        borderColor: getBgCard(data.idQuestionTopic),
                        padding: 2,
                      }}
                    >
                      <div
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          backgroundColor: getBgCard(data.idQuestionTopic),
                          width: 45,
                          height: 45,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      ></div>
                      <div
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          backgroundColor: "#FFD923",
                          color: "#EA8900",
                          borderRadius: "50%",
                          width: 40,
                          height: 40,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Typography
                          style={{
                            fontSize: "40px",
                            color: "#EA8900",
                            fontWeight: "600",
                          }}
                        >
                          {data.reward}
                        </Typography>
                      </div>

                      <div style={{ minHeight: 300, alignItems: "center" }}>
                        <Stack
                          display="flex"
                          direction="column"
                          alignItems="center"
                          spacing={1}
                        >
                          <div
                            style={{
                              alignContent: "center",
                              paddingTop: 25,
                              paddingBottom: 10,
                            }}
                          >
                            <Typography
                              variant="h6"
                              color="primary"
                              textAlign={"center"}
                              sx={{
                                lineHeight: 1,
                                fontWeight: "bold",
                                color: getBgCard(data.idQuestionTopic),
                                fontSize: "28px",
                                textAlign: "center",
                              }}
                            >
                              {data.questionTopic}
                            </Typography>
                          </div>
                          <img
                            alt="questionImg"
                            width={140}
                            height={170}
                            src={imgInsight(data.idQuestionTopic)}
                            style={{
                              borderRadius: "50%",
                              backgroundColor: "#FFF",
                            }}
                          />
                        </Stack>
                      </div>
                      <div
                        style={{
                          height: shouldShowReadMore ? "auto" : 120,
                          maxHeight: 180,
                          borderRadius: "4px",
                          padding: "8px",
                          background: `${getBgCard(data.idQuestionTopic)}50`,
                          overflow: "hidden",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                        >
                          <Typography
                            variant="subtitle1"
                            color="textSecondary"
                            textAlign={"left"}
                          >
                            {`คำถาม:`}
                          </Typography>
                          <Typography
                            variant="h5"
                            textAlign={"left"}
                            sx={{
                              lineHeight: 1.1,
                              fontSize: "18px",
                              marginBottom: 1,
                            }}
                            style={{
                              maxHeight:
                                isExpanded || !shouldShowReadMore
                                  ? 180
                                  : "60px",
                              overflow:
                                isExpanded || !shouldShowReadMore
                                  ? "visible"
                                  : "hidden",
                              // overflowY: shouldShowScroll && !isExpanded ? "auto" : "hidden", // Enable scrolling if needed
                              // padding: "0 8px",
                            }}
                          >
                            {!isExpanded
                              ? truncateText(data.question, 60)
                              : data.question}
                          </Typography>
                          {shouldShowReadMore && (
                            <Button
                              onClick={() => toggleReadMore(data.idMoraleDaily)}
                              style={{
                                lineHeight: 1.1,
                                paddingBottom: 10,
                                fontSize: "16px",
                                padding: 0,
                                cursor: "pointer",
                                background: "none",
                                border: "none",
                                // textDecoration: "underline",
                              }}
                            >
                              {isExpanded ? "Read Less" : "Read More"}
                            </Button>
                          )}
                        </div>
                      </div>
                      <div style={{ height: 160 }}>
                        <div style={{ marginBottom: "8px", marginTop: "8px" }}>
                          <Typography
                            variant="subtitle1"
                            color="textSecondary"
                            style={{ textAlign: "left", marginBottom: "-7px" }}
                          >
                            {`ประเภทคำตอบ: ${
                              data.questionType === "text"
                                ? "ข้อความ"
                                : "ตัวเลือก"
                            }`}
                          </Typography>
                          <Typography
                            style={{ textAlign: "left", marginBottom: "-7px" }}
                            variant="subtitle1"
                          >
                            {`วันที่เริ่มต้น: ${dayjs(data.startDate).format(
                              "YYYY-MM-DD HH:mm"
                            )}`}
                          </Typography>
                          <Typography
                            style={{ textAlign: "left" }}
                            variant="subtitle1"
                          >
                            {`วันที่สิ้นสุด: ${dayjs(data.endDate).format(
                              "YYYY-MM-DD HH:mm"
                            )}`}
                          </Typography>
                        </div>
                        <div>
                          <LinearProgress
                            style={{ height: "10px", borderRadius: "8px" }}
                            variant="determinate"
                            value={
                              (numberOfAnswer * 100) /
                              data.participantList.length
                            }
                          />
                          <Stack
                            direction={"row"}
                            alignItems={"center"}
                            justifyContent={"space-between"}
                          >
                            <Typography variant="body2" color={"#3C3C3C"}>
                              {`ประเมินแล้ว ${numberOfAnswer}/${data.participantList.length} คน`}
                            </Typography>
                            <Button
                              onClick={() =>
                                handleClickAnswerPage(data.idMoraleDaily)
                              }
                            >
                              ดูคำตอบ
                            </Button>
                          </Stack>
                        </div>
                      </div>
                    </StyledCard>
                  </Grid>
                );
              })}
        </Grid>
      )}
    </>
  );
};

export default MoraleDailyHistory;
