import React, { useEffect } from "react";
import {
  Typography,
  Grid,
  LinearProgress,
  Stack,
  Container,
  Box,
  Button,
  Card,
  styled,
} from "@mui/material";
import noData from "./assets/no data.jpg";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getMoraleDailyActive } from "../../../../../actions/moraleDaily";
import LoadingIcon from "../../../shared/general/loadingIcon";
import dayjs from "dayjs";
import { getBgCard, imgInsight, truncateText } from "./utils";

const StyledCard = styled(Card)({
  height: "100%",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  border: "10px solid ",
});

const MoraleDailyInProgress = ({ selectTopic }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { result: moraleDaily, isFetching } = useSelector(
    (state) => state.moraleDaily
  );

  const [expandedCardId, setExpandedCardId] = React.useState(null);

  const toggleReadMore = (id) => {
    setExpandedCardId((prevId) => (prevId === id ? null : id));
  };

  const handleClickAnswerPage = (id) => {
    navigate("/admin/manage/morale-daily/" + id);
  };

  const filterData =
    moraleDaily?.filter((data) =>
      selectTopic ? selectTopic === data.idQuestionTopic : true
    ) || [];

  useEffect(() => {
    dispatch(getMoraleDailyActive());
  }, []);

  if (filterData.length === 0) {
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img
        src={noData}
        alt="noData"
        style={{
          width: "40%",
          height: "40%",
        }}
      />
      <Typography style={{ fontSize: "20px", fontWeight: "500" }}>
        ยังไม่มีแบบสำรวจของท่านในขณะนี้
      </Typography>
    </Box>;
  }

  return (
    <>
      <Grid container spacing={2}>
        {isFetching ? (
          <LoadingIcon />
        ) : (
          filterData.map((data) => {
            const numberOfAnswer = data.participantList.filter(
              (item) => item.answer !== null
            ).length;
            const isExpanded = expandedCardId === data.idMoraleDaily;
            const shouldShowReadMore = data.question.length > 60;
            return (
              <Grid item xs={12} md={6} lg={4}>
                <StyledCard
                  elevation={1}
                  key={data.idMoraleDaily}
                  sx={{
                    position: "relative",
                    borderRadius: "15px",
                    borderColor: getBgCard(data.idQuestionTopic),
                    padding: 2,
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      backgroundColor: getBgCard(data.idQuestionTopic),
                      width: 45,
                      height: 45,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  ></div>
                  <div
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      backgroundColor: "#FFD923",
                      color: "#EA8900",
                      borderRadius: "50%",
                      width: 40,
                      height: 40,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: "40px",
                        color: "#EA8900",
                        fontWeight: "600",
                      }}
                    >
                      {data.reward}
                    </Typography>
                  </div>

                  <Container style={{ height: 270, alignItems: "center" }}>
                    <Stack
                      display="flex"
                      direction="column"
                      alignItems="center"
                      spacing={1}
                    >
                      <div
                        style={{
                          alignContent: "center",
                          paddingTop: 25,
                          paddingBottom: 10,
                        }}
                      >
                        <Typography
                          variant="h6"
                          color="primary"
                          textAlign={"center"}
                          sx={{
                            lineHeight: 1,
                            fontWeight: "bold",
                            color: getBgCard(data.idQuestionTopic),
                            fontSize: "28px",
                            textAlign: "center",
                          }}
                        >
                          {data.questionTopic}
                        </Typography>
                      </div>
                      <img
                        alt="questionImg"
                        width={140}
                        height={170}
                        src={imgInsight(data.idQuestionTopic)}
                        style={{ borderRadius: "50%", backgroundColor: "#FFF" }}
                      />
                    </Stack>
                  </Container>

                  <Container
                    style={{
                      height: shouldShowReadMore ? "auto" : 120,
                      maxHeight: 180,
                      borderRadius: "4px",
                      paddingTop: 5,
                      paddingBottom: 5,
                      background: `${getBgCard(data.idQuestionTopic)}50`,
                      overflow: "hidden",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                    >
                      <Typography
                        variant="subtitle1"
                        color="textSecondary"
                        textAlign={"left"}
                      >
                        {`คำถาม:`}
                      </Typography>
                      <Typography
                        variant="h5"
                        textAlign={"left"}
                        sx={{
                          lineHeight: 1.1,
                          fontSize: "18px",
                          marginBottom: 1,
                        }}
                        style={{
                          maxHeight:
                            isExpanded || !shouldShowReadMore ? 180 : "60px",
                          overflow:
                            isExpanded || !shouldShowReadMore
                              ? "visible"
                              : "hidden",
                        }}
                      >
                        {!isExpanded
                          ? truncateText(data.question, 60)
                          : data.question}
                      </Typography>
                      {shouldShowReadMore && (
                        <Button
                          onClick={() => toggleReadMore(data.idMoraleDaily)}
                          style={{
                            lineHeight: 1.1,
                            paddingBottom: 10,
                            fontSize: "16px",
                            padding: 0,
                            cursor: "pointer",
                            background: "none",
                            border: "none",
                          }}
                        >
                          {isExpanded ? "Read Less" : "Read More"}
                        </Button>
                      )}
                    </div>
                  </Container>
                  <Container style={{ height: 130 }}>
                    <div
                      style={{
                        paddingTop: 10,
                        paddingBottom: 10,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        alignContent: "start",
                      }}
                    >
                      <Typography
                        variant="subtitle1"
                        color="textSecondary"
                        style={{ textAlign: "left", marginBottom: "-7px" }}
                      >
                        {`ประเภทคำตอบ: ${
                          data.questionType === "text" ? "ข้อความ" : "ตัวเลือก"
                        }`}
                      </Typography>
                      <Typography
                        style={{ textAlign: "left", marginBottom: "-7px" }}
                        variant="subtitle1"
                      >
                        {`start: ${dayjs(data.startDate).format(
                          "YYYY-MM-DD HH:mm"
                        )}`}
                      </Typography>
                      <Typography
                        style={{ textAlign: "left" }}
                        variant="subtitle1"
                      >
                        {`end: ${dayjs(data.endDate).format(
                          "YYYY-MM-DD HH:mm"
                        )}`}
                      </Typography>
                    </div>
                    <div>
                      <LinearProgress
                        style={{ width: "100%", height: "10px" }}
                        variant="determinate"
                        value={
                          (numberOfAnswer * 100) / data.participantList.length
                        }
                      />
                      <Stack
                        direction={"row"}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                      >
                        <Typography variant="body2" color={"#3C3C3C"}>
                          {`ประเมินแล้ว ${numberOfAnswer}/${data.participantList.length} คน`}
                        </Typography>
                        <Button
                          onClick={() =>
                            handleClickAnswerPage(data.idMoraleDaily)
                          }
                        >
                          ดูคำตอบ
                        </Button>
                      </Stack>
                    </div>
                  </Container>
                </StyledCard>
              </Grid>
            );
          })
        )}
      </Grid>
    </>
  );
};

export default MoraleDailyInProgress;
