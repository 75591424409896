import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import UniLogo from "../assets/UNI_HR_Logo_RGB_Full_Color.png";
import universeBackground2 from "./universeBackground.jpg";
import PersonIcon from "@mui/icons-material/Person";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import {
  styled,
  Typography,
  Box,
  Card,
  Button,
  Toolbar,
  CardContent,
  Divider,
  Avatar,
  Grid,
  Chip,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import { getOutsiderAssessment } from "../../../actions/assessment";
import dayjs from "dayjs";

const BtnLogin1 = styled(Button)({
  color: "#ffffff",
  "&:hover": {
    borderBottom: "5px solid black",
  },
});

const StyledRoot = styled("div")({
  display: "flex",
  flexDirection: "column",
  minWidth: 350,
  minHeight: "100vh",
  backgroundColor: "#000000",
  flexGrow: 1,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  backgroundImage: `url(${universeBackground2})`,
});

const OutsiderQuestion = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [startAssessment, setStartAssessment] = useState(false);
  const [selectedAssessment, setSelectedAssessment] = useState([]);
  const outsiderEmail = location.state?.email;
  const { result: outsiderQuestions, isFetching } = useSelector(
    (state) => state.assessmentOutsider
  );

  useEffect(() => {
    if (outsiderEmail) {
      dispatch(getOutsiderAssessment(outsiderEmail));
    }
  }, [dispatch]);

  const handleStartAssessment = (idAppraisee) => {
    setSelectedAssessment(outsiderQuestions);
    setStartAssessment(true);
    navigate("/outsider-answer-question", { state: { idAppraisee, outsiderEmail } });
  };

  return (
    <>
      {isFetching ? (
        <CircularProgress />
      ) : outsiderQuestions && outsiderQuestions.length > 0 ? (
        <StyledRoot className={"page"} sx={{}}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Card
              sx={{
                backgroundColor: "white",
                boxShadow: "none",
                borderRadius: "45px",
                width: "95%",
                justifyContent: "center",
                alingItems: "center",
              }}
            >
              <Toolbar
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "20px",
                }}
              >
                <Link to="/landing">
                  <div style={{ width: "112px", height: "63px" }}>
                    <img
                      alt="uni-culture-logo"
                      src={UniLogo}
                      style={{
                        marginTop: "-17.5px",
                        marginLeft: "-25px",
                        width: "120%",
                        height: "160%",
                        objectFit: "contain",
                      }}
                    />
                  </div>
                </Link>

                <Box>
                  <BtnLogin1
                    sx={{ color: "black" }}
                    component={Link}
                    to="/landing"
                  >
                    About Us
                  </BtnLogin1>

                  <BtnLogin1
                    sx={{ color: "black" }}
                    component={Link}
                    to="/login"
                  >
                    Log In
                  </BtnLogin1>
                </Box>
              </Toolbar>
            </Card>
          </Box>

          <Grid
            container
            spacing={4}
            sx={{
              padding: 4.2,
              justifyContent: "center",
            }}
          >
            {outsiderQuestions.map((assessment, index) => (
              <Grid item xs={12} sm={6} key={index}>
                <Card
                  sx={{
                    height: "100%",
                    borderRadius: "12px",
                    boxShadow: 3,
                    padding: 2,
                    transition: "transform 0.3s, box-shadow 0.3s",
                    "&:hover": {
                      transform: "scale(1.02)",
                      boxShadow: 6,
                    },
                  }}
                >
                  <CardContent>
                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Tooltip title={assessment.assessmentName}>
                        <Typography
                          sx={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                          gutterBottom
                          variant="h5"
                        >
                          {assessment.assessmentName}
                        </Typography>
                      </Tooltip>
                      <Chip
                        sx={{ bgcolor: "#EC5B6A", color: "white" }}
                        label={
                          dayjs(assessment.endDate).diff(dayjs(), "day") === 0
                            ? "วันสุดท้าย"
                            : `เหลือเวลาอีก: ${dayjs(assessment.endDate).diff(
                                dayjs(),
                                "day"
                              )} วัน`
                        }
                      />
                    </Box>

                    <Divider />

                    <Chip
                      sx={{ marginTop: "16px" }}
                      label={`ผู้ถูกประเมิน : ${assessment.firstname_TH}  ${assessment.lastname_TH}`}
                      avatar={
                        <Avatar sx={{ backgroundColor: "white" }}>
                          <PersonIcon />
                        </Avatar>
                      }
                    />
                    <Divider sx={{ borderColor: "white" }} />
                    <Chip
                      sx={{ marginTop: "8px" }}
                      label={`คำถาม : ${assessment.numberOfQuestion} ข้อ`}
                      avatar={
                        <Avatar sx={{ backgroundColor: "white" }}>
                          <QuestionAnswerIcon />
                        </Avatar>
                      }
                    />

                    <div sx={{ maxWidth: "200px" }}>
                      <Typography
                        variant="body2"
                        sx={{
                          marginTop: "20px",
                          color: "black",
                          marginBottom: 2,
                        }}
                      >
                        คำอธิบาย
                      </Typography>
                    </div>
                    <Tooltip title={assessment.assessmentDescription}>
                      <Typography
                        variant="body2"
                        sx={{
                          marginTop: "20px",
                          color: "text.secondary",
                          marginBottom: 2,
                          display: "-webkit-box",
                          overflow: "hidden",
                          WebkitBoxOrient: "vertical",
                          WebkitLineClamp: 3,
                          height: "60px",
                        }}
                      >
                        {assessment.assessmentDescription}
                      </Typography>
                    </Tooltip>
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <Button
                        variant="contained"
                        sx={{
                          marginTop: "20px",
                          backgroundColor: "#bd6acf",
                          borderRadius: "15px",
                          width: "150px",
                          "&:hover": { backgroundColor: "#dda2ea" },
                        }}
                        onClick={() => {
                          handleStartAssessment(assessment.idAppraisee);
                        }}
                      >
                        เริ่มทำแบบประเมิน
                      </Button>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </StyledRoot>
      ) : (
        <StyledRoot
          className={"page"}
          sx={{ justifyContent: "center", alignItems: "center" }}
        >
          <Typography variant="h4" sx={{ color: "white" }}>
            ท่านได้ทำแบบประเมินครบถ้วนแล้ว
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Button
              variant="contained"
              sx={{
                marginTop: "20px",
                backgroundColor: "#bd6acf",
                borderRadius: "15px",
                width: "150px",
                "&:hover": { backgroundColor: "#dda2ea" },
              }}
              onClick={() => {
                navigate("/landing");
              }}
            >
              กลับสู่หน้าหลัก
            </Button>
          </Box>
        </StyledRoot>
      )}
    </>
  );
};

export default OutsiderQuestion;
