import React, { Fragment, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import Button from "@mui/material/Button";
import { Typography, Stack, Tabs, Container } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import Tab from "@mui/material/Tab";
import AddIcon from "@mui/icons-material/Add";
import { DatePicker } from "@mui/x-date-pickers";

import Hacker from "./assets/hacker.png";
import feedbackHeaderIcon from "./assets/feedback-text.png";
// import IconButton from "@mui/material/IconButton";
// import BgPage from "./assets/feedback.png";
import { useDispatch, useSelector } from "react-redux";
import { getAllEmployees } from "../../../../actions/user";
import { getFeedback } from "../../../../actions/feedback";
import DialogSendFeedback from "./dialogSendFeedback";

const StyledCard = styled(Card)({
  borderRadius: 16,
  marginBottom: 20,
});

const StyledTitle = styled(Typography)({
  color: "#424242",
});

const StyledCardContent = styled(CardContent)(({ anonymous }) => {
  return anonymous === "true"
    ? {
        display: "flex",
        flexDirection: "row-reverse",
        ["@media only screen and (max-width:600px)"]: {
          flexDirection: "column",
        },
      }
    : {
        display: "flex",
        ["@media only screen and (max-width:600px)"]: {
          flexDirection: "column",
        },
      };
});

const StyledFromFeedback = styled("div", {
  shouldForwardProp: (prop) => prop !== "anonymous",
})(({ anonymous }) => ({
  display: "flex",
  flexDirection: anonymous === "true" ? "row-reverse" : "row",
}));

const StyledRoot = styled("div")({
  backgroundSize: "cover",
  minHeight: "100dvh",
  background:
    "linear-gradient(90deg, hsla(332, 53%, 82%, 1) 0%, hsla(176, 57%, 89%, 1) 100%)",
});

const AddFeedbackButton = styled(Button)({
  marginRight: 8,
  fontSize: 16,
  backgroundColor: "#562B2B",
  "&:hover": {
    backgroundColor: "#341A1A",
  },
});

// const FilterIconButton = styled(IconButton)({
//   color: "#FFFFFF",
//   backgroundColor: "#562B2B",
//   "&:hover": {
//     backgroundColor: "#341A1A",
//   },
// });

const WrapTabs = styled("div")({
  backgroundColor: "#FFFFFF",
  borderRadius: 8,
});

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

const Feedback = () => {
  const [dateFilter, setDateFilter] = useState(dayjs());
  const [filterData, setFilterData] = useState([]);
  const [open, setOpen] = useState(false);
  const [tabValue, setTabValue] = useState(0);

  const dispatch = useDispatch();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: feedbackList } = useSelector((state) => state.feedback);

  useEffect(() => {
    let isCancelled = false;

    const fetchData = async () => {
      try {
        dispatch(getFeedback({ 
          year: dateFilter.get("year"), 
          month: dateFilter.get("month") + 1 
        }));
      } catch (e) {
        if (!isCancelled) {
          console.log(e);
        }
      }
    };

    fetchData();
    return () => {
      isCancelled = true;
    };
  }, [dateFilter]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangetabValue = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    if (feedbackList && userProfile) {
      const temp = feedbackList.filter(
        (item) =>
          dayjs(item.feedbackDate).isSame(dateFilter, "month") &&
          (tabValue === 0
            ? item.idReceiver === userProfile.idEmployees
            : item.idReceiver !== userProfile.idEmployees)
      );
      setFilterData(temp);
    }
  }, [dateFilter, tabValue, feedbackList]);

  useEffect(() => {
    dispatch(getAllEmployees());
  }, []);

  return (
    <StyledRoot className={"page"}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <img alt="feedback-icon" src={feedbackHeaderIcon} width={250} />
      </div>
      {userProfile && (
        <Container>
          <Stack
            direction="row"
            justifyContent={"space-between"}
            alignItems={"center"}
            marginTop={2}
            marginBottom={2}
            flexWrap={"wrap"}
          >
            <WrapTabs>
              <Tabs value={tabValue} onChange={handleChangetabValue}>
                <Tab label={"ได้รับ"} {...a11yProps(0)} />
                <Tab label={"ส่งไป"} {...a11yProps(1)} />
              </Tabs>
            </WrapTabs>
            <AddFeedbackButton
              variant="contained"
              startIcon={<AddIcon />}
              onClick={handleClickOpen}
            >
              {"ส่งฟีดแบค"}
            </AddFeedbackButton>
          </Stack>
          <div style={{ marginBottom: "16px" }}>
            <Typography fontSize={14} fontWeight={500}>
              {"ค้นหาเดือน"}
            </Typography>
            <DatePicker
              sx={{ backgroundColor: "#ffffff", borderRadius: "8px" }}
              views={["month", "year"]}
              openTo="month"
              format="MMMM YYYY"
              minDate={dayjs().subtract(1, "year").startOf("year")}
              maxDate={dayjs()}
              value={dateFilter}
              onChange={(value) => setDateFilter (value)}
            />
          </div>
          <CustomTabPanel value={tabValue} index={0}>
            {filterData.length > 0 ? (
              filterData.map((value) => (
                <Fragment key={value.idFeedback}>
                  <StyledCard variant="outlined">
                    <StyledCardContent
                      anonymous={
                        value.feedbackType === "anonymous" ? "true" : "false"
                      }
                    >
                      <div style={{ paddingRight: 16 }}>
                        <img
                          src={
                            value.feedbackType === "anonymous"
                              ? `${Hacker}`
                              : value.sender_imageURL
                          }
                          alt="friend"
                          width={100}
                          style={{ borderRadius: 45 }}
                        />
                      </div>
                      <div>
                        <StyledTitle gutterBottom>
                          <FormatQuoteIcon
                            fontSize={"large"}
                            style={{ transform: "scale(-1, 1)" }}
                          />
                          {value.feedback}
                          <FormatQuoteIcon fontSize={"large"} />
                        </StyledTitle>
                        <StyledFromFeedback
                          anonymous={
                            value.feedbackType === "anonymous"
                              ? "true"
                              : "false"
                          }
                        >
                          <Typography
                            variant="subtitle1"
                            style={{ fontSize: 14, padding: "0 4px" }}
                            color="textSecondary"
                          >
                            {`—`}
                          </Typography>
                          <Typography
                            variant="subtitle1"
                            style={{ fontSize: 14 }}
                            color="textSecondary"
                          >
                            {value.feedbackType === "anonymous"
                              ? ` Anonymous, `
                              : ` ${value.sender_firstname_TH} ${value.sender_lastname_TH}, `}
                            {dayjs(value.feedbackDate).format(
                              "D MMM YYYY HH:mm"
                            )}
                          </Typography>
                        </StyledFromFeedback>
                      </div>
                    </StyledCardContent>
                  </StyledCard>
                </Fragment>
              ))
            ) : (
              <Typography variant="h5" textAlign={"center"}>
                {"ไม่มีข้อมูล"}
              </Typography>
            )}
          </CustomTabPanel>
          <CustomTabPanel value={tabValue} index={1}>
            {filterData.length > 0 ? (
              filterData.map((value) => (
                <Fragment key={value.idFeedback}>
                  <StyledCard variant="outlined">
                    <StyledCardContent anonymous={"false"}>
                      <div style={{ paddingRight: 16 }}>
                        <img
                          src={value.receiver_imageURL}
                          alt="friend"
                          width={100}
                          style={{ borderRadius: 45 }}
                        />
                      </div>
                      <div>
                        <StyledTitle gutterBottom>
                          <FormatQuoteIcon
                            fontSize={"large"}
                            style={{ transform: "scale(-1, 1)" }}
                          />
                          {value.feedback}
                          <FormatQuoteIcon fontSize={"large"} />
                        </StyledTitle>
                        <StyledFromFeedback anonymous={false}>
                          <Typography
                            variant="subtitle1"
                            style={{ fontSize: 14, padding: "0 4px" }}
                            color="textSecondary"
                          >
                            {`—`}
                          </Typography>
                          <Typography
                            variant="subtitle1"
                            style={{ fontSize: 14 }}
                            color="textSecondary"
                          >
                            {` ${value.receiver_firstname_TH} ${value.receiver_lastname_TH}, `}
                            {dayjs(value.feedbackDate).format(
                              "D MMM YYYY HH:mm"
                            )}
                          </Typography>
                        </StyledFromFeedback>
                      </div>
                    </StyledCardContent>
                  </StyledCard>
                </Fragment>
              ))
            ) : (
              <Typography variant="h5" textAlign={"center"}>
                {"ไม่มีข้อมูล"}
              </Typography>
            )}
          </CustomTabPanel>
        </Container>
      )}
      {open && (
        <DialogSendFeedback
          open={open}
          handleClose={handleClose}
          setTabValue={setTabValue}
        />
      )}
    </StyledRoot>
  );
};

export default Feedback;
