import React from "react";
import { styled } from "@mui/material/styles";
import {
  Typography,
  Grid,
  Card,
  CardActions,
  Stack,
  Container,
  Button,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  updateMoraleQuestion,
  getAllMoraleDailyQuestion,
} from "../../../../../actions/moraleDaily";
import { toast } from "sonner";
import { getBgCard, imgInsight } from "./utils";

const StyledCard = styled(Card)({
  height: "100%",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  border: "10px solid ",
});

const MoraleDailyArchive = ({ selectTopic }) => {
  const dispatch = useDispatch();
  const { result: moraleDailyQuestion } = useSelector(
    (state) => state.moraleDailyQuestion
  );

  const handleClickeActive = async (id) => {
    const res = await dispatch(
      updateMoraleQuestion({
        idMoraledailyQuestion: id,
        isActive: 1,
      })
    );
    if (res && res.status === 200) {
      toast.success("ดำเนินการสำเร็จ");
      dispatch(getAllMoraleDailyQuestion());
    } else {
      toast.success("เกิดข้อผิดพลาด");
    }
  };

  return (
    <Grid container spacing={2}>
      {moraleDailyQuestion &&
        moraleDailyQuestion
          .filter(
            (data) =>
              !data.isActive &&
              (selectTopic ? selectTopic === data.idQuestionTopic : true)
          )
          .map((data) => (
            <Grid item xs={12} md={6} lg={4} key={data.idMoraledailyQuestion}>
              <StyledCard
                elevation={3}
                sx={{
                  position: "relative",
                  borderRadius: "15px",
                  borderColor: getBgCard(data.idQuestionTopic),
                  padding: 2,
                  paddingBottom: 5,
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    backgroundColor: getBgCard(data.idQuestionTopic),
                    width: 45,
                    height: 45,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                ></div>
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    backgroundColor: "#FFD923",
                    color: "#EA8900",
                    borderRadius: "50%",
                    width: 40,
                    height: 40,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    style={{
                      fontSize: "40px",
                      color: "#EA8900",
                      fontWeight: "600",
                    }}
                  >
                    {data.reward}
                  </Typography>
                </div>

                <Container style={{ height: 270, alignItems: "center" }}>
                  <Stack
                    display="flex"
                    direction="column"
                    alignItems="center"
                    spacing={1}
                  >
                    <div
                      style={{
                        alignContent: "center",
                        paddingTop: 25,
                        paddingBottom: 10,
                      }}
                    >
                      <Typography
                        variant="h6"
                        color="primary"
                        textAlign={"center"}
                        sx={{
                          lineHeight: 1,
                          fontWeight: "bold",
                          color: getBgCard(data.idQuestionTopic),
                          fontSize: "28px",
                          textAlign: "center",
                        }}
                      >
                        {data.questionTopic}
                      </Typography>
                    </div>
                    <img
                      alt="questionImg"
                      width={140}
                      height={170}
                      src={imgInsight(data.idQuestionTopic)}
                      style={{ borderRadius: "50%", backgroundColor: "#FFF" }}
                    />
                  </Stack>
                </Container>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    alignContent: "start",
                    minHeight: "190px",
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    color="textSecondary"
                    textAlign={"left"}
                  >
                    {`คำถาม:`}
                  </Typography>
                  <Typography
                    variant="h5"
                    textAlign={"left"}
                    sx={{
                      lineHeight: 1.1,
                      fontSize: "18px",
                      alignContent: "start",
                      marginBottom: 1,
                    }}
                  >
                    {data.question}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    color="textSecondary"
                    textAlign={"left"}
                  >
                    {`ประเภทคำตอบ: ${
                      data.questionType === "text" ? "ข้อความ" : "ตัวเลือก"
                    }`}
                  </Typography>
                </div>
                <CardActions>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ width: "100%", borderRadius: 2 }}
                    onClick={() =>
                      handleClickeActive(data.idMoraledailyQuestion)
                    }
                  >
                    เปิดใช้งาน
                  </Button>
                </CardActions>
              </StyledCard>
            </Grid>
          ))}
    </Grid>
  );
};

export default MoraleDailyArchive;
