import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import AddIcon from "@mui/icons-material/Add";
import HeaderPage from "../../../shared/header/headerPage";
import iconHeader from "./assets/teamwork.svg";
import ActivityRegisterList from "./activityRegisterList";
import "./index.css";
import { Button, Divider, Container } from "@mui/material";
import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllEmployees } from "../../../../../actions/user";
import {
  getActivity,
  getActivityParticipant,
} from "../../../../../actions/activity";
import { Navigate, useLocation } from "react-router-dom";
import DialogAddParticipant from "./dialogAddParticipant";

const WrapHeader = styled("div")({
  marginTop: 16,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  ["@media only screen and (max-width: 600px)"]: {
    "& .MuiTypography-root": {
      fontSize: 26,
    },
  },
  ["@media only screen and (min-width:600px)"]: {
    "& .MuiTypography-root": {
      fontSize: 26,
    },
  },
  ["@media only screen and (min-width:768px)"]: {
    "& .MuiTypography-root": {
      fontSize: 34,
    },
  },
  ["@media only screen and (min-width:992px)"]: {},
});

const WrapBtnAddNew = styled("div")({
  ["@media only screen and (max-width: 600px)"]: {
    display: "none",
  },
  ["@media only screen and (min-width:600px)"]: {},
  ["@media only screen and (min-width:768px)"]: {},
  ["@media only screen and (min-width:992px)"]: {},
});

const WrapBtnIconAddNew = styled("div")({
  ["@media only screen and (max-width: 600px)"]: {},
  ["@media only screen and (min-width:600px)"]: {
    display: "none",
  },
  ["@media only screen and (min-width:768px)"]: {},
  ["@media only screen and (min-width:992px)"]: {},
});

const StyledDivider = styled(Divider)({
  margin: "10px 0",
});

const ActivityRegister = () => {
  const { state } = useLocation();
  const dispatch = useDispatch();
  const { result: activityParticipant } = useSelector((state) => state.activityParticipant);
  const { result: activity } = useSelector((state) => state.activityItem);

  const [openAddParticipant, setOpenAddParticipant] = useState(false);
  const [mode, setMode] = useState("list");

  useEffect(() => {
    if (state?.idActivity) {
      dispatch(getActivity(state?.idActivity));
      dispatch(getActivityParticipant(state?.idActivity));
      dispatch(getAllEmployees());
    }
  }, []);

  if (!state?.idActivity) {
    return <Navigate to="/admin/manage/activity" replace />
  }

  const handleClickOpenAddNewEmployee = () => {
    setOpenAddParticipant(true);
  };

  const handleCloseAddParticipant = () => {
    setOpenAddParticipant(false);
  };

  return (
    <div className="page">
      <WrapHeader>
        {mode === "list" && (
          <Fragment>
            <HeaderPage textLabel={"รายชื่อลงทะเบียน"} icon={iconHeader} />
            <WrapBtnAddNew>
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                onClick={() => handleClickOpenAddNewEmployee()}
              >
                เพิ่มผู้เข้าร่วมกิจกรรม
              </Button>
            </WrapBtnAddNew>
          </Fragment>
        )}
      </WrapHeader>
      <StyledDivider />
      <Container>
        {mode === "list" && activity && (
          <ActivityRegisterList
            activity={activity}
            activityParticipant={activityParticipant}
            idActivity={state?.idActivity}
          />
        )}
        {openAddParticipant && (
          <DialogAddParticipant
            open={openAddParticipant}
            handleClose={handleCloseAddParticipant}
            idActivity={state?.idActivity}
          />
        )}
      </Container>
    </div>
  );
};

export default ActivityRegister;
