import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import UniLogo from "../assets/UNI_HR_Logo_RGB_Full_Color.png";
import universeBackground from "./universeBackground.jpg";
import congrats from "./congrats.jpg";
import galaxy from "./galaxy.jpg";
import unicorn from "./Unicorn-Mascot-Heart-square.png";
import {
  styled,
  Typography,
  Box,
  Card,
  Button,
  Toolbar,
  FormControlLabel,
  Radio,
  InputBase,
  Avatar,
  Grid,
  Chip,
  RadioGroup,
  FormControl,
  Snackbar,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Paper,
} from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Navigation, Pagination } from "swiper/modules";
import { getOutsiderAssessmentById } from "../../../actions/assessment";
import { addAnswerOutsider } from "../../../actions/appraisee";
import InsertCommentIcon from "@mui/icons-material/InsertComment";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import FeelingIcon from "../shared/feelingIcon";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const BtnLogin1 = styled(Button)({
  color: "#ffffff",
  "&:hover": {
    borderBottom: "5px solid black",
  },
});

const StyledRoot = styled("div")({
  display: "flex",
  flexDirection: "column",
  padding: 15,
  marginTop: 20,
  margin: "0px auto",
  minWidth: 350,
  minHeight: "100vh",
  backgroundColor: "#000000",
  flexGrow: 1,
  backgroundImage: `url(${universeBackground})`,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
});

const AnswerQuestion = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [answer, setAnswer] = useState([]);
  const [comment, setComment] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openThankyou, setOpenThankyou] = useState(false);
  const [countdown, setCountdown] = useState(4);
  const { result: outsiderQuestions } = useSelector(
    (state) => state.assessmentOutsiderItem
  );
  const { error } = useSelector((state) => state.assessmentOutsider);
  const idAppraisee = location.state?.idAppraisee;
  const email =  location.state.outsiderEmail;

  useEffect(() => {
    if (idAppraisee) {
      dispatch(getOutsiderAssessmentById(idAppraisee, { email }));
    }
  }, [dispatch, idAppraisee]);

  useEffect(() => {
    if (openThankyou) {
      let timer = setInterval(() => {
        setCountdown((prev) => prev - 1);
      }, 1000);

      setTimeout(() => {
        handleCloseThankyou();
      }, 4000);

      return () => {
        clearInterval(timer);
      };
    }
  }, [openThankyou, navigate]);

  const answerScale6 = [
    {
      key: "verybad",
      label: "แย่ที่สุด",
      value: "0",
    },
    {
      key: "bad",
      label: "แย่มาก",
      value: "20",
    },
    {
      key: "littlebad",
      label: "แย่",
      value: "40",
    },
    {
      key: "littlehappy",
      label: "พอใช้",
      value: "60",
    },
    {
      key: "happy",
      label: "ดี",
      value: "80",
    },
    {
      key: "veryhappy",
      label: "ดีมาก",
      value: "100",
    },
  ];

  const answerScale5 = [
    {
      key: "verybad",
      label: "แย่มาก",
      value: "0",
    },
    {
      key: "bad",
      label: "แย่",
      value: "25",
    },
    {
      key: "littlebad",
      label: "ปานกลาง",
      value: "50",
    },
    {
      key: "happy",
      label: "ดี",
      value: "75",
    },
    {
      key: "veryhappy",
      label: "ดีมาก",
      value: "100",
    },
  ];

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleCloseThankyou = () => {
    setOpenThankyou(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const answerPass =
      answer.filter((item) => {
        const currentQuestion = outsiderQuestions?.questionList?.find(
          (question) =>
            question.idAssessment_Question === item.idAssessment_Question
        );

        if (currentQuestion?.questionType === "choice") {
          return (
            item.value &&
            item.value.trim().length > 0 &&
            (item.comment === undefined || item.comment.trim().length > 0)
          );
        } else {
          return item.value && item.value.trim().length > 0;
        }
      }).length === outsiderQuestions?.questionList?.length;

    if (answerPass) {
      handleClickOpen();
    } else {
      setOpenSnackbar(true);
    }
  };

  const updateAnswer = (index, idAssessment_Question) => (event) => {
    const currentQuestion = outsiderQuestions?.questionList?.find(
      (question) => question.idAssessment_Question === idAssessment_Question
    );

    let newValue = {
      idAppraisee_SampleSize: outsiderQuestions.idAppraisee_SampleSize,
      idAssessment_Question: idAssessment_Question,
    };

    if (event.target.name === "value") {
      newValue.value = event.target.value;
    } else if (
      event.target.name === "comment" &&
      currentQuestion?.questiontype === "choice"
    ) {
      newValue.comment = event.target.value;
    }

    const existingAnswerIndex = answer.findIndex(
      (ans) => ans.idAssessment_Question === idAssessment_Question
    );

    if (existingAnswerIndex === -1) {
      setAnswer([...answer, newValue]);
    } else {
      const updatedAnswers = answer.map((ans) =>
        ans.idAssessment_Question === idAssessment_Question
          ? { ...ans, [event.target.name]: event.target.value }
          : ans
      );
      setAnswer(updatedAnswers);
    }
  };

  const handleComplete = async () => {
    setOpenThankyou(true);
    const res = await dispatch(addAnswerOutsider(answer));

    if (res) {
      setTimeout(() => {
        navigate("/outsider-question", { state: { email }, replace: true });
      }, 3000);
    }
  };

  return (
    <>
      {outsiderQuestions && (
        <StyledRoot className={"page"} sx={{}}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Card
              sx={{
                backgroundColor: "white",
                boxShadow: "none",
                borderRadius: "45px",
                width: "95%",
                justifyContent: "center",
                alingItems: "center",
              }}
            >
              <Toolbar
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "20px",
                }}
              >
                <Link to="/landing">
                  <div style={{ width: "112px", height: "63px" }}>
                    <img
                      alt="uni-culture-logo"
                      src={UniLogo}
                      style={{
                        marginTop: "-17.5px",
                        marginLeft: "-25px",
                        width: "120%",
                        height: "160%",
                        objectFit: "contain",
                      }}
                    />
                  </div>
                </Link>

                <Box>
                  <BtnLogin1
                    sx={{ color: "black" }}
                    component={Link}
                    to="/landing"
                  >
                    About Us
                  </BtnLogin1>

                  <BtnLogin1
                    sx={{ color: "black" }}
                    component={Link}
                    to="/login"
                  >
                    Log In
                  </BtnLogin1>
                </Box>
              </Toolbar>
            </Card>
          </Box>

          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Card
              sx={{
                marginTop: "100px",
                width: "700px",
                maxHeight: "540px",
                color: "white",
                overflowY: "auto",
                borderRadius: "20px",
                justifyContent: "center",
                padding: 2,
              }}
            >
              <Swiper
                modules={[Navigation, Pagination]}
                spaceBetween={30}
                slidesPerView={1}
                navigation
                pagination={{ clickable: true }}
                sx={{ padding: "24px" }}
              >
                {outsiderQuestions?.questionList?.map((question, index) => (
                  <SwiperSlide key={index}>
                    <Grid
                      style={{
                        marginTop: 2,
                        display: "flex",
                        flexDirection: "column",
                        marginBottom: "10px",
                        justifyContent: "center",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Typography variant="h5" sx={{ textAlign: "center" }}>
                          {question.question}
                        </Typography>
                      </Box>

                      {question.questionType === "text" ? (
                        <Card
                          sx={{
                            display: "flex",
                            padding: "8px",
                            marginTop: "24px",
                            borderRadius: "30px",
                            maxWidth: "100%",
                            height: "420p",
                            justifyContent: "center ",
                            marginBottom: "24px",
                          }}
                        >
                          <Box
                            sx={{ display: "flex", justifyContent: "center" }}
                          >
                            <Box sx={{ display: "flex" }}>
                              <Avatar
                                sx={{
                                  bgcolor: "black",
                                  width: 40,
                                  height: 40,
                                }}
                              >
                                <InsertCommentIcon
                                  sx={{ fontSize: 24, color: "white" }}
                                />
                              </Avatar>
                              <InputBase
                                placeholder="แสดงความคิดเห็น"
                                name="value"
                                value={answer.value}
                                sx={{
                                  width: "420px",
                                  height: "100%",
                                  marginLeft: "8px",
                                }}
                                inputProps={{ maxLength: 200 }}
                                multiline
                                onChange={updateAnswer(
                                  index,
                                  question.idAssessment_Question
                                )}
                              />
                            </Box>
                          </Box>
                        </Card>
                      ) : (
                        <FormControl>
                          <RadioGroup
                            row
                            aria-labelledby="demo-radio-buttons-group-label"
                            name="value"
                            value={answer.value}
                            onChange={updateAnswer(
                              index,
                              question.idAssessment_Question
                            )}
                            sx={{
                              justifyContent: "space-evenly",
                              alignItems: "center",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                gap: "8px",
                              }}
                            >
                              {(question.scale === 6
                                ? answerScale6
                                : answerScale5
                              ).map((item) => (
                                <div
                                  className="answer"
                                  key={item.key}
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    width: "100px",
                                    marginTop: "24px",
                                  }}
                                >
                                  <FormControlLabel
                                    control={
                                      <Radio
                                        checkedIcon={
                                          <FeelingIcon
                                            feeling={item.key}
                                            unSelected={true}
                                          />
                                        }
                                        icon={
                                          <FeelingIcon
                                            feeling={item.key}
                                            unSelected={false}
                                          />
                                        }
                                      />
                                    }
                                    value={item.value}
                                    // label={item.label}
                                    labelPlacement="bottom"
                                  />
                                  <Chip
                                    label={item.label}
                                    sx={{ marginTop: "8px" }}
                                  />
                                </div>
                              ))}
                            </Box>
                          </RadioGroup>
                          <Box
                            sx={{
                              display: "flex",
                              marginTop: "24px",
                              justifyContent: "center",
                            }}
                          >
                            <Card
                              sx={{
                                padding: "8px",
                                marginBottom: "32px",
                                borderRadius: "30px",
                                maxWidth: "100%",
                              }}
                            >
                              <Box sx={{ display: "flex" }}>
                                <Avatar
                                  sx={{
                                    bgcolor: "black",
                                    width: 40,
                                    height: 40,
                                  }}
                                >
                                  <InsertCommentIcon
                                    sx={{ fontSize: 24, color: "white" }}
                                  />
                                </Avatar>
                                <InputBase
                                  placeholder="แสดงความคิดเห็น"
                                  name="comment"
                                  value={comment.value}
                                  sx={{ width: "420px", marginLeft: "8px" }}
                                  inputProps={{ maxLength: 200 }}
                                  onChange={updateAnswer(
                                    index,
                                    question.idAssessment_Question
                                  )}
                                />
                              </Box>
                            </Card>
                          </Box>
                        </FormControl>
                      )}

                      {index ===
                        outsiderQuestions?.questionList?.length - 1 && (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                            sx={{
                              padding: "10px 20px",
                              marginBottom: "24px",
                              borderRadius: "20px",
                              backgroundColor: "#bd6acf",
                              "&:hover": {
                                // borderColor: "#EC5B6A",
                                backgroundColor: "#dda2ea",
                              },
                            }}
                          >
                            ส่งแบบประเมิน
                          </Button>
                        </Box>
                      )}

                      <Dialog
                        open={openThankyou}
                        onClose={handleCloseThankyou}
                        maxWidth={false}
                        sx={{
                          "& .MuiDialog-paper": {
                            width: "60%",
                            height: "75%",
                            borderRadius: "15px",
                          },
                          padding: "30px",
                          borderRadius: "5px",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        padding="30px"
                      >
                        <Box
                          sx={{
                            width: "100%",
                            maxHeight: "200px",
                            display: "flex",
                          }}
                        >
                          <img
                            src={galaxy}
                            alt="Galaxy"
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                            }}
                          />
                        </Box>

                        <DialogContent
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Typography
                            variant="h6"
                            sx={{ fontWeight: "bold", fontSize: "30px" }}
                          >
                            ขอบคุณ
                          </Typography>
                          <img src={unicorn} width="100px" height="100px" />
                          <DialogContentText>
                            สำหรับการตอบแบบประเมิน 360 องศา
                          </DialogContentText>

                          <Typography
                            variant="subtitle2"
                            sx={{
                              marginTop: "10px",
                            }}
                          >
                            กำลังไปยังหน้ารายการคำถามใน {countdown} วินาที...
                          </Typography>

                        </DialogContent>
                      </Dialog>

                      <Dialog
                        open={openSnackbar}
                        onClose={handleCloseSnackbar}
                        maxWidth={false}
                        sx={{
                          "& .MuiDialog-paper": {
                            width: "25%",
                            borderRadius: "15px",
                          },
                          padding: "30px",
                          borderRadius: "5px",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        padding="30px"
                      >
                        <Paper
                          sx={{
                            display: "flex",
                            backgroundColor: "#EC5B6A",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: "10px",
                          }}
                        >
                          <WarningAmberIcon
                            sx={{
                              width: "70px",
                              height: "70px",

                              color: "white",
                            }}
                            color="warning"
                          />
                        </Paper>
                        <DialogContent
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Typography
                            variant="h6"
                            sx={{ fontWeight: "bold", fontSize: "30px" }}
                          >
                            คำเตือน!
                          </Typography>
                          <DialogContentText>
                            โปรดตอบคำถามให้ครบ
                          </DialogContentText>
                          <Button
                            onClick={handleCloseSnackbar}
                            sx={{
                              color: "white",
                              height: "24px",
                              width: "24px",
                              backgroundColor: "#EC5B6A",
                              borderRadius: "20px",
                              marginTop: 3,
                              fontSize: "12px",
                              "&:hover": {
                                backgroundColor: "#EC5B6A",
                              },
                            }}
                            variant="contained"
                          >
                            CLOSE
                          </Button>
                        </DialogContent>
                      </Dialog>

                      <Dialog
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                      >
                        <DialogTitle id="alert-dialog-title">
                          {"ยืนยันส่งคำตอบ"}
                        </DialogTitle>
                        <DialogContent>
                          <DialogContentText id="alert-dialog-description">
                            {"สามารถตอบคำถามได้รอบเดียวเท่านั้น"}
                          </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={handleClose}>ยกเลิก</Button>
                          <Button onClick={handleComplete}>ยืนยัน</Button>
                        </DialogActions>
                      </Dialog>
                    </Grid>
                  </SwiperSlide>
                ))}
              </Swiper>
            </Card>
          </Box>
        </StyledRoot>
      )}
    </>
  );
};

export default AnswerQuestion;
