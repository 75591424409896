import React, { useCallback, useState, Fragment} from "react";
import { CircularProgress, Grid, styled, Typography, Card, Stack, Box, colors,  } from "@mui/material";
import DashboardIcon from "@mui/icons-material/Dashboard";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import dayjs from "dayjs";
import clsx from "clsx";
import { useMoraleDailyKPI } from "../../../../quries/morale";
import { BarChart } from "@mui/x-charts/BarChart";
import FeelingIcon from "../../shared/feelingIcon";
import unicornCry from "./assets/Unicorn-Mascot-Cry-square.png";
import unicornHeart from "./assets/Unicorn-Mascot-Heart-square.png";
import unicornIndifferently from "./assets/Unicorn-Mascot-Indifferently-square.png";
import TableMoraleDaily from "./TableDaily";
import { utils, writeFileXLSX } from "xlsx";
import { BarChart as RechartsBarChart, Bar , Cell, ResponsiveContainer, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import DailyGaugeChart from "./DailyGaugeChart";
import DailyDepartmentSectionTable from "./DailyDepartmentSectionTable";
import Top3StrengthDailyTable from "./Top3StrengthDailyTable";
import Top3WeaknessDailyTable from "./Top3WeaknessDailyTable";
import DailyMoraleQuestions from "./DailyMoraleQuestions";

const WrapFeeling = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: 16,
  borderRadius: 8,
  backgroundColor: "#282828",
});

const WrapAverageScore = styled("div")({
  padding:10,
  justifyContent: "center",
  gap: "8px",
  "& .cardTopicSummary": {
    opacity: 1,
    borderRadius:8,
    width: "fit-content",
    padding: "8px 16px",
    //display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    "& .summary": {
      height:'100%',
      alignItems:'end',
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      "& .MuiTypography-root": {
        fontSize: 36,
      },
    },
    "@media only screen and (max-width:600px)": {
      width: "100%",
    },
  },
  "& .active": {
    opacity: 1,
  },
  "& .veryhappy": {
    backgroundColor: "#178867",
  },
  "& .happy": {
    backgroundColor: "#649c18",
  },
  "& .littlehappy": {
    backgroundColor: "#caa401",
  },
  "& .littlebad": {
    backgroundColor: "#cb6600",
  },
  "& .bad": {
    backgroundColor: "#bc293a",
  },
  "& .verybad": {
    backgroundColor: "#cc3426",
  },
});

const feelings = [
  { feeling: "veryhappy" },
  { feeling: "happy" },
  { feeling: "littlehappy" },
  { feeling: "littlebad" },
  { feeling: "bad" },
  { feeling: "verybad" },
];

const feelingCapital = {
  veryhappy: "Very happy",
  happy: "Happy",
  littlehappy: "Little happy",
  littlebad: "Little bad",
  bad: "Bad",
  verybad: "Very bad",
};

const feelingScore = (averageScore) => {
  if (averageScore === 100) {
    return "veryhappy";
  } else if (averageScore >= 80) {
    return "happy";
  } else if (averageScore >= 60) {
    return "littlehappy";
  } else if (averageScore >= 40) {
    return "littlebad";
  } else if (averageScore >= 20) {
    return "bad";
  } else {
    return "verybad";
  }
};

const DailyDetail = ({ companySelected, idDepartment, idSection, date }) => {
  const selectedYear = dayjs(date).year();
  const selectedMonth = dayjs(date).month() + 1;
  const { data, isPending } = useMoraleDailyKPI({
    query: {
      idCompany: companySelected.idCompany,
      year: selectedYear,
      month: selectedMonth,
    },
  });

  const unicornImage = (x) => {
    if (x >= 80) {
      return unicornHeart;
    } else if (x >= 50) {
      return unicornIndifferently;
    } else {
      return unicornCry;
    }
  };
  const [expandedComment, setExpandedComment] = useState(false);

  const handleChangeComment = (panel) => (event, isExpanded) => {
    setExpandedComment(isExpanded ? panel : false);
  };

  const filterData =
    data
      ?.filter((item) => {
        return idDepartment === 0 ? true : item.idDepartment === idDepartment;
      })
      ?.filter((item) => {
        return idSection === 0 ? true : item.idSection === idSection;
      }) || [];

  const exportFile = useCallback(() => {
    const data = filterData?.reduce((acc, cur) => {
      const employee = {
        employeeID: cur.employeeID,
        firstname_TH: cur.firstname_TH,
        lastname_TH: cur.lastname_TH,
        divisionName: cur.divisionName,
        departmentName: cur.departmentName,
        sectionName: cur.sectionName,
        positionName: cur.positionName,
        averageScore: cur.averageScore,
      };

      const temp = Object.assign(
        employee,
        ...cur.questionTopics.map((item) => ({
          [item.questionTopic]: item.averageScore,
        }))
      );
      acc.push(temp);
      return acc;
    }, []);

    const ws = utils.json_to_sheet(data);
    utils.sheet_add_aoa(
      ws,
      [
        [
          "รหัสพนักงาน",
          "ชื่อ",
          "นามสกุล",
          "แผนก", // Division
          "ส่วน", // Department
          "ฝ่าย", // Section
          "ตำแหน่ง", // Position
          "average",
          ...filterData[0].questionTopics.map((item) => item.questionTopic),
        ],
      ],
      { origin: "A1" }
    );

    ws["!cols"] = [
      { wch: 10 },
      { wch: 25 },
      { wch: 25 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 10 },
      ...filterData[0].questionTopics.map((item) => ({ wch: 20 })), // Questions
    ];

    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws);
    writeFileXLSX(
      wb,
      `KPI Daily ${dayjs(date).format("MMMM YYYY")} ${
        companySelected.companyName
      }.xlsx`
    );
  }, [filterData]);

  const scoreData = filterData?.reduce(
    (acc, cur) => {
      if (cur.averageScore) {
        if (cur.averageScore === 100) {
          acc.veryhappy++;
        } else if (cur.averageScore >= 80) {
          acc.happy++;
        } else if (cur.averageScore >= 60) {
          acc.littlehappy++;
        } else if (cur.averageScore >= 40) {
          acc.littlebad++;
        } else if (cur.averageScore >= 20) {
          acc.bad++;
        } else {
          acc.verybad++;
        }

        acc.totalScore += Number(cur.averageScore);
        acc.dataLength++;
      }

      return acc;
    },
    {
      dataLength: 0,
      totalScore: 0,
      veryhappy: 0,
      happy: 0,
      littlehappy: 0,
      littlebad: 0,
      bad: 0,
      verybad: 0,
    }
  );

  const scoreTopic = filterData?.reduce((acc, cur) => {
    for (const x of cur.questionTopics) {
      if (!acc[x.idQuestionTopic]) {
        acc[x.idQuestionTopic] = { sumScore: 0, dataLength: 0 };
      }
      if (x.averageScore) {
        acc[x.idQuestionTopic].sumScore += Number(x.averageScore);
        acc[x.idQuestionTopic].dataLength++;
      }
    }
    return acc;
  }, {});

  const totalAverageScore =
    scoreData?.dataLength > 0
      ? Math.round(scoreData.totalScore / scoreData.dataLength)
      : 0;

  const averageScoreTopic = Object.keys(scoreTopic).map((topic) => {
    return scoreTopic[topic].dataLength > 0
      ? scoreTopic[topic].sumScore / scoreTopic[topic].dataLength
      : 0;
  });

  const maxLengthTopic = filterData?.[0]?.questionTopics.reduce(
    (acc, cur) =>
      cur.questionTopic.length > acc ? cur.questionTopic.length : acc,
    0
  );

  if (isPending) {
    return <CircularProgress />;
  }

  if (filterData.length === 0) {
    return (
      <Typography variant="h6" color={"white"}>
        ไม่พบข้อมูล
      </Typography>
    );
  }

  // const top3Topics = filterData
  //   .flatMap((item) => item.questionTopics)
  //   .reduce((acc, topic) => {
  //     if (!acc[topic.questionTopic]) {
  //       acc[topic.questionTopic] = { sum: 0, count: 0 };
  //     }
  //     acc[topic.questionTopic].sum += topic.averageScore;
  //     acc[topic.questionTopic].count += 1;
  //     return acc;
  //   }, {});

  // const top3 = Object.entries(top3Topics)
  //   .map(([topic, { sum, count }]) => ({
  //     topic,
  //     averageScore: sum / count,
  //   }))
  //   .sort((a, b) => b.averageScore - a.averageScore)
  //   .slice(0, 3);

  return (
    <div>
      {filterData.length === 0 ? (
        <Typography variant="h6" color={"#ffffff"}>
          {"ไม่พบข้อมูล"}
        </Typography>
      ) : (
        <>
         <Grid container  sx={{ width: '100%',  }}>
            <Grid item xs={6} md={9}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Grid item xs={12}>
                    <div style={{ backgroundColor: "#ffffff", padding: "20px", height:"100%", borderRadius: "8px", marginBottom: 15, paddingLeft: "35px", paddingRight: "35px" }} >
                      <div style={{display: "flex", alignItems: "center", gap: "0.5rem",}}>
                        <DashboardIcon sx={{ color: "#000" }} />
                        <Typography style={{ color: "#000" }}  variant="h5"   >
                          สัดส่วนพนักงาน
                        </Typography>
                      </div>
                      <div style={{display: "flex", alignItems: "center", gap: "0.5rem", height: "100%"}}>
                        <Box sx={{ 
                          display: 'flex', 
                          flexWrap: 'wrap', // Allow items to wrap to the next line
                          justifyContent: 'space-around', 
                          width: "100%"
                        }}>
                          {feelings.map((item) => (
                            <Box
                              key={item.feeling}
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                bgcolor: 'background.neutral',
                                p: 1,
                                width: "15%", height:'15%',
                                flexDirection: 'column',
                              }}
                            >
                              <FeelingIcon feeling={item.feeling} unSelected={true} />
                              <Stack spacing={0.2} sx={{ alignItems: 'center', width: 150 }}>
                                <Typography  sx={{ alignItems: 'center',}} >
                                  {feelingCapital[item.feeling]}
                                </Typography>
                                <Typography variant="body2">
                                  {`${Math.round((scoreData[item.feeling] / scoreData.dataLength) * 100)}%`}
                                  {` (${scoreData[item.feeling]} ${
                                    scoreData[item.feeling] === 1 ? "คน" : "คน"
                                  })`}
                                </Typography>
                              </Stack>
                            </Box>
                          ))}
                        </Box>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} >
                  <div style={{ backgroundColor: "#ffffff", padding: "20px", borderRadius: "8px", paddingLeft: "35px", paddingRight: "35px" }} >
                    <Typography style={{ color: "#000" }}  variant="h5"   >
                      คะแนนเฉลี่ยจากสัดส่วนพนักงาน
                    </Typography>
                    <ResponsiveContainer width="100%" height={260}>
                      <RechartsBarChart
                        margin={{ top: 20,right: 20, left: -20, bottom: 5, }}
                        data={feelings.map(item => ({
                          feeling: feelingCapital[item.feeling],
                          value: Math.round((scoreData[item.feeling] / scoreData.dataLength) * 100)
                        }))}
                      >
                        <XAxis dataKey="feeling" />
                        <YAxis tickInterval={[0, 25, 50, 75, 100]} domain={[0, 100]} />
                        <Tooltip
                          contentStyle={{ backgroundColor: "#fff", color: "#000" }}
                          cursor={{ fill: "rgba(255, 255, 255, 0.1)" }}
                        />
                        <Bar dataKey="value" barSize={40} >
                          {feelings.map((item, index) => {
                            let color;
                            switch(feelingCapital[item.feeling]) {
                              case 'Very happy': color = "#178867"; break;
                              case 'Happy': color = "#649c18"; break;
                              case 'Little happy': color = "#caa401"; break;
                              case 'Little bad': color = "#cb6600"; break;
                              case 'Bad': color = "#bc293a"; break;
                              case 'Very bad': color = "#cc3426"; break;
                              default: color = "#ccc";
                            }
                            return <Cell key={`cell-${index}`} fill={color} />;
                          })}
                        </Bar>
                      </RechartsBarChart>
                    </ResponsiveContainer>
                    </div>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <div style={{ backgroundColor: "#ffffff", padding: "20px", height: "100%", borderRadius: "8px", marginBottom: 15, paddingLeft: "35px", paddingRight: "35px" }} >
                    <div style={{display: "flex", alignItems: "center", gap: "0.5rem",}}>
                      <LeaderboardIcon sx={{ color: "#000" }} />
                      <Typography style={{ color: "#000" }}  variant="h5"   >
                        หัวข้อคำถาม
                      </Typography>
                    </div>
                    <Box sx={{marginBottom:-6, marginTop:-4}}>
                      <BarChart
                        height={Math.round(filterData[0].questionTopics.length * 28)}
                        margin={{ left:maxLengthTopic * 8.5,}}
                        yAxis={[
                          {
                            scaleType: "band",
                            data: filterData[0].questionTopics.map((item) => item.questionTopic),
                            tickSize: 5,
                            tickPlacement: "middle",
                            tickLabelStyle: { dominantBaseline: "middle", fontSize: 14, fill: "#000", }
                          },
                        ]}
                        xAxis={[{tickInterval: [0, 20, 40, 60, 80, 100],}, ]}
                        series={[ { data: averageScoreTopic, valueFormatter: (value) => `${value}%`,  color: "#ff9da7", },]}
                        layout="horizontal"
                        slotProps={{
                          axisTick: { style: { stroke: "#000", },},
                          axisLine: { style: {stroke: "#000", }, },
                        }}
                        grid={{ vertical: true }}
                      >
                        <Tooltip
                          formatter={(value, name, props) => {
                            const topicId = filterData[0].questionTopics[props.index].idQuestionTopic;
                            const responses = scoreTopic[topicId]?.dataLength || 0;
                            return [`${value}%`, `Responses: ${responses}`];
                          }}
                          cursor={{ fill: "rgba(255, 255, 255, 0.1)" }}
                        />
                      </BarChart>
                    </Box>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6} md={3}>
              <div 
                style={{ 
                  backgroundColor: "#ffffff", 
                  padding: "20px", 
                  height: "100%", 
                  borderRadius: "8px", 
                  // marginBottom: 15,
                  marginLeft: 15,
                  textAlign: "center", 
                  display: "flex",
                  flexDirection: "column",
                  paddingLeft: "35px", 
                  paddingRight: "35px" 
                }} >
                <div style={{display: "flex",justifyContent: "center",}}>
                  <div style={{ width: "100%",aligeItems:'center', height: "100%", }}>
                    <Typography variant="h4" style={{ color: "#000000",  }}>
                      {`คะแนนเฉลี่ย`}
                    </Typography>
                    <img
                      src={unicornImage(totalAverageScore)}
                      alt="unicorn"      
                      style={{ marginBottom: "5px",  width: "55%", height: "auto", }}
                    />
                  </div>
                </div>  
                <WrapAverageScore>
                  <div className={clsx(`cardTopicSummary`,feelingScore(totalAverageScore))} >
                    <div className={`summary`}>
                      <div style={{  marginRight: 5, width:'12%' }}>
                        <FeelingIcon
                          feeling={feelingScore(totalAverageScore)}
                          unSelected={true}
                        />
                      </div>
                      <div style={{ display: "flex", flexDirection: "row",}}>
                        <Typography variant="h4" style={{ color: "#fff", marginRight:5 }}>
                          {`${totalAverageScore}% `} 
                        </Typography>
                        <Typography
                          variant="h4"
                          className={feelingScore(totalAverageScore)}
                          color="#ffffff"
                        >
                          {feelingCapital[feelingScore(totalAverageScore)]}
                        </Typography>
                      </div>
                    </div>
                  </div>
            </WrapAverageScore>     
              </div>
            </Grid>
            <Grid container spacing={2}>
            <Grid item xs={12}>
              <div style={{  display: "flex",flexDirection: "row",marginTop:15, width:'100%', flex: 1, backgroundColor: "#ffffff", padding: "16px", borderRadius: "8px", paddingRight:40}}>
                <Fragment>
                  <div style={{ paddingTop: "16px", paddingBottom: "16px", display: "flex",flexDirection: "column", marginRight:15,marginLeft:15, flex: "0 0 50%"}}>
                    {filterData && companySelected && (
                      <Top3StrengthDailyTable
                        filterData={filterData}
                        companyName={companySelected.idCompany}
                        departmentName={idDepartment}
                      />
                    )}
                  </div>
                  <div style={{ paddingTop: "16px", paddingBottom: "16px", paddingRight: "16px",display: "flex",flexDirection: "column",marginRight:15, flex: "0 0 50%"}}>
                    {filterData && companySelected && (
                    <Top3WeaknessDailyTable
                      filterData={filterData}
                      companyName={companySelected.idCompany}
                      departmentName={idDepartment}
                    />
                    )}
                  </div>
                </Fragment>
              </div>
            </Grid>
           <Grid item xs={12}>
            <DailyDepartmentSectionTable filterData={filterData} />
           </Grid> 
           <Grid item xs={12}>
            <div style={{  display: "flex",flexDirection: "row", width:'100%', flex: 1, backgroundColor: "#ffffff", padding: "16px", borderRadius: "8px", paddingRight:40}}>
              <DailyMoraleQuestions
                filterData={filterData}
                expandedComment={expandedComment}
                handleChangeComment={handleChangeComment}
                idCompany={companySelected.idCompany}
                year={selectedYear}
                month={selectedMonth}
              />
            </div>
           </Grid>
           <Grid item xs={12}>
            <div style={{flexDirection: "row", width:'100%', flex: 1, backgroundColor: "#ffffff", padding: "16px", borderRadius: "8px", paddingRight:40, paddingLeft:30}}>
              <TableMoraleDaily
                filterData={filterData}
                exportFile={exportFile}
                companySelected={companySelected.idCompany}
              />
            </div>
           </Grid>
          </Grid>
        </Grid>
        </>
      )}
    </div>
  );
};

export default DailyDetail;
