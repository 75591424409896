import React, { useState } from "react";
import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import { CircularProgress, Divider, Grid, Stack } from "@mui/material";
import Chip from "@mui/material/Chip";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import FilterListIcon from "@mui/icons-material/FilterList";
import CloseIcon from "@mui/icons-material/Close";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import HeaderPage from "./../../shared/header/headerPage";
import iconRewardHeader from "./assets/shopping-cart.svg";
import { SpecialIcon } from "../../shared/typeCoin";
import Utils from "./../../../../utils";
import dayjs from "dayjs";
import CoinFilter from "../../shared/coinFilter";
import { useRedeemTransactionEmployee } from "../../../../quries/redeemTransaction";

const StyledCard = styled(Card)({
  height: "100%",
});

const StyledDivider = styled(Divider)({
  width: "100%",
  margin: "10px 0",
});

const StyledCardContent = styled(CardContent)({
  height: "100%",
  display: "flex",
  alignItems: "flex-start",
  ":last-child": {
    paddingBottom: 16,
  },
  ["@media only screen and (max-width:600px)"]: {
    flexDirection: "column",
    alignItems: "center",
  },
  ["@media only screen and (min-width:900px)"]: {
    flexDirection: "column",
    alignItems: "center",
  },
  "& .imgReward": {
    ["@media only screen and (max-width:600px)"]: {
      marginRight: 0,
    },
  },
  "& .textReward": {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "8px",
    flexGrow: 1,
    // marginRight: "auto",
    // "& .nameReward": {
    //   ["@media only screen and (max-width:600px)"]: {
    //     width: 220,
    //   },
    //   ["@media only screen and (min-width:768px)"]: {
    //     width: 300,
    //   },
    //   ["@media only screen and (min-width:992px)"]: {
    //     width: 400,
    //   },
    // },
    // "& .nameDetail": {
    //   whiteSpace: "nowrap",
    //   overflow: "hidden",
    //   textOverflow: "ellipsis",
    //   ["@media only screen and (max-width:600px)"]: {
    //     width: 220,
    //   },
    //   ["@media only screen and (min-width:768px)"]: {
    //     width: 300,
    //   },
    //   ["@media only screen and (min-width:992px)"]: {
    //     width: 400,
    //   },
    // },
  },
  "& .price": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    // ["@media only screen and (max-width:600px)"]: {
    //   marginLeft: "auto",
    // },
  },
  // "& .price .MuiSvgIcon-root": {
  //   color: "rgba(0, 0, 0, 0.2)",
  // },
});

const WrapFilter = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  margin: "16px 0",
  "& .MuiFormControl-root": {
    width: 340,
    marginRight: 8,
  },
});

const redeemStatus = {
  1: { label: "รอตรวจสอบ", backgroundColor: "#1e88e5" },
  2: { label: "สำเร็จ, โปรดติดต่อรับของรางวัล", backgroundColor: "#fb8c00" },
  3: { label: "รับของรางวัลเรียบร้อยแล้ว", backgroundColor: "#43a047" },
  4: { label: "ไม่สำเร็จ, ถูกยกเลิก", backgroundColor: "#e53935" },
}

const StyledRoot = styled("div")({
  display: "flex",
  flexDirection: "column",
  padding: 15,
  marginTop: 60,
  margin: "0px auto",
  minWidth: 350,
});

export default function MyReward() {
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { data: redeemTransaction, isPending } = useRedeemTransactionEmployee({
    idEmployees: userProfile?.idEmployees,
  });
  const [openFilter, setOpenFilter] = useState(false);
  const [rewardStatus, setRewardStatus] = useState(0);
  const [dateFilter, setDateFilter] = useState(dayjs());
  const [filterCoin, setFilterCoin] = useState([]);

  const toggleDrawer = (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpenFilter(!openFilter);
  };

  const rewardStatusList = [
    { idRedeemStatus: 0, status: "ทั้งหมด" },
    { idRedeemStatus: 1, status: "รอตรวจสอบ" },
    // { idRedeemStatus: 2, status: "สำเร็จ" },
    { idRedeemStatus: 3, status: "รับของรางวัลแล้ว" },
    { idRedeemStatus: 4, status: "ไม่สำเร็จ" },
  ];

  if (isPending) {
    return <CircularProgress />;
  }

  return (
    <StyledRoot className={"page"}>
      <HeaderPage textLabel={"รางวัลของฉัน"} icon={iconRewardHeader} />
      <StyledDivider />
      <Stack
        direction={{ xs: "column", md: "row" }}
        spacing={2}
        marginBottom={4}
        marginTop={2}
        justifyContent={"space-between"}
      >
        <Stack
          direction={"row"}
          sx={{ overflowX: "auto", scrollbarWidth: "none" }}
          spacing={2}
        >
          {rewardStatusList.map((item) => {
            return (
              <Chip
                clickable
                variant="outlined"
                label={item.status}
                color={
                  rewardStatus === item.idRedeemStatus ? "primary" : "default"
                }
                onClick={() => setRewardStatus(item.idRedeemStatus)}
              />
            );
          })}
        </Stack>
        <Stack direction={"row"} spacing={2} justifyContent={"flex-end"}>
          <DatePicker
            views={["year"]}
            label="เลือกตามปีที่แลก"
            minDate={dayjs().subtract(3, "year")}
            maxDate={dayjs()}
            value={dateFilter}
            onChange={(newValue) => {
              setDateFilter(newValue);
            }}
          />
          <IconButton aria-label="filter" onClick={toggleDrawer} size="large">
            <FilterListIcon fontSize="large" />
          </IconButton>
        </Stack>
      </Stack>
      <Drawer anchor={"right"} open={openFilter} onClose={toggleDrawer}>
        <CoinFilter setFilter={setFilterCoin} />
      </Drawer>
      <Grid container spacing={2}>
        {redeemTransaction &&
          redeemTransaction
            .filter((item) => dayjs(dateFilter).isSame(item.redeemDate, "year"))
            .filter((item) => rewardStatus === 0 || rewardStatus === item.idRedeemStatus)
            .filter((item) => filterCoin.length === 0 || item.coins.some(coin => filterCoin.includes(coin.idCoinType)))
            .map((value) => {
              return (
                <Grid item xs={12} md={6} lg={4}>
                  <StyledCard key={value.idRedeem} elevation={3}>
                    <StyledCardContent>
                      <Grid container alignItems={"center"} spacing={2}>
                        <Grid item xs={12} sm={5}>
                          <img
                            alt="reward"
                            className={`imgReward`}
                            width={"100%"}
                            height={"auto"}
                            src={value.imageURL}
                          />
                        </Grid>
                        <Grid item xs={12} sm={7}>
                          <div className={`textReward`}>
                            <Chip
                              label={redeemStatus[value.idRedeemStatus].label}
                              sx={{
                                color: "#ffffff",
                                backgroundColor: redeemStatus[value.idRedeemStatus].backgroundColor 
                              }}
                            />
                            <Typography className={`nameReward`} variant="h6">
                              {value.name}
                            </Typography>
                            {value?.options?.length > 0 && (
                              <Typography
                                className={`nameDetail`}
                                color="textSecondary"
                              >
                                {value.options
                                  .map((option) => option.value)
                                  .join(", ")}
                              </Typography>
                            )}
                          </div>
                          <div>
                            <Typography color="textSecondary">
                              {`จำนวน: ${value.quantity}`}
                            </Typography>
                            <Typography color="textSecondary" noWrap>
                              {`วันที่แลกรางวัล: ${dayjs(
                                value.redeemDate
                              ).format("DD/MM/YYYY")}`}
                            </Typography>
                            {value.receivedDate && (
                              <Typography color="textSecondary" noWrap>
                                {`วันที่รับของรางวัล: ${dayjs(
                                  value.receivedDate
                                ).format("DD/MM/YYYY")}`}
                              </Typography>
                            )}
                          </div>
                        </Grid>
                      </Grid>
                      <Divider
                        flexItem
                        sx={{ borderWidth: 1, marginTop: 2, marginBottom: 2 }}
                      />
                      <div className={`price`}>
                        {value?.coins.map((item, index) => (
                          <>
                            <SpecialIcon
                              idCoinType={item.idCoinType}
                              width={40}
                            />
                            <Typography
                              variant="h4"
                              style={{ marginLeft: 8, fontSize: 20 }}
                            >
                              {Utils.numberWithCommas(item.amount)}
                            </Typography>
                            {value?.coins.length - 1 > index && (
                              <CloseIcon color="disabled" />
                            )}
                          </>
                        ))}
                      </div>
                    </StyledCardContent>
                  </StyledCard>
                </Grid>
              );
            })}
      </Grid>
    </StyledRoot>
  );
}
